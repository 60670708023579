.primary-avatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background: $lightGrey1;
  flex-shrink: 0;
  position: relative;
  img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: contain;
  }

  &.md {
    width: 60px;
    height: 60px;
  }

  &.lg {
    width: 70px;
    height: 70px;
  }

  &.xl {
    width: 125px;
    height: 125px;
  }
}

.my-bridge-avatar {
  font-family: $primaryFont;
  * {
    font-family: $primaryFont;
  }
  span {
    position: static;
    font-size: 0.875rem;
    display: initial;
    font-weight: normal;
    line-height: normal;
    width: auto;
    height: auto;
    border: none;
    border-radius: inherit;
    background: inherit;
  }
}
