@import '../../../../scss/utils/variables';
.profile__user__name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3d5a80;
}

.user__connections {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: $secondaryGrey;
  margin-left: 15px;
}

.user__position {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: $darkGrey2;
  margin-bottom: 3px;
}

.user__position__timeline {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: $secondaryGrey;
}
