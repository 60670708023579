@import '../utils/variables';

.header {
  background: $white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $zindex-navbar;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.2);
  height: 3.75rem;
  padding: 0 0.9375rem;
  display: flex;
  align-items: center;
  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .container {
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &-left {
    align-items: center;
    display: flex;
    flex-grow: 1;

    button {
      background-color: transparent;
      padding: 0;
      border: none;
    }

    & > svg {
      flex-shrink: 0;
    }

    @media screen and (min-width: 768px) {
      flex: none;
    }

    @media screen and (min-width: 1024px) {
      width: 35%!important;
    }
  }

  &-full {
    align-items: center;
    display: flex;
    flex-grow: 1;

    button {
      background-color: transparent;
      padding: 0;
      border: none;
    }

    & > svg {
      flex-shrink: 0;
    }

    @media screen and (min-width: 768px) {
      flex: none;
    }

    @media screen and (min-width: 1024px) {
      width: 70%;
    }
  }

  &-middle {
    @media screen and (min-width: 1024px) {
      width: 23%;
    }
    .navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      > li {
        @media screen and (min-width: 1024px) {
          position: relative;
        }
        & + li {
          margin-left: 1rem;
          @media screen and (min-width: 768px) {
            margin-left: 2rem;
          }

          @media screen and (min-width: 1280px) {
            margin-left: 0;
          }
        }

        > a,
        > button {
          width: 53px;
          height: 53px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border: none;
          border-radius: 8px;
          &:hover {
            background: #e4e6eb;
          }
        }

        > svg {
          width: 1.875rem;
          height: 1.875rem;

          @media screen and (min-width: 768px) {
            width: 1.5rem;
            height: 1.5rem;
          }

          @media screen and (min-width: 1280px) {
            width: 2rem;
            height: 2rem;
          }

          // @media screen and (min-width: 1600px) {
          //   width: 2.875rem;
          //   height: 3.0625rem;
          // }
        }
        &.active {
          svg {
            path {
              fill: $headingColor;
            }
          }
          button,
          a {
            background: #e4e6eb;
          }
        }

        .navbar-dropdown {
          .menu-search {
            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }

        .status-dropdown {
          svg {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    @media screen and (min-width: 1024px) {
      width: 28%;
    }
    .navigation {
      margin-left: auto;
      display: flex;
      align-items: center;
      list-style: none;
      li {
        &:last-child {
          position: relative;
        }

        & + li {
          margin-left: 5px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 0.5rem;
        // width: 2.5rem;
        // // height: 2.5rem;
        // background: $lightGrey1;
        // border-radius: 6.25rem;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        @media screen and (min-width: 1024px) {
          position: relative;
        }

        // &:hover {
        //   background-color: darken($color: $lightGrey1, $amount: 5%);
        // }

        img.profile-pic {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      }
    }
  }
}

.search-bar {
  // flex: 1;
  margin-left: auto;
  @media screen and (min-width: 1024px) {
    position: relative;
    width: 100%;
  }

  .search-result {
    position: absolute;
    background: #ffffff;
    top: 50px;
    left: 0;
    width: 100%;
    border: 1px solid #dddddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    z-index: 100;
    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #000000;
    }

    &--container {
      display: flex;
      padding: 0.8rem;
    }
    &--profile {
      padding-right: 10px;
    }
    &--details {
      display: flex;
      flex-direction: column;
      h5 {
        font-size: $fs-15;
        font-weight: $fw-400;
        margin: 0;
      }
      p {
        margin: 0;
        font-size: $fs-12;
        line-height: normal;
        color: $grey;
      }
    }
    &--bottom {
      display: flex;
      padding: 15px 0;
      justify-content: center;
      border-top: 1px solid #d9d9d9;
      > a {
        text-decoration: none;
      }
    }
  }

  .wrap {
    position: absolute;
    left: -100%;
    top: 3.75rem;
    width: 100%;
    padding: 0.625rem;
    background-color: $white;
    z-index: -1;
    transition: all ease-in-out 0.3s;

    @media screen and (min-width: 1024px) {
      position: static;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
    }

    &.in {
      left: 0;
    }
  }

  span {
    width: 2.125rem;
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $lightGrey1;
    border-radius: 50%;
    padding: 0.5rem;

    @media screen and (min-width: 1024px) {
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      width: auto;
      height: auto;
    }
  }

  svg {
    display: block;
    @media screen and (min-width: 1024px) {
      position: absolute;
      left: 1.1875rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input {
    display: block;
    height: 2.8125rem;
    border: none;
    padding: 0 1.25rem;
    width: 100%;
    background: #f6f6f6;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.875rem;
    color: $inputTextColor;
    border-radius: 4px;
    outline: none !important;
    @media screen and (min-width: 1024px) {
      padding: 0 1.25rem 0 3.4375rem;
      border-radius: 4px;
    }

    &::placeholder {
      font-size: 0.9375rem;
      line-height: 1.875rem;
      font-weight: 400;
      color: $inputTextColor;
    }
  }
}

.status-dropdown {
  position: relative;
  &--menu {
    padding: 0.625rem;
    position: absolute;
    top: 2.8rem;
    width: 14.4375rem;
    right: 0;

    @media screen and (min-width: 1280px) {
      left: 0;
      right: auto;
      top: 3.1rem;
    }

    .wrap {
      display: flex;
      align-items: center;
      padding: 0.9375rem 1.25rem;
      cursor: pointer;
      border-radius: 5px;
      transition: all ease-in-out 0.3s;

      &:hover {
        background: #e4e6eb;
      }

      .content {
        flex-grow: 1;
        p {
          font-weight: 400;
          font-size: 0.9375rem;
          margin: 0;
          line-height: 1;
          color: $black;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 0.75rem;
          margin-top: 0.5rem;
          line-height: 1;
          color: $grey;
        }
      }

      .icon {
        font-size: 0;
        font-style: normal;
        width: 0.9375rem;
        align-self: flex-start;
        margin-right: 0.5rem;
        border-radius: 50%;
        height: 0.9375rem;
      }
    }
  }
  & > span {
    cursor: pointer;
    padding: 0 0.625rem;
    display: flex;
    align-items: center;
    line-height: 1;
    height: 1.875rem;
    background: $textLight;
    border: 0.0625rem solid $lightGrey1;
    user-select: none;
    border-radius: 12.5rem;
    color: $black;
    font-weight: 400;
    font-size: 0.8125rem;
    // min-width: 6.75rem;

    @media screen and (min-width: 1280px) {
      // min-width: 12rem;
      padding: 0 0.9375rem 0 0.625rem;
      font-size: 0.9375rem;
      height: 2.5rem;
    }

    svg {
      // margin-left: auto;
      margin-left: 0.5rem;
      width: 0.625rem;
      height: 0.375rem;
      @media screen and (min-width: 1280px) {
        width: 0.75rem;
        height: 0.5rem;
      }
    }

    em {
      font-size: 0;
      font-style: normal;
      border-radius: 50%;
      width: 0.625rem;
      height: 0.625rem;
      margin-right: 0.375rem;

      @media screen and (min-width: 1280px) {
        width: 0.9375rem;
        height: 0.9375rem;
      }
    }
  }
}

.mobile-nav {
  background: $white;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.2);
  height: 3.75rem;
  position: fixed;
  z-index: $zindex-navbar;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  left: 0;
  .navbar-dropdown {
    &--menu {
      top: auto;
      bottom: 4.375rem;
    }
    .dropdown-wrap {
      width: 2.125rem;
      height: 2.125rem;
      padding: 0;
      background-color: transparent;
      position: relative;
      &.transparent {
        width: 2.125rem;
        height: 2.125rem;
      }

      & > svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      img {
        width: 100%;
        display: block;
        border-radius: 50%;
        height: 100%;
        background-color: $lightGrey1;
      }
    }
  }
  & > ul {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    justify-content: space-around;
    & > li {
      &:last-child {
        & > a {
          background: $lightGrey1;
        }
      }
      &.active {
        svg {
          path {
            fill: $primaryColor;
          }
        }
      }
      & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.125rem;
        height: 2.125rem;
        border-radius: 6.25rem;
        transition: all 0.3s ease-in-out;
        position: relative;
        text-decoration: none;

        & > span {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background: $primaryColor;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 0.625rem;
          line-height: 0.625rem;
          color: $white;
          position: absolute;
          right: 0;
          top: 0;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      }
    }
  }
}

.navbar-dropdown {
  &.notification {
    .dropdown-wrap {
      span {
        background: #ff0600;
      }
    }
  }
  .dropdown-wrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    background: $lightGrey1;
    user-select: none;
    border-radius: 6.25rem;
    transition: all 0.3s ease-in-out;

    & > svg {
      width: 2rem;
      height: 2rem;
    }

    &.transparent {
      background: transparent;
      padding: 0;
      width: auto;
      height: auto;
      display: block;
      width: 53px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &:hover,
      &.active {
        background: #e4e6eb;
      }
    }
    & > span {
      width: 24px;
      height: 24px;
      border: 3px solid #fff;
      border-radius: 50%;
      background: #ff0600;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.625rem;
      color: $white;
      position: absolute;
      right: 0;
      top: 0;
    }
    .profile-avatar {
      span {
        position: static;
        font-size: 1rem;
        display: initial;
        font-weight: normal;
        line-height: normal;
        width: auto;
        height: auto;
        border: none;
        border-radius: inherit;
        background: inherit;
      }
    }
    .company-avatar {
      span {
        position: static;
        font-size: 1rem;
        display: initial;
        font-weight: normal;
        line-height: normal;
        width: auto;
        height: auto;
        border: none;
        border-radius: inherit;
        background: inherit;
      }
    }
  }

  & > svg {
    cursor: pointer;
    background-color: rgb(228, 230, 235);
  }

  &--menu {
    position: absolute;
    right: 0.9375rem;
    top: 3.4375rem;
    background: $white;
    border: 0.0625rem solid $lightGrey1;
    border-radius: 0.5rem;
    width: calc(100% - 1.875rem);
    z-index: $zindex-navbar;

    &.profile-dropdown {
      width: 350px;
      height: auto;
      right: 0;
      top: 3.1rem;

      @media screen and (max-width: 767px) {
        top: 2rem;
      }

      @media screen and (min-width: 768px) {
        width: 250px;
      }

      @media screen and (min-width: 1280px) {
        width: 350px;
      }

      &.xs {
        width: 201px;
        left: 0;
        // max-width: 100%;

        @media screen and (max-width: 767px) {
          right: auto;
          top: 1.5rem;
        }
      }

      .menu {
        &-header {
          position: relative;
          &::after {
            content: '';
            bottom: 0;
            position: absolute;
            height: 1px;
            width: calc(100% - (0.9375rem + 0.9375rem));
            left: 0.9375rem;
            background-color: $lightGrey1;
          }
        }

        &-body {
          padding: 1.25rem 0.9375rem 0.9375rem;
          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 30px;
            color: $primaryColor;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              height: 25px;
              min-width: 25px;
              line-height: 1;
              display: flex;
              border-radius: 50px;
              align-items: center;
              justify-content: center;
              padding: 0 2px;
              font-weight: 500;
              font-size: 15px;
              color: $white;
              background: $primaryColor;
            }
          }
          ul {
            margin: 0 -4px;
            li {
              display: block;
              padding: 0;
              width: auto;
              height: auto;
              border-radius: 0;
              background: transparent;
              button {
                display: flex;
                align-items: center;
                font-weight: 400;
                width: 100%;
                padding: 8px 6px;
                background-color: transparent;
                border: none;
                font-size: 13px;
                line-height: 1;
                color: $darkGrey1;
                text-decoration: none;
                border-radius: 5px;
                transition: all ease-in-out 0.3s;

                & > svg {
                  transition: all ease-in-out 0.3s;
                }

                &:hover {
                  background: #e4e6eb;

                  & > svg {
                    margin-right: 10px;
                  }
                  // span {
                  //   background: darken($color: $primaryGrey, $amount: 10%);
                  // }
                }

                span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: all ease-in-out 0.3s;
                  width: 36px;
                  margin-right: 11px;
                  height: 36px;
                  border-radius: 50%;
                  background: $primaryGrey;
                }
              }
            }
          }
        }

        &-footer {
          padding: 1.25rem 0.9375rem 0.9375rem;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            margin: 0;
            color: $darkGrey2;
          }
        }
      }

      .content {
        padding-left: 1.25rem;
        @media screen and (min-width: 1280px) {
          padding-left: 1.25rem;
        }
        h4 {
          font-weight: 500;
          font-size: 0.9375rem;
          line-height: 1;
          margin: 0 0 6px;
          color: $black;
        }

        p {
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 0.875rem;
          margin: 0 0 6px;
          // color: $grey;

          a {
            color: darken($color: $primaryColor, $amount: 5%);
            &:not(:hover) {
              text-decoration: none;
              color: $primaryColor;
            }
          }
        }
      }

      .icon {
        width: 2.5625rem;
        flex-shrink: 0;
        height: 2.5625rem;
        position: relative;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $lightGrey1;

        .status {
          border-radius: 50%;
          width: 15px;
          height: 15px;
          position: absolute;
          background-color: $error;
          bottom: 3px;
          right: -7px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      width: 352px;
      right: -1.25rem;
    }

    .menu {
      &-header {
        padding: 1.25rem 0.9375rem 0.9375rem;
        .wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
            font-weight: 600;
            font-size: 16px;
            color: $headingColor;
            margin: 0px;
          }
          .notif-dropdown {
            cursor: pointer;
          }
        }
      }

      &-body {
        // height: calc(100% - (7.6875rem + 3.75rem));
        // overflow: auto;

        max-height: 500px;
        overflow: auto;
        .block {
          &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.3125rem 0.9375rem;
            p {
              margin: 0;
              font-weight: 600;
              font-size: 0.9375rem;
              line-height: 133%;
              color: $black;
            }

            a {
              font-weight: 600;
              font-size: 0.9375rem;
              line-height: 133%;
              color: $primaryColor;
              &:not(:hover) {
                text-decoration: none;
              }
            }
          }

          &-row {
            display: flex;
            padding: 0.9375rem;

            &:hover {
              background-color: $lightGrey;
            }

            ul {
              display: flex;
              align-items: center;
              list-style: none;
              margin-bottom: 0.5rem;
              li {
                line-height: 1;
                width: auto;
                height: auto;
                padding: 0;
                background: transparent;
                border-radius: 0;
                & + li {
                  margin-left: 1.0625rem;
                  position: relative;

                  @media screen and (min-width: 768px) {
                    margin-left: 1.5625rem;
                  }

                  &::before {
                    content: '';
                    height: 100%;
                    background-color: $darkGrey2;
                    width: 0.0625rem;
                    position: absolute;
                    left: -0.5rem;
                    top: 50%;
                    transform: translateY(-50%);

                    @media screen and (min-width: 768px) {
                      left: -0.75rem;
                    }
                  }
                }
                button {
                  padding: 0;
                  display: block;
                  background-color: transparent;
                  border: none;
                  font-weight: 600;
                  font-size: 0.75rem;
                  line-height: 1;
                  color: $primaryColor;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 1;
              color: $black;
            }

            h4 {
              font-weight: 600;
              font-size: 0.9375rem;
              margin-bottom: 0.5rem;
              line-height: 1;
              color: $black;
            }

            p {
              font-weight: 400;
              font-size: 0.8125rem;
              line-height: 1;
              margin-bottom: 0.5rem;
              color: $black;

              a {
                color: $black;
                font-weight: 600;

                &:not(:hover) {
                  text-decoration: none;
                }
              }
            }

            .icon {
              width: 3.75rem;
              border-radius: 50%;
              flex-shrink: 0;
              height: 3.75rem;
              background: $lightGrey1;
              position: relative;

              .status {
                width: 0.9375rem;
                height: 0.9375rem;
                border-radius: 50%;
                position: absolute;
                right: 0;
                bottom: 0;

                &.online {
                  background-color: $success;
                }

                &.offline {
                  background-color: $error;
                }
              }

              & + div {
                width: calc(100% - 3.75rem);
              }

              svg {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 1.3125rem;
                height: 1.3125rem;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
        }
      }

      &-search {
        margin-top: 0.9375rem;
        position: relative;

        svg {
          position: absolute;
          left: 1.1875rem;
          top: 50%;
          transform: translateY(-50%);
        }

        input {
          height: 2.5rem;
          background: $inputBg;
          width: 100%;
          border-radius: 3.125rem;
          border: none;
          padding: 0 1.25rem 0 3.4375rem;
          font-weight: 400;
          font-size: 0.9375rem;
          line-height: 1.875rem;
          color: $inputTextColor;
        }
      }

      &-footer {
        a {
          height: 3.75rem;
          background: $white;
          border-top: 0.0625rem solid $lightGrey1;
          border-radius: 0 0 0.5rem 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          text-decoration: none;
          font-weight: 600;
          font-size: 1rem;
          line-height: 188%;
          color: $primaryColor;
        }
      }
    }
  }
}
.navbar-dropdown--menu{ 
  right: 0;
  &.drop-left {
    left: 0;
    right: auto;
  }
}

.chat-nav {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #d9d9d9;
  position: fixed;
  bottom: 20px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    border-radius: 50%;
    height: 100%;
    background-color: $lightGrey1;
  }

  &.new-chat {
    bottom: 70px;
  }
}

.notif-card {
  display: flex;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: $lightGrey;
  }
  &--inner {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: auto;
  }
  &--profile {
    flex: initial;
    width: 60px;
    height: 60px;
    position: relative;
    .notif-icon {
      position: absolute;
      right: -5px;
      bottom: -5px;
    }
  }
  &--details {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .notif-actions {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      > li {
        position: relative;
        padding-right: 15px;
        font-weight: 600;
        font-size: 12px;
        &::after {
          content: '|';
          position: absolute;
          right: 5px;
          color: #5b5b5b;
          font-weight: 400;
        }
        &:last-child::after {
          content: '';
        }
      }
    }
    .notif-time {
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
    }
  }
  &--title{
      font-weight: 400;
      font-size: 13px;
      margin: 0px;
      line-height: normal;
      b{
        text-transform: capitalize;
      }
  }
  &--preview{
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
  }
  &--actions {
    display: flex;
    flex: 0;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    .dropdown-menu {
      background: #ffffff;
      border: 1px solid #dddddd;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 12px;
      width: 280px;
      .dropdown-item {
        font-weight: 400;
        font-size: 15px;
        white-space: break-spaces;
      }
    }
  }
  &--statusdot {
    background: #3d5a80;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
  }
}
.header-btn-container {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  // margin-top: 15px;
  > button {
    border-radius: 50px;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 15px;
    padding: 3px 16px;
    &.active {
      background: #d9d9d9;
    }
  }
}
