.user-settings {
  font-family: 'Inter';
  font-style: normal;

  .accordion-body {
    .custom-radio {
      label {
        span {
          &::after {
            top: 5.5px !important;
          }
        }
      }
    }
  }

  .sub-accordion {
    padding: 0px;
    border: none;
    margin-top: 20px;

    .accordion-header {
      img {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        border: 1px solid #cccccc;
      }
    }
  }

  .bordered-container {
    overflow: visible;
  }

  &--main-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #3d5a80;
  }

  .tab-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
  }

  .page-list-wrapper {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;

    .logo {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      overflow: hidden;
    }
  }

  &--container {
    @media screen and (min-width: 1250px) {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .hr {
    border-top: 1px solid $grey1;
  }

  .btn {
    // min-height: 2.5rem;
    line-height: normal;
    font-size: 1rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .btn-secondary {
    background-color: $primaryGrey;
    color: $primaryColor;
    border: none;
    height: auto;
    box-shadow: none;

    &:active {
      background-color: $primaryGrey;
      color: $black;
    }

    &:active:focus {
      box-shadow: none;
    }
  }

  &--account {
    .setting-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
    }

    .name-section {
      padding: 0;
    }

    .deactivation-section {
      .deactivation-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        text-align: right;
        color: $primaryColor;
        cursor: pointer;
      }

      .custom-checkbox span::after {
        top: 0.4375rem;
      }
    }

    .deactivation-modal {
      .modal-header {
        padding-bottom: 0 !important;
      }
    }

    p {
      margin-bottom: 1rem;
    }

    p.email {
      word-wrap: anywhere;
    }

    .label {
      font-size: $fs-16;
      margin-bottom: $fs-8;
    }

    .label-subtext {
      font-size: $fs-15;
      margin-bottom: $fs-8;
    }

    .links {
      font-size: $fs-15;
      color: $primaryColor !important;
      font-weight: 500;
    }

    .form-group {
      margin: 0px;
    }

    @media screen and (min-width: 768px) {
      p {
        margin-bottom: 0.5rem;
      }
    }

    @media screen and (min-width: 1024px) {
      p {
        margin-bottom: inherit;
      }
    }
  }

  &--notifications {
    .accordion-button:not(.collapsed) {
      color: #000;
      background-color: #fff;
      border: none;
      box-shadow: none;
    }

    .description {
      font-weight: $fw-600;
      font-size: $fs-14;
      margin-bottom: 0.5rem;
    }
  }

  &--pages {
    .usp-header {
      button {
        p {
          font-size: 18px;
        }
      }
    }

    .card {
      border: unset;
      cursor: pointer;

      &:active {
        background-color: $lightGrey1;
      }

      &:hover {
        background-color: $lightGrey2;
      }

      &.pages-card {
        &:not(:last-of-type) {
          border-bottom: 1px solid #e8e8e8;
        }

        .page-list-wrapper {
          button {
            min-width: 200px;
            margin-right: 2rem;
          }
        }

        border-radius: 0;
        padding: 30px 10px 20px 10px;
      }
    }

    .page-details {
      position: relative;
      flex-grow: 1;
    }

    .more_actions {

      // margin-left: 3rem;
      .threeDots:after {
        content: '\2807';
        font-size: 30px;
        cursor: pointer;
        color: $darkGrey2;
      }
    }

    .more-menu {
      position: absolute;
      background: $white;
      // box-shadow: 0 0 10px $lightGrey1;
      border: 1px solid $lightGrey1;
      width: 12rem;
      padding: 0.5rem;
      z-index: 1;
      border-radius: 0.5rem;
      right: 0;
      overflow: hidden;

      .link {
        padding: 0.2rem;
        cursor: pointer;

        &:active {
          background-color: #d6d8de;
        }

        &:hover {
          background-color: #e4e6eb;
        }
      }
    }

    .avatar {
      height: 50px;
      width: 50px;
    }

    .company-icon {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      object-fit: cover;
    }

    .company-banner {
      position: relative;
      height: 158px;
      background: $darkGrey1;
      padding: 0;
      margin-bottom: 5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .upload-banner {
        position: absolute;
        bottom: 0.5rem;
        right: 1.8rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px;
        margin-bottom: 10px;
        margin-right: 20px;
        background: #e4e6eb;
        color: #5b5b5b;
      }

      .upload-banner-text {
        margin-left: 3.4px;
      }

      .upload-logo {
        position: absolute;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background: #d9d9d9;
        left: 2rem;
        bottom: -4rem;
        border: none;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .upload-icon {
          width: 130px;
          height: 130px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          cursor: pointer;
          position: absolute;
          top: 2.4rem;
          left: 2.4rem;
          border: none;
        }

        .upload {
          position: absolute;
          top: 28%;
          left: 28%;
        }
      }
    }

    .company_detail {
      min-height: 100px;
    }

    .website {
      padding-left: 1rem !important;
    }

    .form-group {
      label {
        font-size: 14px;
        color: #5b5b5b;
      }
    }

    .borderless-btn {
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: #e4e6eb;
      }
    }

    .no-posts-image-container {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      .no-posts-image {
        height: 250px;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
      }

      p {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
      }

      button {
        p {
          color: #3d5a80;
        }
      }
    }

    .form-group-outer {
      margin-bottom: 0;

      .form-group {
        margin-bottom: 0;

        .form-control {
          .error {
            &:focus {
              border-color: #e41c19;
              box-shadow: 0 0 0 0.0625rem #e41c19;
            }
          }
        }
      }

      .text-danger {
        font-size: 14px;
        line-height: 16px;
        color: #e41c19;
      }
    }
  }

  &--team {
    .table-responsive {
      max-height: 300px;
      overflow-y: auto;
    }

    .email,
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }

    .field-label-container {
      display: flex;
      justify-content: space-between;
    }

    .field-label {
      text-align: left;
      font-size: 14px;
      color: #737373;
      margin-bottom: 2px;
    }

    .field-action {
      font-size: 12px;
      color: #3d5a80;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .text-primary {
      color: $pageHeadingColor !important;
    }

    .table {
      vertical-align: middle;
      border-radius: $rounded-5;
      -webkit-border-radius: $rounded-5;
      -moz-border-radius: $rounded-5;
      -ms-border-radius: $rounded-5;
      -o-border-radius: $rounded-5;
    }

    .table-light {
      background-color: $textLight;
      vertical-align: middle;
      height: 3.438rem;
    }
  }

  .disabled-button {
    background: #3d5a80 !important;
    color: white !important;
    opacity: 1 !important;
  }

  &--integration {

    .integration-button,
    .continue-button {
      // height: 3.438rem;
      vertical-align: middle;
      border-radius: 5px !important;

      &:nth-child(2n -1) {
        margin-right: 1rem;
      }
    }

    .integration-button {
      width: 16.875rem;
      background: #e4e6eb;
      color: #3d5a80;
      opacity: 1 !important;

      @media screen and (max-width: 1023px) {
        margin-bottom: 1rem;
      }
    }

    .continue-button {
      width: 10rem;
    }
  }

  &--payment {
    .table-responsive {
      td {
        vertical-align: middle;

        .btn {
          margin-top: -9px;
        }
      }
    }

    .card,
    .card-body {
      border-radius: 0;
      background-color: #fafafa;

      .form-control {
        background: $white;
      }
    }

    .card,
    .card>* {
      border-color: $lightGrey1;
    }

    .card-header {
      height: 3.438rem;
    }

    .save-button {
      height: 3.125rem;
      width: 13.875rem;
      border-radius: 50rem;
      -webkit-border-radius: 50rem;
      -moz-border-radius: 50rem;
      -ms-border-radius: 50rem;
      -o-border-radius: 50rem;
    }
  }

  &--subscriptions {
    .subscription-table {
      .table {
        vertical-align: start;
        border: 1px solid $lightGrey1;
        border-radius: $rounded-5;
        -webkit-border-radius: $rounded-5;
        -moz-border-radius: $rounded-5;
        -ms-border-radius: $rounded-5;
        -o-border-radius: $rounded-5;

        tr {
          height: 3.438rem;
        }

        .table-light {
          background-color: $textLight;
          vertical-align: middle;
          height: 3.438rem;
        }

        .features,
        .basic,
        .professional,
        .standard {
          width: 300px;
          padding: 1rem;

          &> :first-child {
            height: 10rem;
            display: block;
          }

          @media screen and (min-width: 1250px) {

            td,
            tr {
              display: block;
            }
          }

          .btn {
            width: 9.375rem;
          }
        }

        .features {
          @media screen and (max-width: 1023px) {
            width: 10rem;
            display: block;
          }
        }

        .basic {
          background-color: $white;
          text-align: center;
        }

        .professional {
          padding: 1rem;
          background-color: $lightWhite;
          text-align: center;
        }

        .standard {
          padding: 1rem;
          background-color: $lightGrey1;
          text-align: center;
        }
      }
    }
  }

  &--page-header {
    font-weight: 600;
    font-size: 20px;
    color: $black;
    padding-bottom: 1rem;
    border-bottom: 1px solid $lightGrey1;
  }

  &--subscriptions {
    .pricing-table-container {
      display: flex;
      border: 1px solid $lightGrey1;
      justify-content: stretch;

      .pricing-table {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        border-right: 1px solid $lightGrey1;
        flex: 1;

        &.lightGreyBg {
          background-color: #f6f7f8;
        }

        &.darkGreyBg {
          background-color: #e9eaeb;
        }

        &--header {
          min-height: 150px;
          display: flex;
          flex-direction: column;
          gap: 6px;
          align-items: center;
          justify-content: flex-start;
          padding: 20px;

          h3 {
            font-style: normal;
            font-weight: $fw-600;
            font-size: $fs-20;
            color: $black;
            text-align: center;
            margin: 0;
          }

          p {
            font-weight: $fw-400;
            font-size: $fs-18;
            color: #a9a9a9;
          }
        }

        &--body {
          display: flex;
          padding: 20px;

          .plan-details {
            margin: 0;
            padding: 0;
            display: block;
            list-style: none;
            width: 100%;

            li.plan-points {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              font-weight: 400;
              font-size: 14px;
              color: $black;
              margin-bottom: 20px;
              align-items: center;
              line-height: normal;
            }

            li.mark-points {
              display: flex;
              justify-content: center;
              gap: 10px;
              font-weight: 400;
              font-size: 14px;
              color: $black;
              margin-bottom: 20px;
              align-items: center;
              line-height: normal;
            }

            li.mark-points:not(:first-child) {
              height: 34px;
            }
          }
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.limit-indicator {
  text-align: right;
}

.payment-details {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 20px;
  align-items: center;

  .plan-name {
    font-weight: 600;
    font-size: 16px;
    color: $darkGrey2;
  }

  .plan-price.box {
    background: #e4e6eb;
    padding: 5px 10px;
  }
}

.card-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid $lightGrey1;
}

.settings-custom-checkbox span::after {
  top: 0.4375rem !important;
}