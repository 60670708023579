.form {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      height: 30px;
      left: 401px;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #3d5a80;
    }
    h4 {
      font-weight: 400;
      font-size: 15px;
      color: $primaryColor;
      cursor: pointer;
    }
  }

  .actions {
    display: flex;
    column-gap: 15px;
    justify-content: space-between;
    .save {
      background: #0255fe;
      border-radius: 5px;
      color: white;
      padding: 5px 10px;
      outline: none;
      border: none;
    }
    .disable {
      // background: #97a4c0;
      border-radius: 5px;
      background: #0255fe;
      color: white;
      padding: 5px 10px;
      outline: none;
      border: none;
      cursor: not-allowed;
    }
    .cancel {
      background: #e4e6eb;
      border-radius: 5px;
      border: none;
      color: black;
      padding: 5px 10px;
    }
  }
}
.form__first-coloumn {
  display: grid;
  grid-template-columns: 40% 27% 28%;
  align-items: center;
  column-gap: 2.5%;
}
.date-wrapper {
  position: relative;
  .date {
    border: 1px solid $inputBorderColor;
    padding: 11px 5px 11px 15px;
    border-bottom-left-radius: 80px !important;
    border-top-left-radius: 80px !important;
    width: 80%;
    background: $inputBGColor;
    position: absolute;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 2;
    white-space: nowrap;
  }
  .form-group {
    z-index: 1;
  }
  .date-selector {
    width: 100%;
    background: url('../../assets/icons/Calendar.svg') no-repeat 95%;
    background-size: 25px;
    &:focus-visible {
      outline: none;
    }
  }
}
.Profile_Tab .border-right {
  border-right: 1px solid $lightGrey1;
  padding-right: 1.25rem;
  align-items: center;
}
.meetings {
  font-size: 1rem;
  line-height: 120%;
  padding-top: 1rem;
  &__header,
  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
  &__details {
    margin-top: 0.75rem;
  }
  &__title {
    font-weight: 600;
  }
  &__all {
    &:active,
    &:visited {
      color: #3d5a80;
    }
    text-decoration: none;
  }
  &__links {
    &--join {
      &:hover,
      &:visited {
        color: #3d5a80;
      }
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 160px;
      display: block;
      color: #3d5a80;
      overflow: hidden;
      text-decoration: none !important;
      font-size: 15px;
    }
    &--visit {
      &:hover,
      &:visited {
        color: $grey;
      }
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      width: 160px;
      color: $grey;
      text-decoration: none !important;
      font-size: 14px;
    }
  }
  &__time {
    font-weight: 600;
    color: $darkGrey2;
    font-size: 14px;
    color: #3d5a80;
    min-width: 70px;
  }
  &__links{
    flex:1;
  }
  &__links:last-child a {
  }
}
.calendar-section {
  .response-container {
    padding: 5px 10px;
    .response-header {
      display: flex;
      border-bottom: 1px solid #d9d9d9;

      p {
        padding: 5px 12px;
        font-size: 14px;
        font-weight: 500;
        border-right: 1px solid #d9d9d9;
      }
    }
    .response-body {
      margin-top: 10px;
      padding: 0 12px;

      h3 {
        margin: 2px 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
      h5 {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #0255fe;
      }
    }

    .divider {
      border-bottom: 1px solid #d9d9d9;
      margin: 10px;
    }

    .meeting-info {
      display: grid;
      grid-template-columns: 0.25fr 0.75fr;
      column-gap: 20px;
      row-gap: 5px;
      padding: 0 12px;

      h5 {
        font-weight: 600;
        margin: 0;
        font-size: 15px;
        line-height: 25px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
      }
    }
  }

  a {
    text-decoration: none;
    color: black;
  }
}
.second-column {
  flex: 1;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
}

.second-column > .primary-card {
  padding: 10px 20px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: black;
  }

  .meeting-widget {
    margin-top: 15px;

    .single-meeting-con {
      display: flex;
      align-items: center;
      column-gap: 30px;
      padding: 10px 0;
      border-bottom: 1px solid #d9d9d9;

      .meeting-time {
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        color: #000000;
        min-width: 100px;
      }

      .meeting-details-con {
        h5 {
          font-weight: 400;
          font-size: 15px;
          color: #0255fe;
          margin: 0;
        }

        p {
          font-weight: 400;
          font-size: 15px;
          margin: 0;
          color: #747474;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #CBF1D3 !important;
  .fc-daygrid-day-number {
    // color: $white !important;
    font-size: 15px;
    line-height: 18px;
  }
}
.buitinSpan {
  margin-left: 32px;
}
// .custom-checkbox input:checked + span:before {
//   border-color: #96acd8 !important;
//   background-color: #81848b !important;
// }
.attendee-action {
  .delete-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrey1;
  padding: 15px 0;
  position: relative;
  .action-btn {
    background: transparent;
    border-radius: 0;
    border: 0;
    color: $darkGrey3;
    height: 36px;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      color: $darkGrey3;
    }
  }
  .action-btn + .action-btn {
    border-left: 1px solid $lightGrey1;
  }

  .modals-popups {
    position: absolute;
    width: fit-content;
    padding: 15px;
    border: 1px solid $inputBorderColor;
    top: 3rem;
    background: $white;
    border-radius: 15px;
    .opt-comment {
      min-height: 90px;
    }
    &.accept-meeting {
      left: 0px;
    }
    &.tentative-meeting {
      left: 120px;
    }
    &.decline-meeting {
      left: 240px;
    }
    &.propose-meeting {
      left: 350px;
    }
    &.send-update {
      left: 240px;
    }
  }
}

.fc {
  thead th {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #4f4f4f;
    tr {
      border-bottom: 2px solid $lightGrey1;
    }
  }
  .fc-col-header-cell-cushion {
    padding: 10px 16px;
  }
  .fc-toolbar-title {
    font-size: 1.5rem;
    line-height: 120%;
    color: #4a5660;
  }
  .fc-button {
    border: none !important;
  }
  .fc-today-button {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .fc-timeGridDay-button {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .fc-button-group {
    font-size: 1rem;
    letter-spacing: -0.1px;
    font-weight: 500;
    .fc-prevBtn-button,
    .fc-nextBtn-button {
      width: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .fc-button-primary {
    background-color: $darkGrey2 !important;
    text-transform: capitalize;
    &:hover,
    &:focus {
      background-color: $primaryColor !important;
    }
    &:disabled {
      background-color: $primaryColor !important;
    }
    &.fc-button-active {
      background-color: $primaryColor !important;
    }
  }
}

.meetings__title{
  color: #3d5a80!important;
}
.error-msg {
  z-index: 5000;
  position: absolute;
  top: 40px;
  left: 100px;
  padding: 5px 15px;
  display: flex;
  border-radius: 10px;
  min-width: 200px;
  justify-content: space-between;
  align-items: center;
  color: $error;
  // font-weight: $fw-700;
}

.meeting-popup {
  .modal-content {
    border-radius: 1rem;
  }
  .modal-header {
    color: $primaryColor ;
  }
  .modal-body {
    padding: 0rem 2.5rem 1.5rem;
  }
}
.meeting-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 30px;
  color: $darkGrey2;
}
.alert-warning-custom {
  background: #fee9d6;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: $darkGrey2;
  border-radius: 0;
}
.attende-response {
  background: #e7f3ff;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 1rem 0;
}
.passcode {
  input:checked + span:before {
    opacity: 0.5 !important;
    // border-color: #7ea8fc !important;
    // background-color: #7ea8fc !important;
  }
}
.fc-h-event .fc-event-time {
  max-width: fit-content;
  overflow: unset!important;
}
.upcoming-meeting-card{
  padding-bottom: 15px;
  border-bottom: 1px solid $lightGrey1;
  margin-bottom: 15px;
} 
.upcoming-meeting-card:last-child{
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.seemore{
  overflow: hidden!important;
  height: 200px!important;
}

.seemoreCursor {
  cursor: pointer;
  color: #3d5a80 !important;
}

.fc-event-title-container{
  overflow: hidden;
}

.fc-event-title{
  white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}