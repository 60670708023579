.my-circle {
  // width: 100%;
  // padding-right: 1.5rem;
  border: 1px solid rgb(228, 230, 235);
  border-radius: 8px;
  @media screen and (min-width: 1280px) {
    // max-height: calc(100vh - (60px + 0.9375rem + 0.9375rem));
    // padding-right: 0;
    // width: 21.875rem;
    // max-width: 100%;
  }

  &--header {
    padding: 10px;
    h3 {
      display: flex;
      align-items: center;
      color: $primaryColor;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
    }
  }

  &--body {
    overflow: auto;
    max-height: 250px;
    padding-top: 15px;
    padding-left: 10px;
  }

  &--footer {
    border-top: 0.0625rem solid $lightGrey1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    width: 90%;
    margin: 0 auto;
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #0255fe;
      color: $primaryColor;
      &:not(:hover) {
        text-decoration: none;
      }
    }
  }
}

.circle-detail {
  padding: 1rem;
  width: 456px;
  max-width: 100%;
  box-shadow: none;

  &--header {
    @extend %v-center;
    column-gap: 1.25rem;
    margin-bottom: 1rem;

    .content {
      flex-grow: 1;

      button {
        height: 1.3125rem;
        background: $white;
        border: 0.0625rem solid $lightGrey1;
        border-radius: 3.125rem;
        font-weight: 500;
        padding: 0 0.5625rem;
        font-size: 0.75rem;
        line-height: 1;
        display: flex;
        align-items: center;
        color: $black;
        transition: all ease-in-out 0.3s;

        &:hover {
          background-color: $primaryColor;
          border-color: $primaryColor;
          color: $white;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &--footer {
    display: flex;
    column-gap: 0.25rem;
    padding-left: 90px;

    button {
      width: calc((100% - 1.5rem) / 3);
      height: 2.25rem;
      border: none;
      color: $black;
      transition: all ease-in-out 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      background: #e4e6eb;
      border-radius: 5px;
      &:focus {
        outline: none;
      }

      &:hover {
        background-color: darken($color: #e4e6eb, $amount: 5%);
      }
    }
  }
}
