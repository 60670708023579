.add-contact {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px 15px;
  text-align: center;
  top: 80px;

  ul {
    list-style: none;
    margin: 60px 0 0;
    padding: 0;
    column-gap: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      border: 1px solid #5b5b5b;
      border-radius: 3px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .wrap {
    p {
      color: #000000;
      margin-bottom: 30px;
      font-size: 14px;
    }
  }

  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #0255fe;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 5px;
    color: #000000;
  }

  button {
    transition: all ease-in-out 0.3s;
    margin-top: 30px;
    width: 249px;
    height: 48px;
    border: none;
    max-width: 100%;
    background: #e4e6eb;
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 87%;
    color: #5b5b5b;

    &:hover {
      background: #ccddfe;
      color: $primaryColor;
    }
  }

  input {
    font-weight: 500;
    height: 45px;
    display: block;
    padding: 0 13px;
    width: 100%;
    font-size: 16px;
    line-height: 25px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #5b5b5b;
    pointer-events: none;
  }
}
