@import '../utils/placeholders';
@import '../utils/variables';

.loader {
  @extend %center-with-direction;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  left: 0;
  top: 0;
  background-color: $loaderOverlay;

  &.secondary {
    position: static;
    height: 150px;
    background-color: transparent;

    span {
      border-top: 0.25rem solid $white;
      border-right: 0.25rem solid $white;
      border-bottom: 0.25rem solid $white;
    }
  }

  p {
    color: $white;
    font-weight: $fw-400;
  }

  span,
  span:after {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
  }
  span {
    margin-bottom: 1rem;
    font-size: 0.625rem;
    position: relative;
    text-indent: -9999em;
    border-top: 0.25rem solid $white;
    border-right: 0.25rem solid $white;
    border-bottom: 0.25rem solid $white;
    border-left: 0.25rem solid $primaryColor;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
}


@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}