@import '../utils/variables';

.header-page{
  width: 100%;
  background: #3D5A80;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 70px;
  color: white;
  .head{
    font-size: 24px;
  }
  .route{
    font-size: 16px;
  }
}

.aboutus-container{
  background: #E4E6EB;
  padding: 50px 0;
  .heading{
    font-size: 24px;
    color: #3D5A80;
    margin: 10px 0;
    font-weight: 700;
  }
  .container{
    background: white;
    padding: 30px 20px;
    .container-heading{
      font-size: 18px;
      line-height: 24px;
      color: #3D5A80;
      margin: 10px 0;
      font-weight: 600;
    }
    .container-text{
      font-size: 16px;
      color: #5B5B5B;
      line-height: 24px;
    }
  }
}

.fs-20{
  font-size: 20px!important;
  line-height: 24px;
  color: #3D5A80;
  margin-bottom: 20px;
  font-weight: 600;
}

.mg-10{
  margin:10px 0;
}

ul{
  margin-left: 15px;
}

.link{
  color: #3D5A80;
  text-decoration: underline;
}