.toggle-switch {
    position: relative;
    border: 1px solid $inputBorderColor;
    padding: 4px;
    border-radius: 50rem;

    .checkbox {
        display: none;

        &:checked+.label .inner {
            margin-left: 0;
        }

        &:checked+.label .switch {
            right: 0.313rem;
            background: $primaryColor;
        }
    }

    .label {
        width: 3rem;
        height: 1.563rem;
        display: block;
        overflow: hidden;
        cursor: pointer;
        border-radius: 20px;
    }

    .inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;

        &:before,
        &:after {
            float: left;
            width: 50%;
            height: 2.25rem;
            line-height: 2.25rem;
            color: $primaryColor;
            font-weight: bold;
            box-sizing: border-box;
        }

        &:before {
            content: "";
            background-color: $secondaryBtnBg;
            color: $primaryColor;
        }

        &:after {
            content: "";
            background-color: $inputBorderColor;
            color: $primaryColor;
        }
    }

    .switch {
        display: block;
        width: 1.563rem;
        height: 1.563rem;
        background: $darkGrey2;
        position: absolute;
        right: 1.625rem;
        border: 0 solid $inputBorderColor;
        transition: all 0.3s ease-in 0s;
        border-radius: 50rem;
        -webkit-border-radius: 50rem;
        -moz-border-radius: 50rem;
        -ms-border-radius: 50rem;
        -o-border-radius: 50rem;
        -webkit-transition: all 0.3s ease-in 0s;
        -moz-transition: all 0.3s ease-in 0s;
        -ms-transition: all 0.3s ease-in 0s;
        -o-transition: all 0.3s ease-in 0s;
    }
}