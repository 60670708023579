@import "../../../scss/utils/variables";

.comments-container {
  width: 100%;
  padding: 0 1rem 1rem 1rem;

  .comment-actions {
    .action-btn {
      &:hover {
        background-color: $primaryGreyBg;
        border-radius: 4px;
      }
    }
  }

  .comment-box {
    padding: 0px !important;
    margin-bottom: 1rem;
    position: relative;

    &.has-comments::before {
      content: '';
      width: 25px;
      height: 30px;
      position: absolute;
      display: block;
      /* bottom: -10px; */
      border-bottom: 1px solid #e4e6eb;
      border-bottom-left-radius: 12px;
      border-left: 1px solid #e4e6eb;
      left: -30px;
      top: -11px;
    }
    &.has-comments::after {
      content: '';
      width: 25px;
      height: 64px;
      position: absolute;
      display: block;
      border-left: 1px solid #e4e6eb;
      left: -30px;
      top: -11px;
    }
  }

  .has-comments {
    .comment-container {
      &:not(:last-child)::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0px;
        border-left: 1px solid #e4e6eb;
        left: -30px;
        top: -10px;
      }
    }
  }

  .comment-container {
    display: flex;
    gap: 10px;
    position: relative;

    .avatar-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatar-icon {
        display: block;
      }

      .avatar-line {
        width: 1%;
        /* border: 1px solid #e4e6eb; */
        background-color: #e4e6eb;
        // box-shadow: -1px 0 0 #e4e6eb;

        // width: 10px;
        height: calc(100% - 65px);
        border-left: 1px solid #e4e6eb;
        /* background-color: #e4e6eb; */
        position: relative;

        &::after {
          content: '';
          width: 30px;
          border-bottom: 1px solid #000;
          height: 30px;
          position: absolute;
          display: block;
          bottom: -10px;
          border-bottom: 1px solid #e4e6eb;
          border-bottom-left-radius: 12px;
          border-left: 1px solid #e4e6eb;
          left: -1px;
        }
      }

      .avatar-line-horizontal {
        width: 51%;
        height: 0.5%;
        align-self: flex-end;
        background-color: #e4e6eb;
        display: none;
      }

      &.avatar-column-level {
        position: relative;

        &::before {
          content: '';
          width: 25px;
          height: 30px;
          position: absolute;
          display: block;
          /* bottom: -10px; */
          border-bottom: 1px solid #e4e6eb;
          border-bottom-left-radius: 12px;
          border-left: 1px solid #e4e6eb;
          left: -30px;
          top: -5px;
        }
      }
    }

    .details-column {
      width: 100%;
      position: relative;

      // &.has-comments::before {
      //   content: "";
      //   position: absolute;
      //   top: 40px;
      //   left: -30px;
      //   bottom: 50px;
      //   border-left: 1px solid #e1e1e1;
      //   z-index: 0;
      // }

      .commentor-details {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        min-height: 40px;

        .edit-comment-options {
          display: flex;
          flex-direction: column;
          position: absolute;
          background: white;
          border: 1px solid #eff3f5;
          border-radius: 10%;
          right: 10px;
          white-space: nowrap;
          overflow: hidden;

          .edit-comment-option {
            cursor: pointer;
            padding: 10px;

            &:hover {
              background-color: #eff3f6;
            }
          }
        }
      }

      .comment-details {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        .comment-bg {
          padding: 10px;
          margin: 10px 0;
          background: #eff3f5;
          border-radius: 8px;

          img {
            max-width: 100%;
            // width: 100%;
          }
        }

        .comment-actions {
          display: flex;
          justify-content: space-between;
        }

        &.has-comments::before {
          content: "";
          position: absolute;
          top: 5px;
          left: -30px;
          bottom: 0;
          border-left: 1px solid #e1e1e1;
          z-index: 0;
        }
      }


      .more-replies {
        position: relative;
        margin-bottom: 1rem;

        &::before {
          content: '';
          width: 25px;
          height: 30px;
          position: absolute;
          display: block;
          /* bottom: -10px; */
          border-bottom: 1px solid #e4e6eb;
          border-bottom-left-radius: 12px;
          border-left: 1px solid #e4e6eb;
          left: -30px;
          top: -11px;
        }
      }
    }
  }
}