@import '../utils/variables';

.dropdown-menu-container {
  .cta {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $lightGrey;
    }

    &:active {
      background-color: $lightGrey1;
    }
  }
}

.my-disk {
  display: flex;
  padding: 1rem;
  font-size: $fs-15;

  .disk-main-heding {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: $pageHeadingColor;
  }

  .left-tabs {
    border-right: 1px solid #d0cfcf;
    min-height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    .team-left-pannel {
      flex: 1;
      margin-bottom: 30px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .team-sub-tab {
        .team-logo {
          border: 1px solid #CCCCCC;
          width: 32px;
          height: 32px;
          border-radius: 100%;
        }
      }
    }

    .tab-menu {
      display: block;
      padding: 0.5rem;
      width: 100%;
      text-align: left;
      border-radius: 5px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      line-height: 1.7rem;

      &.subtab-active {
        color: $darkMainColor;
        font-weight: 700;
      }

      &.active,
      &:hover {
        color: $darkMainColor;
        font-weight: 700;
        background-color: $primaryGreyBg;
      }
    }

    .sub-tab {
      list-style: none;
      margin-left: 20px;
      min-width: 200px;
      // margin-top: 0.5rem;

      li {
        display: flex;
        gap: 10px;
        cursor: pointer;
        line-height: 1.7rem;
        text-transform: capitalize;
        padding: 9px;

        .menu-icon {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $lightGrey1;
          border-radius: 50%;
        }

        &:hover,
        &.active {
          color: $darkMainColor;
          font-weight: 700;
          background-color: $primaryGreyBg;
          border-radius: 5px;

          .menu-icon {
            background-color: $primaryColor;

            >svg {
              filter: invert(50%) brightness(2);
            }
          }
        }

        .avtar {
          display: inline-block;
          // border: 1px solid $grey;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }

      li+li {
        margin-top: 0.2rem;
      }
    }

    @media only screen and (max-width: 900px) {
      min-height: auto;
    }
  }

  .right-pannel {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .row {
      height: 95%;
    }

    .row.search-listing {
      height: 77% !important;
    }

    .my-disk-header {
      height: 5%;
      display: flex;
      margin-bottom: 1rem;
      align-items: center;
      justify-content: space-between;

      .brd-crum {
        // max-width: 60%;
        flex: 1;
        flex-grow: 1;
        font-weight: 700;
        font-size: 16px;

        .history-menu {
          position: relative;

          .hm-list {
            padding: 0;
            margin: 0;
            border-radius: 12px;
            box-shadow: 0px 0px 10px #999;
            background-color: $white;
            position: absolute;
            width: 200px;
            max-height: 150px;
            overflow-y: auto;
            z-index: 10;
            display: flex;
            flex-direction: column-reverse;

            .hml-li {
              cursor: pointer;
              padding: 0.5rem 1rem;
              font-size: 14px;
              user-select: none;
              font-weight: normal;
              display: flex;
              align-items: center;

              >span {
                padding-left: 10px;
              }

              &:hover {
                background-color: $lightGrey2;
              }

              &:active {
                background-color: $lightGrey1;
              }
            }
          }
        }

        span {
          display: inline-block;
        }

        span+span {
          // margin-left: 5px;
        }

        .link {
          color: $pageHeadingColor;
          cursor: pointer;

          &.active {
            cursor: default;
            color: $darkGrey3;
          }
        }
      }

      .btn-wrapper {
        display: flex;
        align-items: center;

        .search-container {
          width: 38px;
          height: 38px;
          overflow: hidden;
          cursor: pointer;
          border-radius: 50%;
          position: relative;

          .search-btn {
            background: transparent;
            position: absolute;
            border: none;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            z-index: 9;

            >svg {
              margin: 0px;
            }
          }

          &.active {
            width: auto;
            border-radius: 0px;
          }

          input {
            background: #e5e6eb;
            border-radius: 100px;
            font-size: 12px;
            line-height: 14px;
            color: $darkGrey1;
            padding: 12px 16px 12px 40px;
            flex: 0 1 260px;
            border: none;
            outline: none;
            position: relative;
          }
        }

        svg {
          display: inline-block;
          margin-left: 1rem;
        }

        .viewBtn {
          cursor: pointer;
        }
      }

      &.mobile-search {
        input {
          background: #e5e6eb;
          border-radius: 100px;
          font-size: 12px;
          line-height: 14px;
          color: $darkGrey1;
          padding: 12px 16px 12px 45px;
          flex: 0 1 100%;
          border: none;
          outline: none;
          position: relative;
          background: url('../../assets/icons/search_icon.svg') no-repeat scroll 18px 7px #e5e6eb;
          background-size: 22px;
        }
      }
    }

    .team-left-pannel {
      height: calc(100vh - 100px);
    }

    .my-disk-left-block {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 1rem;
      height: 100%;
      overflow: auto;


      .my-disk-list-items {
        padding-bottom: 10px;
      }

      .headers {
        z-index: 2;
        background-color: white;
        position: sticky;
        top: 0;
        border-bottom: 1px solid $lightGrey1;
        font-weight: 700;
        padding: 0.5rem 0;
        display: grid;
        grid-template-columns: 40% 30% 20% 10%;
        grid-template-areas: 'hd1 hd2 hd3 hd4';

        .hd3 {
          justify-self: center;
        }
      }

      .headers.no-last-col {
        grid-template-columns: 40% 50% 10%;
        grid-template-areas: 'hd1 hd2 hd3';
      }

      .file-wrapper {
        display: block;
        margin-top: 0.5rem;
        position: relative;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;

        &.active,
        &:hover {
          background-color: $lightGrey;
        }

        &:active {
          background-color: $lightGrey1
        }

        .file {
          padding: 10px;
          display: grid;
          grid-template-columns: 40% 30% 20% 10%;
          grid-template-areas: 'g1 g2 g3 g4';
          // text-transform: capitalize;
          line-height: 1;
          align-items: center;

          .team-logo {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid #CCCCCC;
          }
        }

        .g1 {
          display: flex;
          gap: 5px;
        }

        .g3 {
          justify-self: center;
        }

        .g4 {
          justify-self: center;
        }
      }

      .share-file-wrapper {
        display: block;
        // margin-top: 0.5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .file {
          display: grid;
          grid-template-columns: 40% 50% 10%;
          grid-template-areas: 'g1 g2 g3';
          // text-transform: capitalize;
          padding: 10px;
          margin-top: 0.5rem;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background-color: $lightGrey;
          }

          &:active {
            background-color: $lightGrey1;
          }

          .avtar {
            // border: 1px solid $grey;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
          }

          .g1 {
            .file-name {
              padding: 0px 10px;
            }
          }

          .g3 {
            justify-self: center;
          }

        }

        .remove-me {
          right: 5px;
          top: 3.5rem;
        }
      }

      .grid-item-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        .no-files-message {
          grid-area: 1 / 2 / 2 / 5;
          text-align: center;
        }

        .grid-view-file {

          min-width: 110px;
          // text-transform: capitalize;
          text-align: center;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          position: relative;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background-color: $lightGrey;
          }

          &:active {
            background-color: $lightGrey1;
          }

          .grid-view-submenu {
            position: absolute;
            right: 0px;
            top: 0px;
            padding: 10px;
            cursor: pointer;
          }

          .svg {
            display: block;
          }


          span {
            display: block;
            text-align: center;

            &.avatar {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 50%;
            }
          }

          .grid-view-name {
            display: flex;
            position: relative;
            align-items: center;
            column-gap: 0.5rem;

            .mobile-more-menu {
              width: 12rem;
              top: 3rem;
            }
          }

          &:nth-child(3n + 1) {
            .grid-view-name {
              .mobile-more-menu {
                right: auto;
                left: 0;
              }
            }
          }

          .remove-me {
            top: 110%;
            right: 1.7rem;
            white-space: nowrap;
          }
        }
      }
    }

    .my-disk-right-block {
      border-left: 1px solid #d0cfcf;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .head {
        display: block;
        padding: 0.5rem;
        width: 100%;
        text-align: left;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        line-height: 1.7rem;
        background-color: $primaryGrey;
        text-transform: capitalize;
      }

      .info {
        flex: 1;
        padding: 0px 10px;

        strong {
          color: $headingColor;
        }
      }

      .cta-wrapper {
        background: $primaryBg;
        border: 1px solid $ctaBorderGrey;
        border-radius: 8px;
        padding: 10px;

        .file-options {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          // grid-column-gap: 10px;
          // grid-row-gap: 10px;
          border-bottom: 1px solid #d0cfcf;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }

        .file-options-view-access {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(1, 1fr);
        }

        .folder-options {

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          // grid-column-gap: 10px;
          // grid-row-gap: 10px;
        }

        .cta {
          text-align: center;
          font-size: $fs-12;
          cursor: pointer;
          padding: 10px;
          color: #212529;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 10px;

          &:hover {
            background-color: $primaryGrey;
          }

          &:active {
            background-color: $lightGrey1;
          }
        }
      }
    }
  }


  .storage {
    position: relative;
    width: 17rem;
    max-width: 100%;
    font-size: 15px;

    @media only screen and (max-width: 520px) {
      position: relative;
      bottom: auto;
      margin-top: 2rem;
    }

    &--top {
      display: flex;
      justify-content: space-between;
    }

    &--bottom {
      display: flex;
      justify-content: space-between;
    }
  }

  .search-head {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;


    .filter-container {

      &--file-types {}

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          padding: 0.5rem 1rem;

          &.active,
          &:hover {
            background-color: $lightGrey;
          }
        }
      }
    }

    .filter-left {
      display: flex;
      gap: 10px;
    }

    .btn-wrapper {
      position: relative;

      .filter-btn {
        background: $primaryGrey;
        height: 2.2rem;
        font-weight: 400;
        line-height: 1;
        font-size: 90%;
        border-radius: 2rem;
      }

      &:last-child {
        justify-content: flex-end;
      }

      .filter-wrapper {
        position: absolute;
        min-width: 14rem;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        ;
        border-radius: 12px;
        top: 3rem;
        z-index: 5;
        background: $white;
        border: 1px solid #E4E6EB;

        .form-group {
          margin: 0px;
        }

        &::before {
          content: '';
          position: absolute;
          border-left: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #E4E6EB;
          left: 50%;
          top: -1.2rem;
          z-index: -1;
        }

        &::after {
          content: '';
          position: absolute;
          border-left: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $white;
          left: 50%;
          top: -1.15rem;
          z-index: -1;
        }

        .filter-header {
          padding: 10px 20px;
          border-bottom: 1px solid #ddd;
          display: flex;
          justify-content: space-between;
        }

        ul {

          // padding: 20px;
          li {
            padding: 0.5rem 1rem;
            cursor: pointer;

            &:hover {
              background-color: $lightGrey;
            }

            &:active {
              background-color: $lightGrey1;
            }
          }
        }

        &.types {
          left: -8rem;

          li {
            list-style: none;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            gap: 1rem;
          }

          li+li {
            // margin-top: 0.6rem;
          }
        }

        &.last-modify {
          left: -4rem;
          width: 15rem;

          li {
            list-style: none;
            align-items: center;

            .icon {
              margin-right: 1rem;
              width: 1rem;
            }
          }

          li+li {
            margin-top: 0.6rem;
          }
        }

        &.people {
          left: -5.5rem;
          width: 15rem;

          li {
            list-style: none;
            align-items: center;

            .icon {
              margin-right: 1rem;
              width: 1rem;
            }
          }

          li+li {
            margin-top: 0.6rem;
          }
        }

        &.sort {
          left: 0rem;
          width: 15rem;

          li {
            list-style: none;
            align-items: center;

            .icon {
              margin-right: 1rem;
              width: 1rem;
            }
          }

          li+li {
            margin-top: 0.6rem;
          }
        }
      }
    }
  }

  .mobile-filters {
    position: absolute;
    padding: 10px;
    box-shadow: 0 0 10px $grey;
    right: 1rem;
    border-radius: 0.8rem;
    background: $white;
    z-index: 1;

    .btn-wrapper {
      position: relative;
      margin-bottom: 1rem;

      .filter-btn {
        background: $lightGrey;
        height: 2.2rem;
        font-weight: 400;
        line-height: 1;
        font-size: 90%;
        border-radius: 2rem;
      }

      &:last-child {
        justify-content: flex-end;
      }

      .filter-wrapper {
        position: absolute;
        padding: 1rem;
        min-width: 10rem;
        box-shadow: 0 0 10px $grey;
        border-radius: 1rem;
        top: 3rem;
        z-index: 5;
        background: $white;

        &::before {
          content: '';
          position: absolute;
          border-left: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $grey;
          left: 50%;
          top: -1.2rem;
          z-index: -1;
        }

        &::after {
          content: '';
          position: absolute;
          border-left: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $white;
          left: 50%;
          top: -1.15rem;
          z-index: -1;
        }

        &.types {
          left: -8rem;

          li {
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          li+li {
            margin-top: 0.6rem;
          }
        }

        &.last-modify {
          left: -4rem;
          width: 15rem;

          li {
            list-style: none;
            align-items: center;

            .icon {
              margin-right: 1rem;
              width: 1rem;
            }
          }

          li+li {
            margin-top: 0.6rem;
          }
        }

        &.people {
          left: -5.5rem;
          width: 15rem;

          li {
            list-style: none;
            align-items: center;

            .icon {
              margin-right: 1rem;
              width: 1rem;
            }
          }

          li+li {
            margin-top: 0.6rem;
          }
        }

        &.sort {
          left: 0rem;
          width: 15rem;

          li {
            list-style: none;
            align-items: center;

            .icon {
              margin-right: 1rem;
              width: 1rem;
            }
          }

          li+li {
            margin-top: 0.6rem;
          }
        }
      }
    }
  }

  .mobile-more-menu {
    position: absolute;
    background: $white;
    right: 1.2rem;
    top: 1.5rem;
    z-index: 10;
    box-shadow: 0 0 10px #999;
    border-radius: 0.5rem;
    white-space: nowrap;
    width: fit-content;
    font-size: 14px;
    overflow: hidden;

    .cta {
      user-select: none;
      color: #212529;
      text-decoration: none;
      display: block;
      text-align: left;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $lightGrey2;
      }

      &:active {
        background-color: $lightGrey1;
      }

      svg {
        margin-right: 0.5rem;
        width: 20px;
      }
    }

    &.grid-view {
      right: -10px;
    }
  }

  .more-menu-grid {
    position: absolute;
    top: 0;
    right: -10px;
  }
}

.folder-files {
  li {
    display: flex;
    justify-content: flex-start;
    // max-width: 80%;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
    border-radius: 10px;

    &:hover {
      background-color: $lightGrey;
    }

    &:active {
      background-color: $lightGrey1;
    }

    &.active {
      background-color: $lightGrey1;
    }

    .chk-box {
      width: 30px;
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 10px;
      border-radius: 10px;



      .icon {
        margin-right: 5px;
        height: max-content;
        width: max-content;

        span.plus-minus {
          margin-right: 5px;
        }
      }

      .name {
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }

  li+li {
    margin-top: 0.1rem;
  }
}

.folder-item {
  .accordion-item {
    border: 0;
    margin-bottom: 0.1rem;

    .accordion-title {
      display: flex;
      padding: 10px;
      cursor: pointer;
      border-radius: 10px;

      &:hover {
        background-color: $lightGrey;
      }

      &:active {
        background-color: $lightGrey1;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }

    .accordion-title.active {
      background-color: $lightGrey1;
    }
  }

  .folder-files {
    margin-left: 2rem;
    // margin-top: 1rem;
  }
}


.deleted-list {
  li {
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    padding-right: 20px;

    .custom-checkbox {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.disk-popup {
  &.share-disk {
    .modal-dialog {
      --bs-modal-width: 650px;
    }
  }

  .modal-content {
    border-radius: 1rem;
  }

  .modal-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $inputBorderColor;
  }

  .modal-body {
    padding: 1.5rem;
  }

  .btn {
    font-weight: 400;
    line-height: 1rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  .btn-primary {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }

  .btn-blue {
    background-color: $headingColor;

  }

  .btn-secondary {
    background-color: $primaryGrey;
    color: $black;
    border-color: $primaryGrey;
  }

  .share-access {
    background-color: transparent;
    padding: 0;
    height: auto;
    border: 0;

    :hover,
    :active {
      border: 0;
    }
  }
}

.share-list {
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // gap: 1rem;

  .user-card {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    align-items: center;
    gap: 1rem;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: $lightGrey;
    }

    &:active {
      background-color: $lightGrey1;
    }

    .name {
      flex-grow: 1;
    }

    .drop-down {
      justify-content: flex-end;
    }

    .revoke-btn {
      cursor: pointer;
    }

    .invite-by {
      opacity: 0.5;
      font-style: italic;
      margin-top: -0.75rem;
      font-size: $fs-12;
    }
  }
}