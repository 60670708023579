.blogCont {
  margin-top: 51px;
  background: #ffffff;

  .blogRow {
    display: flex;
    border: 1px solid #e4e6eb;
    border-radius: 12px;
    gap: 20px;
    flex-wrap: unset;
  }
  .editorCont {
    padding: 0;
  }
  .blogContent {
    flex: unset;
    padding-bottom: 23px;
  }
  .backgroundCont {
    min-height: 183px;
    background-color: #e4e6eb;
    margin-top: 22px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    .uploadedImage {
      width: 100%;
      object-fit: contain;
    }
    .hoverDelete {
      display: none;
      width: 50px;
      cursor: pointer;
      height: 50px;
      line-height: 2.5;
      border-radius: 50%;
      background: #ffff;
      position: absolute;
      left: 50%;
      bottom: 20px;
    }
    &:hover {
      .hoverDelete {
        display: block;
      }
    }
    .cameraPlaceholder {
      padding: 32px 22px 22px;
      position: relative;
      .closeIconCont {
        position: absolute;
        top: 10px;
        right: 10px;
        display: none;
        cursor: pointer;
      }
      &:hover {
        .closeIconCont {
          display: block;
        }
      }
    }
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      opacity: 0;
    }
    h6 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #3d5a80;
      margin: 0;
      padding: 10px 0;
    }
    p {
      margin: 0 auto;
      padding: 0;
      width: 360px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      text-align: center;
      color: #3d5a80;
    }
  }
  .editorCont {
    :global(.rdw-editor-main) {
      min-height: 420px;
    }
  }
  .tbBlog {
    padding-left: 25px;
    padding-top: 15px;
    min-height: 75vh;
    border-bottom: none;
    margin-bottom: 30px;
    position: relative;
    :global(.nav-item) {
      a {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #3d5a80;
      }

      :global(.nav-link.active) {
        background-color: #e4e6eb;
      }
    }
    h2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: #3d5a80;
    }
  }
  h6 {
    font-size: 20px;
    padding: 25px 0;
    margin-bottom: 0;
  }
  .btnGroups {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 9px 18px;
      gap: 10px;
      border: none;
      padding: 9px 18px;
      background: #e4e6eb;
      border-radius: 5px;
      color: #3d5a80;
      width: 131px;
      height: 36px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
      &:nth-child(2) {
        margin: 0 20px;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 9px 18px;
        gap: 10px;
        width: 89px;
        height: 36px;
        background: #3d5a80;
        border-radius: 5px;
        color: #ffffff;
      }
    }
  }
  .addCoverPhoto {
    display: flex;
    margin-top: 25px;
    justify-content: flex-start;
    background-color: #ebebeb;
    padding: 10px 20px;
    align-items: center;
    max-width: 200px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    svg {
      margin-right: 10px;
      width: 16px;
    }
  }
  .postHeadLine {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5b5b5b;
    margin: 20px 0;
    border: none;
  }
  .draftHead {
    font-weight: 400;
    padding-top: 18px;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
  }
  :global(.editor-inline) {
    border: none;
    a {
      color: #000;
      text-decoration: none;
    }
  }
  :global(.rdw-editor-toolbar) {
    > div {
      padding: 0px 10px;
      border-right: 1px solid #ccc;
    }
  }
  :global(.ql-toolbar.ql-snow + .ql-container.ql-snow) {
    height: 500px;
    p {
      font-size: 1.5em;
    }
  }
  :global(.ql-formats) {
    position: relative;
    border-right: 1px solid #ccc;
    padding-right: 15px;
  }
  :global(.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label) {
    border: none;
  }
  :global(.ql-header.ql-picker) {
    width: 67px;
    &:first-child {
      &::after {
        content: '';
        margin: 0 0.5em;
        display: inline-block;
        border: 6px solid transparent;
        border-top: 7px solid gray;
        border-bottom: 0 none;
        position: absolute;
        right: -16px;
        top: 10px;
      }
    }
  }
  :global(.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)) {
    svg {
      display: none;
    }
  }
  :global(.ql-editor) {
    height: 500px;
    p {
      font-size: 1.5em;
    }
  }
  :global(.ytp-cued-thumbnail-overlay) {
    margin: 0 10px;
  }
}
.draftCont {
  margin-top: 18px;
  h6 {
    padding-bottom: 10px;
    color: #3d5a80;
  }
  .subtext {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #000000;
  }
  .actionIcons {
    display: flex;
    align-items: center;
    margin-top: 8px;

    span {
      cursor: pointer;
      color: #3d5a80;
    }
  }
  .draftItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin: 16px;
    min-width: 280px;
    overflow: none;
    background: #ffffff;
    border: 1px solid #e4e6eb;
    border-radius: 12px;
    h6 {
      padding-top: 10px;
    }
  }
}
.draftImage {
  width: 100%;
  min-width: 172px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  img {
    width: 100%;
    object-fit: cover;
  }
}
.draftDescr {
  padding: 10px;
  flex-grow: 1;
  width: 100%;

  h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #3d5a80;
  }
}
.descDraftText {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b5b;
  img,
  iframe {
    display: none;
  }
}
.readMore {
  border: none;
  color: #3d5a80;
  background: none;
  display: block;
  font-size: 13px;
}
.blogDetailContainer {
  padding-top: 54px;
  h3 {
    color: #3d5a80;
    font-size: 30px;
  }
  .subtext {
    display: inline-block;
    padding-top: 20px;
  }
  .detailsFeaturedImage {
    > img {
      margin-top: 20px;
      width: 100%;
      height: 450px;
    }
  }
}

.text-editor {
  box-sizing: border-box;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 8px;
  height: 100%;
}

.deleteButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 0px;
  gap: 10px;
  width: 162px;
  height: 48px;
  background: transparent;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #e13e3e !important;
}

.edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 10px;
  gap: 10px;
  width: 68px;
  height: 38px;
  background: #e4e6eb;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #5b5b5b !important;
}

.subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #747474;
}
.blogDetailsMainBody {
  img {
    max-width: 100%;
  }
}
