@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

p {
  margin: 0;
}


.sub-container-first {
  margin-top: 20px;
  width: 20vw;
}

.space-bottom {
  margin-bottom: 20px;
}