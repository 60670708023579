
.timeline {
    display: flex;
    flex-direction: column;
  
    &__event {
      background: #fff;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      margin: 20px 0;
      border-radius: 8px;
      min-height: 12vh;
    height: 16vh;
  
      &__title {
        font-family: 'Inter';
        font-size: 16px;
        line-height: 19.2px;
        text-transform: uppercase;
        font-weight: 400;
        color: #000000;
        
      }
      &__content {
        padding: 0px;
      }
      &__date {
        color: #f6a4ec;
        font-size: 1.5rem;
        font-weight: 600;
        white-space: nowrap;
      }
      &__icon {
        border-radius: 8px 0 0 8px;
        background: #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 40%;
        font-size: 2rem;
        color: #D9D9D9;
        padding: 20px;
  
        i {
          position: absolute;
          top: 50%;
          left:-65px;
          font-size: 2.5rem;
        transform: translateY(-50%);
          
        }
      }
      &__description {
        flex-basis: 60%;
      }
  
      &:after {
        content: "";
        width: 3px;
        height: 100%;
        background: #D9D9D9;
        position: absolute;
        top: 21%;
        // transform: translateY(-50%);
        left: -3.1rem;
        z-index: 0;
        
      
      }
  
      &:before {
        content: "";
        width: 1rem;
        height: 1rem;
        position: absolute;
        background: #D9D9D9;
        border-radius: 100%;
        left: -3.5rem;
        // right: 90%;
        top: 8%;
        transform: translateY(-50%);
        border: 2px solid #D9D9D9;
      }
      
  
      
        &:last-child{
  
          &:after{
            content: none;
          }
      }
      
    }
  }
  
  @media (max-width: 786px) {
    .timeline__event {
      flex-direction: column;
    }
    .timeline__event__icon {
      border-radius: 4px 4px 0 0;
    }
  }
  