@import "../../../scss/utils/variables";

.dropdown-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow-y: auto;
    min-width: 200px;
    max-height: 500px;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: white;
    border: 1px solid #e1e1e1;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);

    .dmc-heading {
        font-size: $fs-14;
        font-weight: 500;
        color: $primaryColor;
        padding: 0.5rem 1rem;
    }

    .dmc-sub-heading {
        font-size: $fs-12;
        opacity: .5;
        padding: 0rem 1rem 0.5rem 1rem;
    }

    .ddm-item {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 14px;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: rgba(238, 239, 244, 0.566)
        }

        &:active {
            background-color: rgba(238, 239, 244, 1)
        }
    }
}