.kebab-action {
  position: relative;

  &:hover {
    .kebab-action--menu {
      display: block;
    }
  }

  button {
    width: 1.5rem;
    background-color: transparent;
    padding: 0px;
  }

  &--menu {
    width: 12.0625rem;
    padding: 0.9375rem;
    top: 2.125rem;
    display: none;
    right: 0;
    position: absolute;
    z-index: 999;
    ul {
      list-style: none;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        font-weight: 400;
        cursor: pointer;
        font-size: 0.8125rem;
        line-height: 1;
        color: $black;

        & + li {
          margin-top: 0.75rem;
        }
      }
    }
  }

  svg {
    display: block;
  }
}
