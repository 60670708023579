@import '../../scss/utils/variables';
.chats__main__wrapper {
  position: fixed;
  bottom: 0;
  right: 65px;
  display: flex;
  max-width: 75vw;
  max-height: 450px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  z-index: 9999;
}

.chats__main,
.meeting__main {
  height: 450px;
  overflow: hidden;
  width: 330px;
  position: relative;
  border-radius: 12px 12px 0 0;
  border: 1px solid #ddd;
  margin-left: 15px;
  &.minimized {
    bottom: -399px;
    // .main__chat{
    //   height: 50px;
    //   overflow: hidden;
    // }
  }
}

.meeting__main {
  width: 750px;
}
.meeting__main:has(.meeting-wrapper) {
  border: none;
    height: 0px;
}

.chat__composer .giphy-picker{
  width: 300px !important;
  // margin-top: -80px;
  position: absolute;
  bottom: 100%;
  left: 10px;
  right: 10px;
  top: auto;
}
.chat__composer .emoji__keyboard{
  width: 300px !important;
  position: absolute !important;
    bottom: 100% !important;
}
.chat__composer .giphy-picker .loader{display: none !important;}
// .message__txt a{color: #fff !important;}
.attendies-list{max-width: 300px; text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
.meetingChatGroup .main__chat{
  overflow: hidden;
}
.draggable-content.meetingChatGroup{
  height: 450px;
}
.meetingChatGroup .chat__secondary-view .chat__header{}