.comment-box {
  column-gap: 10px;
  align-items: flex-start;
  display: flex;
  padding: 1rem;

  .content {
    flex-grow: 1;
    position: relative;

    form {
      position: relative;

      input[type='text'] {
        display: block;
        padding: 0 110px 0 12px;
        border: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        height: 40px;
        width: 100%;
        color: $black;
        background: #f3f3f3;
        border-radius: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;

        &.add-more-space {
          padding-right: 45px;
        }

        &:focus {
          outline: none;
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      // z-index: 9;
      right: 4px;
      gap: 5px;
      top: 3px;

      li {
        width: auto;
        position: relative;
        // background-color: red;

        &>div {
          // position: absolute;
          // right: 0;
          // top: 40px;
          z-index: 10;

          @media screen and (max-width: 767px) {
            // right: -16px;
          }
        }

        button {
          background-color: transparent;
          padding: 0;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 34px;
          width: 34px;
          transition: all 0.2s;

          label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          input {
            display: none;
          }

          &:hover {
            background-color: #d1d1d1;
          }
        }
      }
    }
  }

  .icon {
    width: 40px;
    flex-shrink: 0;
    background: $lightGrey1;
    height: 40px;
    border-radius: 50%;

    img {
      width: 100%;
      border-radius: 50%;
      height: 100%;
      margin-top: 0 !important;
    }
  }
}

.comments {
  &--row {
    position: relative;

    .content {
      flex-grow: 1;
    }

    ul {
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 24px 20px;
      display: flex;
      gap: 10px;
      position: relative;
      justify-content: center;

      @media screen and (min-width: 768px) {
        gap: 20px;
      }

      // &:after {
      //   content: '';
      //   width: calc(100% - 40px);
      //   left: 20px;
      //   bottom: 0;
      //   position: absolute;
      //   height: 1px;
      //   background-color: $lightGrey1;
      // }
      li {
        @extend %vh-center;
        // width: calc((100% - 60px) / 4);
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 768px) {
          width: 90px;
        }

        button {
          font-weight: 400;
          font-size: 15px;
          line-height: 30px;
          padding: 0;
          border: none;
          // width: 100%;
          background-color: transparent;
          border-radius: 6px;
          color: $black;
          transition: all ease-in-out 0.3s;

          // &:hover {
          //   background-color: $lightGrey;
          // }
        }
      }
    }

    .wrap {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: '';
      width: 4px;
      height: calc(100% - 40px);
      position: absolute;
      left: 19px;
      background: $lightGrey1;
      top: 50px;
    }
  }
}

.giphy-picker {
  --shadow-color: 0deg 0% 0%;
  --shadow: 0.3px 0.5px 2.7px hsl(var(--shadow-color) / 0.14), 0.4px 0.8px 1px -3.2px hsl(var(--shadow-color) / 0.14),
    1px 2px 2.5px -4.5px hsl(var(--shadow-color) / 0.14);
  box-shadow: var(--shadow);
  background-color: #fff;
  width: 336px;
  border-radius: 10px;

  .loader {
    position: absolute;
    display: none;
  }

  @media screen and (max-width: 767px) {
    right: -50px !important;
  }

  &--header {
    padding: 10px;

    svg {
      z-index: 1;
      fill: rgba(34, 36, 39, 0.7);
      position: absolute;
      top: 50%;
      left: 10.5px;
      transform: translateY(-50%);

      &.clear-icon {
        left: auto;
        right: 10.5px;
        cursor: pointer;
      }
    }

    input {
      --duration: 225ms;
      --easing: cubic-bezier(0.4, 0, 0.2, 1);
      width: 100%;
      transition-duration: var(--duration);
      transition-property: background-color, box-shadow;
      transition-timing-function: var(--easing);
      border: 0;
      border-radius: 10px;
      outline: 0;
      padding: 8px 31px;
      display: block;
      font-size: 14px;
      color: rgb(34, 36, 39);
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &--body {
    padding: 10px;
  }
}

.comment-image {
  position: relative;
  width: 150px;

  img {
    max-width: 100%;
    width: 100%;
    display: block;
    border-radius: 20px;
  }

  button {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    transition: all ease-in-out 0.3s;
    border: none;

    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
  }
}