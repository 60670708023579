// PAGES
@import './pages/auth';
@import './pages/auto-complete';
@import './pages/company-profile';
@import './pages/dashboard';
@import './pages/jobtabs-listing';
@import './pages/profile';
@import './pages/settings';
@import './pages/invitations';
@import './pages/mutualconnections';
@import './pages/onboarding';
@import './pages/teams';
@import './pages/calendar';
@import './pages/create-company';
@import './pages/create-company-job';
@import './pages/create-pagenext';
@import './pages/jobs';

// COMPONENTS
@import './components/avatar';
@import './components/accordion';
@import './components/confirmAlert';
@import './components/card';
@import './components/candidate-jobs';
@import './components/checkbox';
@import './components/connection-chaining';
@import './components/job-card';
@import './components/list';
@import './components/loader';
@import './components/modal';
@import './components/navbar';
@import './components/not-found';
@import './components/radio-button';
@import './components/status';
@import './components/tabs';
@import './components/toggle-switch';
@import './components/react-calendar';
@import './components/circle';
@import './components/kebab-action';
@import './components/sidebar';
@import './components/post';
@import './components/create-post';
@import './components/comments';
@import './components/add-contact';
@import './components/readmore';
@import './components/autocomplete';
@import './components/loading';
@import './components/meeting';
@import './components/single-filter';
@import './components/mydisk';
@import './components/dropzone';
@import './components/subheader-search';
@import './components//quantityInput';

// UTILITIES
@import './utils/basics';
@import './utils/button';
@import './utils/input';
@import './utils/dropdown';
@import './utils/placeholders';
@import './utils/utilities';
@import './utils/variables';
@import './utils/seprator';
@import './utils/scrollbar';
@import './utils/layout';
@import 'microtip/microtip';
html,
body {
  background-color: #fff;
  // background-color: rgba(247, 247, 247, 0.97);
}

.tooltip {
  z-index: 10000000 !important;
}


body.react-confirm-alert-body-element {
  overflow: visible !important;
}