@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');

p {
  margin: 0;
}

.outside-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100vw;
  height: 19.72vh;
  padding: 4.55vh 6.015vw;
  border: 1px solid rgba(228, 230, 235, 1);
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.inside-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
  box-sizing: border-box;
}

.arrow-back {
  width: 16px;
  height: 100%;
}

.back {
  color: #3d5a80;
  font-size: 20px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  border: none;
  background-color: #fff;
}

.textBox {
  color: rgba(61, 90, 128, 1);
  font-size: 18px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.camera-image-logo {
  align-self: center;
  align-items: center;
}

.starlabel label:after {
  content: ' *';
  color: red;
}

.verify-checkbox {
  margin-top: 3rem;
  padding: 0;
}
.terms-text {
  color: #868585;
  font-size: 0.875rem;
  font-weight: 400;
}
.submit {
  width: 249px;
  height: 48px;
  margin-left: 18px;
}
.form-control.jobFields, .form-select.jobFields{
  background: #ffffff;
}
.job-select{
  &__control{
    min-height: 44px !important;
  }
  &__indicators{
    display: none !important;
  }
}
.job-fields{
  .with-label{color:#5B5B5B}
}
.jobPills{
  line-height: 25px;
  padding: 8px 12px !important;
  background: #3D5A80 !important;
  border-color: #3D5A80 !important;
  p{color: $white !important;}
}