@import '/src/scss/utils/variables';
.meeting__name {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $primaryColor;
}

.chat__options {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
}

.options_list {
  background-color: rgb(255, 255, 255);
  z-index: 1;
  width: 100%;
  flex: 1 1 0px;
  order: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &--inner{
    max-width: 280px;
  }
}

.margin-top-5 {
  margin-top: 15px;
}


.input-bg-gray {
  background: #E4E6EB;
}