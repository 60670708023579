.accordion {
    padding: 1.5rem;
    border: 1px solid $lightGrey1;
    border-radius: $rounded-8;
    -webkit-border-radius: $rounded-8;
    -moz-border-radius: $rounded-8;
    -ms-border-radius: $rounded-8;
    -o-border-radius: $rounded-8;
}

.accordion-header {
    margin-bottom: 0;
    outline: none;

    button {
        &::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC40MDA0MyAxLjEzMTE5QzkuMjAwNDUgMC4wNjUyNzcxIDEwLjc5OTYgMC4wNjUyNzc4IDExLjU5OTYgMS4xMzExOUwxOC44NTYxIDEwLjc5OTRDMTkuODQ1NiAxMi4xMTc4IDE4LjkwNSAxNCAxNy4yNTY2IDE0SDIuNzQzNDVDMS4wOTUwMyAxNCAwLjE1NDM1NCAxMi4xMTc4IDEuMTQzODcgMTAuNzk5NEw4LjQwMDQzIDEuMTMxMTlaIiBmaWxsPSIjNUI1QjVCIi8+PC9zdmc+") !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
}