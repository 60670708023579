@import './variables';

.form-control {
  padding: 14px 20px;
  font-size: 0.9375rem;
  border: 1px solid $inputBorderColor;
  border-radius: 0.3125rem;
  background: $inputBGColor;
  color: $black;
  border-radius: 0.5rem;
  height: 44px;

  &::placeholder {
    color: $black;
  }

  &:focus {
    color: $black;
    background-color: $checkboxBg;
    border-color: $primaryColor;
    box-shadow: 0 0 0 0.0625rem $primaryColor;
  }

  &.error,
  &.error:focus {
    border-color: $error;
    box-shadow: 0 0 0 0.0625rem $error;
  }
}

.form-group {
  margin-bottom: 0.5rem;
  width: 100%;
  align-items: center;
  position: relative;
  .error {
    .custom-select__control,
    .custom-select__control:focus {
      border-color: $error;
      box-shadow: 0 0 0 0.0625rem $error;
    }
  }
  .input-group-icon {
    position: absolute;
    z-index: $zindex-dropdown;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: .5rem;
  }

  .start-1 {
    left: 10px;
    top: 10px;
  }

  .start-1 + .form-control {
    padding-left: 40px;
  }

  .end-1 {
    right: 1rem;
  }
  .end-1 + .form-control {
    padding-right: 50px;
  }
}
