@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

p {
  margin: 0;
}

.total_connections {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 18px */

  /* text color */

  color: #5b5b5b;
}

.recent_connections {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  /* identical to box height, or 18px */

  /* text color */

  color: #5b5b5b;
}

.edit_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  gap: 10px;
  color: #ffffff;
  /* Primery color */
  background: #3d5a80;
  border-radius: 5px;
}
.edit_page:hover {
  background: #003049;
  color: #ffffff;
}
.access {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */
}
/* new color */

.access p {
  cursor: pointer;
}
.team_color {
  color: #3d5a80;
}

.bilinginfo {
  color: #5b5b5b;
}

.paymethod {
  color: #3d5a80;
}

.sub-container-home {
  margin-top: 20px !important;
  width: 60.46vw;
}

.sub-container-first {
  margin-top: 5px;
  width: 23vw;
}

.sub-container-analytics {
  margin-top: 0;
  width: 23vw;
}

.sub-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 23vw;
  box-sizing: border-box;
  margin-right: 28px;
}

.companyprofile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 8px 14px;
  border: 1px solid rgba(228, 230, 235, 1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}
.companyprofile .text-container {
  justify-content: space-between;
}
.borderless-btn {
  padding: 10px !important;
  border-radius: 5px;
}
.borderless-btn:hover {
  background-color: #e4e6eb;
  padding: 10px;
}
.companyprofile2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.company-profile {
  color: rgba(91, 91, 91, 1);
  font-size: 18px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.sub-text-container {
  margin-bottom: 2px;
  margin-right: 6px;
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.visible-icon {
  margin-left: 5px;
  gap: 10px;
  width: 41px;
  height: fit-content;
}

.edit-public-profile-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 12px;
  gap: 10px;
  width: 100%;
  border-top-width: 1px;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-color: rgba(228, 230, 235, 1);
  border-style: solid;
  box-sizing: border-box;
}

.edit-public-profile-url {
  color: #3d5a80;
  font-size: 14px;
  line-height: 129%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.frame-49527 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 8px 14px;
  border: 1px solid rgba(228, 230, 235, 1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.frame-495501 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.grow-your-page {
  color: rgba(91, 91, 91, 1);
  font-size: 18px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.frame-487111 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.adding-admins-helps-you {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49551 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 36px;
  padding: 7px 33px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #3d5a80;
  cursor: pointer;
}

.add-page-admins {
  margin: 14px 6px;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.frame-495041 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 12px;
  gap: 10px;
  width: 100%;
  border-top-width: 1px;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-color: rgba(228, 230, 235, 1);
  border-style: solid;
  box-sizing: border-box;
}

.invite-connections {
  color: #3d5a80;
  font-size: 14px;
  line-height: 129%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.intro-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 8px 14px;
  border: 1px solid rgba(228, 230, 235, 1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.frame-495502 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.introduction {
  color: rgba(91, 91, 91, 1);
  flex-direction: row;
  font-size: 18px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.edit-intro {
  color: rgba(225, 140, 62, 1);
  margin-left: 6.53vw;
  font-size: 14px;
  line-height: 129%;
  font-family: Inter, sans-serif;
  font-weight: 400 !important;
  cursor: pointer;
}

.company-description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.frame-49486 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.company-description {
  color: rgba(134, 133, 133, 1);
  max-height: 96px;
  width: 100%;
  font-size: 15px;
  line-height: 140%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding-bottom: 6.5px;
  border-top-width: 0;
  border-bottom: 1px;
  border-right: 0;
  border-left: 0;
  border-color: rgba(228, 230, 235, 1);
  border-style: solid;
  box-sizing: border-box;
}

.intro-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 8px 14px;
  border: 1px solid rgba(228, 230, 235, 1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.frame-49280 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
  box-sizing: border-box;
}

.frame {
  width: 20px;
  height: 26px;
}

.frame-49275 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  height: 100%;
  box-sizing: border-box;
}

.page {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.business-consultant {
  color: rgba(134, 133, 133, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49281 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

.frame-16 {
  width: 21px;
  height: 26px;
}

.frame-49276 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  width: 311px;
  height: 100%;
  box-sizing: border-box;
}

.address {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

._-101-north-tryon-st-6th-f {
  color: rgba(134, 133, 133, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49282 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
  box-sizing: border-box;
}

.frame-19 {
  width: 20px;
  height: 20px;
}

.frame-49277 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  height: 100%;
  box-sizing: border-box;
}

.phone-number {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

._-123456789 {
  color: rgba(225, 140, 62, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49283 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
  box-sizing: border-box;
}

.frame-22 {
  width: 20px;
  height: 14px;
}

.frame-49278 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  height: 100%;
  box-sizing: border-box;
}

.email {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.databridge-gmail-com {
  color: rgba(225, 140, 62, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49284 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
  box-sizing: border-box;
}

.frame-25 {
  width: 20px;
  height: 20px;
}

.frame-49279 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  height: 100%;
  box-sizing: border-box;
}

.website {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.databridgeconsultant {
  color: rgba(225, 140, 62, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49288 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: fit-content;
  box-sizing: border-box;
}

.frame-28 {
  width: 22px;
  height: 100%;
}

.rating-437-reviews {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-487112 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  padding: 0px 38px 0px;
  box-sizing: border-box;
}

.allow-reviews {
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-2221 {
  margin-left: 10px !important;
  padding-left: 10px !important;
  align-items: flex-end;
  gap: 10px;
  width: 41px;
  height: fit-content;
}

.frame-495042 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding-top: 12px;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-color: rgba(228, 230, 235, 1);
  border-style: solid;
  box-sizing: border-box;
}

.open-now {
  color: rgba(225, 140, 62, 1);
  font-size: 14px;
  line-height: 129%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.select-hours {
  margin-left: 7.9vw;
  color: rgba(225, 140, 62, 1);
  font-size: 14px;
  line-height: 129%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49524 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 8px 14px;
  border: 1px solid rgba(228, 230, 235, 1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.frame-495503 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.analytics {
  color: rgba(91, 91, 91, 1);
  font-size: 18px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.frame-49302 {
  display: flex;
  flex-direction: column;
  margin-top: -7px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

.frame-49292 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.frame-49290 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 360px;
  height: 100%;
  border-top: 0px;
}

.last-7-days {
  position: absolute;
  top: 48px;
  left: 0px;
  color: rgba(91, 91, 91, 1);
  font-size: 13px;
  line-height: 192%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

._-37k {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.search-appearances {
  color: rgba(91, 91, 91, 1);
  margin-top: -15px;
  padding-top: -10px;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49291 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  box-sizing: border-box;
}

.margin-symbols {
  margin-top: 2px;
  margin-right: 8px;
}

._-111 {
  color: rgba(255, 0, 0, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49295 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
  border-bottom: 1px;
  border-top: 1px;
  border-right: 0;
  border-left: 0;
  border-color: rgba(228, 230, 235, 1);
  border-style: solid;
  box-sizing: border-box;
}

.frame-49293 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 293.84px;
  height: 100%;
  box-sizing: border-box;
}

._-355 {
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.unique-visitors {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49294 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
  height: fit-content;
  box-sizing: border-box;
}

._-61 {
  color: rgba(255, 0, 0, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.increase-text {
  color: #037f29;
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.polygon-7 {
  width: 12.12px;
  height: 8.26px;
}

.frame-49298 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px;
  border-top: 0px;
  border-right: 0;
  border-left: 0;
  border-color: rgba(228, 230, 235, 1);
  border-style: solid;
  box-sizing: border-box;
}

.frame-49296 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 282.84px;
  height: 100%;
  box-sizing: border-box;
}

._-96 {
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin-bottom: 3px;
}

.new-followers {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin-top: -5px;
  margin-bottom: -5px;
}

.invite-connections-to-f {
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49297 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
  height: fit-content;
  box-sizing: border-box;
}

._-435 {
  color: rgba(255, 0, 0, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.polygon-8 {
  width: 12.12px;
  height: 8.26px;
}

.frame-49301 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom-width: 1px;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  box-sizing: border-box;
}

.frame-49299 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 282.84px;
  height: 100%;
  box-sizing: border-box;
}

.start-apost {
  position: absolute;
  top: 40px;
  left: 0px;
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

._-37 {
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.post-impressions {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.frame-49300 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
  height: fit-content;
  box-sizing: border-box;
}

._-4351 {
  color: rgba(255, 0, 0, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.polygon-9 {
  width: 12.12px;
  height: 8.26px;
}

.frame-49305 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.frame-49303 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 282.6px;
  height: 100%;
  box-sizing: border-box;
}

._-41 {
  color: rgba(61, 90, 128, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.custom-button-clicks {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding-top: -5px;
}

.margin-box {
  margin-top: -11px;
}

.frame-49300 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2px;
  width: 100%;
}

.frame-49304 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
  height: fit-content;
  box-sizing: border-box;
}

._-4352 {
  color: rgba(3, 127, 41, 1);
  font-size: 15px;
  line-height: 167%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.polygon-10 {
  width: 12.12px;
  height: 8.39px;
}

.custom-control-input {
  margin-left: 30px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: red !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 47, 69, 0.25) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: red !important;
  background-color: red !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: red !important;
  border-color: red !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: red !important;
}

.custom-control-input-green:not(:disabled):active ~ .custom-control-label::before {
  background-color: red !important;
  border-color: red !important;
}

#mySwitch {
  background-image: url('../../../assets/new/companyprofilevisible.svg');
  background-color: #28a745;
}

#mySwitch:checked {
  background-color: red;
}

.modal-body {
  padding: 10px;
}

.no-posts-image-container {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.no-posts-image-container .no-posts-image {
  height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}
.no-posts-image-container p {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.no-posts-image-container button p {
  color: #3d5a80;
}
