.post-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  background: #ffffff;
  width: '100%';
  position: relative;
}

.mutual-actions {
  color: #999;

  svg {
    opacity: 0.5;
  }
}