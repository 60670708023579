@import '../../scss/utils/variables';
.image-uploader {
  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-radius: 8px;
  }

  &__p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $darkGrey2;
  }

  &__h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: $primaryColor;
    margin: 0;
  }

  &__h3 {
    .modal-body & {
      font-size: 24px;
      line-height: 120%;
      color: $darkGrey2;
      margin: 0;
      &--span {
        text-decoration-line: underline;
        font-weight: 600;
      }
    }
  }

  &__canvas {
    width: 100%;
    height: 16rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  &__input {
    display: none;
  }

  &__label {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    gap: 8px;
  }

  &__drag-active {
    background-color: #b5b5b5;
  }

  &__camera-btn {
    background: $secondaryBtnBg;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: $primaryColor;
  }
}

.image__editor__wrapper {
  padding-top: 1rem;
}

.image__editor__container {
  display: flex;
  flex-direction: column;
}

.editor__features {
  display: flex;
  gap: 15px;
}

.editor__feature {
  height: 35px;
  padding-bottom: 5px;
}

.editor__feature__active {
  border-bottom: solid 1px #e18c3e;
}

.image__editor__crop {
  display: flex;
  justify-content: center;
  background: #f5f5f5;
}

.image__editor__controls {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rotate__features {
  display: flex;
  justify-content: end;
  gap: 5px;
}

.img__features {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.img__form__range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  accent-color: #3d5a80;
}

.img__features__text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #5b5b5b;
}

.img__features__text__active {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #3d5a80;
}

.img__features__wrapper {
  flex: 1;
}

.img__features__set {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
