.suggestions {
    border: 1px solid $inputBorderColor;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    border-radius: $rounded-5;
    -webkit-border-radius: $rounded-5;
    -moz-border-radius: $rounded-5;
    -ms-border-radius: $rounded-5;
    -o-border-radius: $rounded-5;

    

    li {
        padding: 0.5rem 1rem;

        &:hover {
            background-color: $skyBlue;
            cursor: pointer;
        }
    }
}