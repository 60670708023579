@import '../utils/variables';

.primary-card {
  background: $white;
  border: 0.0625rem solid $inputBorderColor;
  // border: 1px solid $inputBorderColor;
  // box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.card-bottom {
  padding: 10px 20px;
  border-top: 1px solid $lightGrey1;
}
