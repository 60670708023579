.chats__main__wrapper {
  position: fixed;
  bottom: 0;
  right: 65px;
  display: flex;
  max-width: 75vw;
  max-height: 450px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  z-index: 9999;
}

.chats__main,
.meeting__main {
  height: 450px;
  overflow: hidden;
  width: 350px;
  position: relative;
  border-radius: 12px 12px 0 0;
  border: 1px solid #ddd;
  margin-left: 15px;
  background: #fff;
  &.minimized {
    bottom: -399px;
    // .main__chat{
    //   height: 50px;
    //   overflow: hidden;
    // }
  }
}

.meeting__main {
  width: 750px;
}
.meeting__main:has(.meeting-wrapper) {
  border: none;
    height: 0px;
}

.chat__composer .giphy-picker{
  width: 300px !important;
  // margin-top: -80px;
  position: absolute;
  bottom: 100%;
  left: 10px;
  right: 10px;
  top: auto;
}
.chat__composer .emoji__keyboard{
  width: 300px !important;
  position: absolute !important;
    bottom: 100% !important;
}
.chat__composer .giphy-picker .loader{display: none !important;}
// .message__txt a{color: #fff !important;} 
.parent__message .sender__message__container, .parent__message .receiver__message__container{
  max-width: 100%;
  max-height: 100%;
}
.parent__message .sender__message__container .message__wrapper, .parent__message .receiver__message__container .message__wrapper, .parent__message .receiver__message__container .message__wrapper .message__details {
//  max-height: 150px !important;
}
.parent__message .receiver__message__container .message__wrapper .message__details .message__image__container{
  max-height: calc(150px - 25px) !important;
}
.parent__message .sender__message__container .message__wrapper .message__img__wrapper, .parent__message .receiver__message__container .message__wrapper .message__details .message__image__container .message__image__wrapper{
  max-height: calc(150px - 25px) !important;
}

.parent__message .sender__message__container .message__wrapper .message__img__wrapper img, .parent__message .receiver__message__container .message__wrapper .message__details .message__image__container .message__image__wrapper img{
  max-height: calc(150px - 25px) !important;
  width: auto !important;
}
.thread__chat .chat__list{
  height: 150px !important;
  min-height: 150px !important;
    overflow-y: auto;
}
// .chats__main__wrapper:has(.minimized){
//   height: 50px;
// }
.chat-stacked-container{position: fixed; right: 20px; bottom: 120px;}