@import '../utils/variables';

body {
  .react-calendar button {
    font-size: 13px;
  }
  .react-calendar {
    padding: 20px 15px;
  }

  .react-calendar__navigation {
    height: auto;
  }

  .react-calendar__navigation button {
    min-width: 24px;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 21px 0;
  }

  .react-calendar {
    width: 100%;
    border: none;
    font-family: $primaryFont;
    background-color: $white;
    border-radius: 0.75rem;
    overflow: hidden;
    border: 0.0625rem solid $inputBorderColor;
    line-height: normal;

    abbr[title] {
      text-decoration: none;
    }

    .react-calendar__navigation {
      margin-bottom: 0;
      background-color: transparent;

      button {
        font-size: 16px;
        line-height: 120%;
        color: #4a5660;
        font-weight: 500;

        span {
          font-weight: 600;
        }

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }

    .react-calendar__month-view {
      button {
        padding: 9px 8px;
        font-weight: 400;
        font-size: 15px;
        line-height: 120%;
        color: #4a5660;

        &:nth-child(7n) {
          border-right: none;
        }
      }
    }
  }

  .react-calendar__month-view__weekdays {
    margin-top: 12px;
    font-weight: normal;
  }

  .react-calendar__month-view__days__day--weekend {
    color: $error;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--active {
    background: $darkGrey2;
    color: $white !important;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: #037f29;
  }

  .react-calendar__tile--now {
    background-color: #037f29;
    color: $white !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #999 !important;
  }
}
