@import '../utils/variables';

.post-edit-modal {
  .modal-dialog {
    --bs-modal-width: 750px;
  }
}

.modal {
  z-index: 9999;

  &.edit-modal {
    .tabs {
      min-width: 100px;
    }

    .form-group {

      input,
      select {
        font-size: 14px;
        padding-left: 10px;
        line-height: 16px;
      }

      margin-bottom: 0 !important;
    }

    .flex-column {
      margin-bottom: 16px;
    }

    .helper-text {
      font-size: 14px;
      line-height: 16px;
      color: #868585;
    }
  }

  &.confirmation-modal {
    &.lg {
      .modal {
        &-dialog {
          --bs-modal-width: 750px;
        }
      }
    }

    &.xs {
      .modal {
        &-dialog {
          --bs-modal-width: 450px;
        }
      }
    }

    .modal {
      &-body {
        padding: 0 1rem 1rem;

        &>h4 {
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          color: $black;
          margin-bottom: 5px;
        }

        .subtext {
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: $black;
          margin-bottom: 15px;
          margin-top: -15px;
        }

        &>ul {
          list-style: none;

          li {
            position: relative;
            padding-left: 20px;

            &::before {
              content: '';
              width: 5px;
              height: 5px;
              position: absolute;
              left: 0;
              top: 10px;
              background-color: $black;
              border-radius: 50%;
            }

            &+li {
              margin-top: 0.5rem;
            }
          }
        }

        &>p {
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: $darkGrey2;

          &+p {
            margin-top: 0.5rem;
          }

          strong {
            font-weight: 600;
          }
        }

        .confirm-dropdown>* {
          margin: 0px !important;
        }
      }

      &-dialog {
        --bs-modal-width: 600px;
      }

      &-content {
        background: $white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        border: none;
      }
    }
  }

  &-header {
    --bs-modal-header-padding: 10px;
    border-bottom: none;

    button {
      background-color: transparent;
      border: none;
      line-height: 1;
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-transform: capitalize;
      color: $headingColor;
      margin: 0;
    }
  }

  &-body {
    padding: 20px;

    .primary-avatar-list {
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: 0.9375rem;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 50px;
    }

    .back-to-page {
      @extend %vh-center;
      font-weight: $fw-500;
      font-size: 1.125rem;
      line-height: 1.875rem;
      color: $black;
      margin-top: 1rem;

      &.secondary {
        button {
          font-weight: $fw-400;
          font-size: 15px;
          line-height: 30px;
          margin-left: 0;
        }
      }

      button {
        font-weight: $fw-500;
        margin-left: 0.3125rem;
        line-height: 1.875rem;
        color: $primaryColor;

        @media screen and (min-width: 1280px) {
          font-weight: $fw-600;
          font-size: 1.5rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h3 {
      font-weight: $fw-500;
      font-size: 18px;
      line-height: 30px;
      color: $black;
      margin-bottom: 26px;
    }
  }

  &-footer {
    border: none;
    padding: 0.5rem;

    // .btn {
    //   margin: 0;
    //   width: 151px;
    //   border-radius: 50px;
    //   height: 65px;

    //   & + .btn {
    //     margin-left: 25px;
    //   }
    // }
  }

  &-dialog {
    --bs-modal-width: 600px;
    // --animate-duration: 1s;
    // --animate-delay: 1s;
    // --animate-repeat: 1;
    // -webkit-animation-name: zoomIn;
    // animation-name: zoomIn;
    // -webkit-animation-duration: 1s;
    // animation-duration: 1s;
    // -webkit-animation-duration: var(--animate-duration);
    // animation-duration: var(--animate-duration);
    // -webkit-animation-fill-mode: both;
    // animation-fill-mode: both;
  }

  &-content {
    --bs-modal-border-color: $formBorderColor;
    --bs-modal-border-radius: 0;
  }

  &-backdrop.show {
    --bs-backdrop-opacity: 0.1;
    z-index: 9999;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
  }

  50% {
    opacity: 1;
  }
}

.modal-more-section {
  padding: 12px 0 0;
  text-align: center;
  border-top: 1px solid #d9d9d9;
  margin-top: 12px;
}