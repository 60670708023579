.job-card {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  min-height: auto !important;
  &.individual{min-height: 370px !important;}
  .job-header {
    display: flex;
    gap: 20px;

    .company-logo {
      margin-bottom: 1rem;
    }

    .company-title-country {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap:5px
    }

    .company-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  .job-body {
    display: flex;
    gap: 1rem;

    .job-payments {
      display: flex;
      gap: 0.3rem;
      flex-wrap: wrap;
flex-grow: initial;
    }
  }

  .job-description {
    max-height: 170px;
    overflow: hidden;
    margin: 10px 0;
    color: #868585;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    padding: 0;
  }
  .job-description .single-line{
    height: 24px;
    overflow: hidden;
    width: calc(100% - 75px);
    display: inline-block;
    vertical-align: middle;
    line-break: anywhere;
    padding-right: 15px;
    position: relative;
  }
  .job-description .single-line::after{
    content: "...";
    position: absolute;
    right: 2px;
    top: 0px;
  }
  .job-description.individual{
    min-height: 130px;
  }
  .job-footer {
    background-color: #e4e6eb;
    padding: 5px;
    display: flex;
    justify-content: space-evenly;
  }

  .action-menu {
    background: $white;
    box-shadow: 0 0 10px $lightGrey1;
    position: absolute;
    top: 3rem;
    right: 1rem;
    z-index: 1;
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    :hover {
      background-color: $lightGrey1;
    }

    .action {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      padding: 7px 18px;

      .icon {
        min-width: 25px;
      }
    }
  }
}
.job-excerpt {line-height: normal;}