@import '/src/scss/utils/variables';
.new-chat-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.main__chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  font-family: sans-serif;
  width: 100%;
}

.main__chat * {
  box-sizing: border-box;
  font-family: sans-serif;
}

.main__chat ::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

.main__chat ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.main__chat ::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
}

.main__chat ::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

.chat__header {
  padding: 16px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.chat__details {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  width: calc(100% - 136px);
}

.chat__user {
  width: calc(100% - 50px);
  padding: 0px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 320px) and (max-width: 480px), (min-width: 481px) and (max-width: 768px) {
  .chat__user {
    width: calc(100% - 80px) !important;
  }
}

.user__name {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat__options {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
}

.chat-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px 0px 0px 16px;
}

.chat-close i {
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-mask: url('./resources/close.svg') center center no-repeat;
  background-color: $primaryColor;
}

.chat__list {
  background-color: rgb(255, 255, 255);
  z-index: 1;
  width: 100%;
  flex: 1 1 0px;
  order: 2;
  position: relative;
}

.list__wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  position: absolute;
  top: 0px;
  transition: background 0.3s ease-out 0.1s;
  width: 100%;
  z-index: 100;
  padding-top: 16px;
}

.chat__composer {
  padding: 16px;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  order: 3;
  position: relative;
  flex: 0 0 auto;
}

.composer__input {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  z-index: 2;
  padding: 0px;
}

.input__inner {
  flex: 1 1 auto;
  position: relative;
  outline: none;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
}

.input__message-input {
  width: 100%;
  align-self: end;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  padding: 6px;
  outline: none;
  overflow: hidden auto;
  position: relative;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  z-index: 1;
  max-height: 100px;
  user-select: text;
  background-color: rgb(228, 230, 235);
  border-radius: 35px;
  border: 0;
}

.input__message-input:empty::before {
  content: attr(placeholder);
  color: rgb(153, 153, 153);
  pointer-events: none;
  display: block;
}

.input__sticky__buttons {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  // margin-left: auto;
  cursor: pointer;
  width: auto;
}

.input__sticky {
  padding: 4px 0px;
  position: absolute;
  width: 100%;
  display: flex;
}

.input__sticky:empty::before {
  pointer-events: none;
}

.input__sticky__attachment {
  display: flex;
  width: auto;
  // min-width: 75px;
}

.attachment__filepicker {
  width: calc(100% - 20px);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
  opacity: 1;
  transition: width 0.5s linear 0s;
}

.filepicker__filelist {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px;
}

.item__video {
  height: 24px;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px 5px 0px 0px;
}

.item__video > i {
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-mask: url('./resources/video.svg') center center no-repeat;
  background-color: $primaryColor;
}

.item__video > input {
  display: none;
}

.item__image {
  height: 24px;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px 5px 0px 0px;
}

.item__image > i {
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-mask: url('./resources/image.svg') center center no-repeat;
  background-color: $primaryColor;
}

.item__image > input {
  display: none;
}

.button__emoji {
  height: 24px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
}

.button__emoji i {
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-mask: url('./resources/emoji.svg') center center no-repeat;
  background-color: $primaryColor;
}
.message__wrapper {
  width: auto;
  align-self: flex-end;
  display: flex;
  flex: 1 1 0%;
}

.message__info__wrapper {
  align-self: flex-end;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  height: 25px;
  padding: 4px 8px;
}

.rbt {
  padding-left: 25px;
}

.rbt .rbt-input-main::-ms-clear {
  display: none;
  width: 326px;
}

/**
 * Menu
 */
.rbt-menu {
  margin-bottom: 2px;
  &.dropdown-menu.show {
    border: 0;
    left: 0 !important;
    width: 328px !important;
    top: 1px !important;
  }
}
.rbt-menu > .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbt-menu > .dropdown-item:focus {
  outline: none;
}
.rbt-menu-pagination-option {
  text-align: center;
}

/**
 * Multi-select Input
 */
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  border: 0 !important;
  background: none !important;
  padding: 10px 20px 14px 20px !important;
}
.rbt-input-multi.focus {
  //border-color: #80bdff;
  //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0;
}
.rbt-input-multi.form-control {
  height: auto;
}
.rbt-input-multi.disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}
.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}
.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

/**
 * Close Button
 */
.rbt-close {
  z-index: 1;
}
.rbt-close-lg {
  font-size: 1.5rem;
}

/**
 * Token
 */
.rbt-token {
  background-color: #0255fe17;
  border: 0;
  border-radius: 0.25rem;
  color: $primaryColor;
  display: inline-flex;
  line-height: 1rem;
  margin: 1px 3px 2px 0;
}
.rbt-token .rbt-token-label {
  padding: 0.25rem 0.5rem;
}
.rbt-token .rbt-token-label:not(:last-child) {
  padding-right: 0.25rem;
}
.rbt-token-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: #495057;
  pointer-events: none;
}
.rbt-token-removeable {
  cursor: pointer;
}
.rbt-token-active {
  background-color: rgba(2, 85, 254, 0.09);
  color: #fff;
  outline: none;
  text-decoration: none;
}
.rbt-token .rbt-token-remove-button {
  background-image: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: none;
  color: inherit;
  display: flex;
  justify-content: center;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 0.25rem 0.5rem;
  padding-left: 0;
  text-shadow: none;
}
.rbt-token .rbt-token-remove-button .rbt-close-content {
  display: block;
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
}
.rbt-aux-lg {
  width: 3rem;
}
.rbt-aux .rbt-close {
  margin-top: -0.25rem;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}

.has-aux .form-control {
  padding-right: 2rem;
}
.has-aux .form-control.is-valid,
.has-aux .form-control.is-invalid {
  background-position: right 2rem center;
  padding-right: 4rem;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
  flex: 1;
}
.input-group > .rbt .rbt-input-hint,
.input-group > .rbt .rbt-aux {
  z-index: 5;
}
.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbt-wrap {
  border-bottom: 1px solid rgb(234, 234, 234);
  display: flex;
  background-color: white;
}

.loading-wrap {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
}
.receiver__message__container .message__actions {
  top: 0px !important;
  left: 90% !important;
  border: none;
  background: transparent;
}
.sender__message__container .message__actions {
  top: 0px !important;
  right: 92% !important;
  border: none;
  background: transparent;
}

.sender__message__container.message__audio .message__actions {
  top: 50px !important;
  left: -40px !important;
  border: none;
  background: transparent;
}
.receiver__message__container.message__audio .message__actions {
  top: 36px !important;
  left: 90% !important;
  border: none;
  background: transparent;
}

.thread__chat .inline__chat__user {
  display: none !important;
}
.team__main__chat__wrapper {
  max-height: 720px !important;
}
.group-admin-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  &--avatar {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    .edit-icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &--name {
    font-size: $fs-18;
    font-weight: $fw-500;
    margin-bottom: 5px;
  }
  &--subtext {
    color: $darkGrey2;
    font-size: 14px;
  }
  &--actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      border: none;
      background: transparent;
      text-align: center;
      font-size: 14px;
      width: 60px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.chatNameModal {
  display: block;
  p {
    font-size: 15px;
    color: #868585;
  }
  .text-area-container {
    position: relative;
    .form-group {
      margin-bottom: 0px !important;
    }
  }
  textarea.form-control {
    height: 64px;
    background-color: #fff;
  }
  span.form-text {
    position: absolute;
    right: 10px;
    bottom: 2px;
  }
  .sub-title {
    font-size: 16px;
    color: #5b5b5b;
    margin: 25px 0 5px;
  }
}
.user-pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  .user-pills {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    background: #e4e6eb;
    color: #3d5a80;
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    align-items: center;
    border-radius: 5px;
  }
}
.suggested-list-container {
  display: block;
  max-height: 200px;
  overflow: auto;
  padding: 5px 0;
  .user-list {
    display: flex;
    gap: 10px;
    padding: 5px 0;
    align-items: center;
    &--name {
      color: #3d5a80;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
.input-container {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
