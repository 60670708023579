%center-with-direction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

%flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%v-center {
  display: flex;
  align-items: center;
}
