.user-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  min-width: 300px;
  max-width: 400px;
  padding: 10px;
}

.dashboard-circle-popup{
  border-radius: 10px!important;
  box-shadow: none!important;
}

.user-info-container {
  display: flex;
  gap: 10px;
}

.icon-status-container {
  position: relative;

  .status {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    bottom: 8px;
    right: 0px;
  }
}

.name-position-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 5px;
  width: 100%;
}

.connection-circle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-actions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  color: #000000;

  .gray-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;

    border: none;
    background: #e4e6eb;
    border-radius: 5px;
    min-height: 36px;
  }

  .orange-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;

    border: none;
    color: #fff;
    background: #3d5a80;
    border-radius: 5px;
    min-height: 36px;
  }
}
