@import '../utils/variables';

.meeting-wrapper {
  display: flex;
  height: calc(100vh - 60px);
  flex-direction: column;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 60px;
  z-index: 9999;
  border: 1px solid $lightGrey1;
  &.minimised {
    width: 330px;
    height: 45px;
    left: auto;
    right: 60px;
    bottom: 0;
    top: auto;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    >.meeting-container{
      height: 0px;
    }
    .meeting-header{
      padding: 1rem;
    }
  }
  &.maximised {
    top: 0px;
    height: 100vh;
    
  }
  &.windowed{
    width: 400px;
    height: 400px;
    left: auto;
    right: 10px;
    bottom: 20px;
    top: auto;
    .meeting-header{
      padding: 1rem;
    }
    .absolute-bottom-left{display: none;}
  }
}
.meeting-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrey1;
  align-items: center;
  padding: 1rem 3rem;
  background: $white;
  .header-right-controls {
    display: flex;
    align-items: center;
    > * {
      margin: 0 5px;
      cursor: pointer;
    }
  }
  .text-primary{
    color: $primaryColor !important;
  }
}
.meeting-wrapper.minimised .meeting-header {
  padding: 10px 15px;
}
.meeting-container {
  height: 100vh;
  display: flex;
  position: relative;
  &.hidden{
    visibility: hidden;
    opacity: 0;
    height: 0px;
  }
}
.video-button-container {
  display: flex;
  // height: 120px;
  width: 65%;
  justify-content: space-around;
  margin: 0 auto;
  padding: 15px 0;
  position: relative;
}
.video-controls {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  cursor: pointer;
  position: relative;
  .dropupMenuButton {
    position: absolute;
    top: 5px;
    margin-right: -28px;
    @media screen and (max-width: 1400px) {
      top: 0px;
    }
  }
  .dropdown-menu {
    padding: 0;
    border: 1px solid #dddddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

    &.settings {
      position: absolute !important;
      bottom: 100% !important;
      left: -50% !important;
      transform: none !important;
      inset: auto auto 100% auto !important;
      height: max-content;
    }
    li {
      margin: 0px !important;
      .dropdown-item {
        border-bottom: 1px solid $lightGrey1;
        padding: 8px;
        justify-content: flex-start;
      }
      &:last-child {
        .dropdown-item {
          border: none;
        }
      }
    }

    li.form-element {
      flex-direction: column;
      width: 500px;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;
    }
    li.form-element:first-child {
      border-bottom: 1px solid $lightGrey1;
    }
    &::after {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #fff;
      content: '';
      bottom: -14px;
      position: absolute;
      left: 50%;
      margin-left: -10px;
    }
    &.reaction-dropdown {
      li {
        width: 420px;
        padding: 8px 5px;
        margin: 0px;
        button {
          background: #f2f2f2;
          display: flex;
          border: none;
          border-radius: 12px;
          padding: 10px 15px;
          width: 100%;
          justify-content: center;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }
}
.control-button {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: $lightGrey1;
  > svg {
    max-height: 26px;
    max-width: 28px;
  }
  @media screen and (max-width: 1400px) {
    width: 60px;
    height: 60px;
    font-size: $fs-14;
    > svg {
      max-height: 22px;
      max-width: 24px;
    }
  }
  .notificationIcon {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &.end-call {
    background: #ff0000;
  }
  &.active {
    background: #ff0000;
  }
}
.bottom-buttons {
  display: none;
}

.buttons-right-section {
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: flex;
  gap:10px;
  .control-button {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
  }
  .dropdown-menu {
    padding: 0;
    border: 1px solid #dddddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    inset: auto 0px 70px 5px !important;
    li {
      margin: 0px !important;
      .dropdown-item {
        border-bottom: 1px solid $lightGrey1;
        padding: 8px;
        
      }
      &:last-child {
        .dropdown-item {
          border: none;
        }
      }
    }
    &::after {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #fff;
      content: '';
      bottom: -14px;
      position: absolute;
      right: 10px;
      margin-left: -10px;
    }
  }
}

.meetingCallTimer {
  padding: 10px 0;
  text-align: center;
  font-size: 20px;
}
.callGlobalController {
  background: #f2f2f2;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  .iconButton {
    background: none;
    border: none;
    padding: 8px 15px;
  }
}
.participantsContainer {
  height: 250px;
  overflow: auto;
}
.participantCard {
  display: flex;
  padding: 10px 0;
  &--inner {
    display: flex;
    flex: auto;
  }
  &--profile {
    width: 40px;
    height: 40px;
    flex: initial;
  }
  &--details {
    h4 {
      font-size: $fs-16;
      font-weight: $fw-500;
      margin: 0;
    }
    p {
      font-size: $fs-16;
      font-weight: $fw-400;
      margin: 0;
      color: #5b5b5b;
    }
    padding-left: 15px;
    flex: 1;
  }
  &--actions {
    > button {
      background: none;
      border: none;
      padding: 10px;
    }
  }
}

.reactionSlider {
  position: absolute;
  left: -80px;
  top: 50%;
  background: #fff;
  padding: 15px;
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

.chatTabsHeader {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.settingsContainer {
  display: flex;
  height: 100%;
  .tabSection {
    width: 25%;
    background: #f5f5f5;
    border-right: 1px solid #d9d9d9;
    .nav-item {
      justify-content: flex-start;
      border-bottom: 1px solid #d9d9d9;
      .nav-link {
        color: $black;
      }
      &.active {
        background: $primaryColor;
        .nav-link {
          color: $white;
        }
      }
    }
    .nav-item + .nav-item {
      margin: 0 !important;
    }
  }
  .tabContent {
    width: 75%;
    .voiceContainer {
      padding: 15px;
      overflow: auto;
      .cameraContainer {
        width: 100%;
        height: 250px;
        position: relative;
        border-radius: 18px;
        overflow: hidden;
      }
    }
  }
}
.sharingWHiteBoard{
  position: absolute; background: #fff;
}

.closeShare{position: absolute; top: 10px; right: 20px; background:#D9D9D9; border:none; width: 20px; height: 20px; border-radius: 50px; text-align: center; line-height: 10px; padding: 10px; box-sizing: content-box;}

// .message__txt__wrapper:has(.emoji){
//   background: transparent;
// }
.passcodeText{
  font-size: 13px;
  padding: 3px 5px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}
.user-display-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 50px 0px;
  flex-direction: column;
  &--bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
    .camera-settings{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
    .buttons-container{
      display: flex;
      gap: 10px ;
    }
  }
}
.control-button.active + .dropupMenuButton > svg{
  filter:invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(125%) contrast(102%);
}