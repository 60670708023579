@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

// variable overrides
$primaryFont: 'Inter', sans-serif;
$primaryFontPoppings: 'Poppins', sans-serif;
$primaryBg: #f5f5f5;
$black: #272727;
$white: #ffffff;
$lightWhite: #f6f7f8;

$primaryGrey: #ededed;
$secondaryGrey: #868585;
$grey: #747474;
$grey1: #b8b8b8;
$lightGrey: #f1f1f1;
$lightGrey2: #fafafa;
$lightGrey1: #d9d9d9;
$darkGrey1: #747474;
$darkGrey2: #5b5b5b;
$darkGrey3: #65676b;
$textLight: #e9eaee;
$radioBorder: #9e9e9e;
$listBorderColor: #e0e0e0;
$darkMainColor: #003049;
$headingColor: #3d5a80;
$ctaBorderGrey: #d0cfcf;

$formBorderColor: #ced0d4;
$primaryColor: #3d5a80;
$primaryOrangeColor: #e18c3e;
$primaryHoverColor: #21354f;
$skyBlue: #ebc4a1;
$checkboxBg: #f8f8f8;
$loaderOverlay: rgba(0, 0, 0, 0.8);
$transition: all ease-in-out 0.3s;
$socialLoginHover: #fee9d6;
$inputBorderColor: #dddddd;
$inputBGColor: #f8f8f8;
$error: #e41c19;
$success: #00a84b;
$inputBg: #d6d6d6;
$inputTextColor: #565656;
$tabBgColor: #e7f3ff;
$secondaryBtnBg: #e4e6eb;
$primaryGreyBg: #e4e6eb;
$pageHeadingColor: #3d5a80;

// FONT WEIGHTS
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;

// Font sizes
$fs-8: 0.5rem;
$fs-9: 0.563rem;
$fs-10: 0.625rem;
$fs-11: 0.688rem;
$fs-12: 0.8rem;
$fs-13: 0.85rem;
$fs-14: 0.9rem;
$fs-15: 0.95rem;
$fs-16: 1rem;
$fs-17: 1.063rem;
$fs-18: 1.125rem;
$fs-19: 1.188rem;
$fs-20: 1.25rem;
$fs-24: 1.5rem;
$fs-48: 48px;

// Border Radius
$rounded-1: 0.06rem;
$rounded-2: 0.13rem;
$rounded-3: 0.19rem;
$rounded-4: 0.3rem;
$rounded-5: 0.35rem;
$rounded-6: 0.4rem;
$rounded-7: 0.45rem;
$rounded-8: 0.5rem;
$rounded-9: 0.55rem;
$rounded-10: 0.6rem;
$rounded-11: 0.7rem;
$rounded-12: 0.8rem;
$rounded-13: 0.85rem;
$rounded-14: 0.9rem;
$rounded-15: 0.95rem;

// Z-index
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-navbar: 1090;
