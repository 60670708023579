@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.create-company-intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 33px;
  width: 42.5%;
  height: 66%;
  padding: 8.734% 3.49% 8.734% 3.49%;
  margin: 8% 28.75% 8% 28.75%;
  border: 1px solid rgba(228, 230, 235, 1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.create-company-services-section {
  max-width: 570px;
  padding: 30px 15px;

  .services-img {
    background: $primaryGrey;
    width: 150px;
    height: 150px;
    border-radius: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    position: relative;

    .upload {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      &.added-img {
        width: 30px;
        left: auto;
        top: auto;
        right: -1.8rem;
        bottom: 0;
        background: $primaryGrey;
        border-radius: 50%;
        height: 30px;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .serviceDetail {
    min-height: 150px;
  }
  .info {
    font-size: 14px;
    line-height: 16px;
    color: #5b5b5b;
  }
  // .action-btn {
  //     .btn-secondary {
  //         height: 40px;
  //         line-height: 1rem;
  //         background: $darkGrey1;
  //         border-color: $darkGrey1;
  //         color: $black;
  //         margin-right: 1rem;
  //     }

  //     .btn-primary {
  //         height: 40px;
  //         line-height: 1rem;
  //     }
  // }
}

.create-amybridge-page {
  color: rgba(61, 90, 128, 1);
  font-size: 32px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.connect-with-clients-em {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(61, 90, 128, 1);
  font-size: 16px;
  line-height: 130%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  text-align: center;
}

.size-large-type-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 45.77%;
  height: 7.28vh;
  padding: 6px 9px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: rgba(225, 140, 62, 1);
}

.button-style {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 87%;
  font-family: Inter, sans-serif;
  font-weight: 600;
  border: none;
  background-color: rgba(225, 140, 62, 1);
}
.message-box-alert {
  background: $secondaryBtnBg;
  padding: 12px 0;
  width: 100%;
  display: block;
  text-align: center;
  color: #5b5b5b;
  font-size: 16px;
  line-height: 25px;
}
