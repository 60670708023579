@import "variables";
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b1b1b1;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b1b1;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b1b1;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.text-btn {
  background-color: transparent;
  border: transparent;
  padding: 0;
}

.sticky-xl-top,
.sticky-top {
  z-index: 9;
}

.helper-text {
  font-size: 0.875rem;
  margin-top: 0.1875rem;
}

.label-text {
  font-size: 0.675rem;
}
.fs-12 {
  font-size: $fs-12;
}
.fs-13 {
  font-size: $fs-13;
}
.fs-14 {
  font-size: $fs-14;
}
.fs-15 {
  font-size: $fs-15;
}
.fs-18 {
  font-size: $fs-18;
}
.fs-20 {
  font-size: $fs-20;
}
.color-primary {
  color: $primaryColor;
}
.color-darkGrey2{
  color: $darkGrey2 !important;
}

.color-red {
  color: $error;
}

.color-success {
  color: $success;
}

.color-info {
  color: rgb(255, 145, 0);
}
.bg-white{
  background: $white !important;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-disable {
  cursor: not-allowed;
}

.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40px !important;
}
.w-80 {
  width: 80% !important;
}
.h-40 {
  width: 40px !important;
}

.h-36 {
  height: 36px;
  // line-height: 0.75rem;
}
.ht-100 {
  height: 100%;
}
.vh-100 {
  height: 100vh !important;
}
.popup-content {
  z-index: 1091 !important;
}
.mt-n3 {
  margin-top: -1rem;
}

.plr-font {
  font-size: 14px !important;
  padding: 9px 20px;
}

.circleIcon {
  width: 30px;
  height: 30px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.no-wrap {
  white-space: nowrap;
}
.sub-text {
  font-size: small;
  line-height: 2px;
}

.tooltip .tooltip-inner {
  line-height: normal;
  font-size: 12px;
  color: #d9d9d9;
  text-align: left;
  padding: 10px;
}

.bg-lightGrey {
  background-color: #f6f7f8;
}
.bg-darkGrey {
  background-color: #e9eaeb;
}

.fullpage-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thankyoupage {
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  &--header {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    margin: 100px 0 20px;
  }
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 60px;
  }
}
// rich editor text styles

.ql-indent-1 {
  margin-left: 10%;
}

.ql-indent-2 {
  margin-left: 20%;
  //
}

.ql-indent-3 {
  margin-left: 30%;
}

.ql-indent-4 {
  margin-left: 40%;
}

.ql-indent-5 {
  margin-left: 50%;
}

.ql-indent-6 {
  margin-left: 60%;
}
.clr-grey{color: #5B5B5B !important}