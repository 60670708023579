$block: '.quantity-input';
$border: 0 solid #dbdbdb;
$border-radius: 3px;
$color--primary:$headingColor;
$modifier--text-color:$white;


#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

#{$block}__modifier,
#{$block}__screen {
  user-select: none;
  outline: none;
}

#{$block}__modifier {
  width: 34px;
  height: 34px;
  font-size: 16px;
  
  background: $color--primary;
  color: $modifier--text-color;
  border: $border;
  text-align: center;
  border-radius: 50%;
  
  cursor: pointer;
  
  &:hover {
    background: darken($color--primary, 10%);
    color: darken($modifier--text-color, 20%)
  }

}

#{$block}__screen {
  width: 38px;
  padding: 10px;
  font-size: $fs-16;
  background: $secondaryBtnBg;
  border: 0;
  border-top: $border;
  border-bottom: $border;
  text-align: center;
}
