.header-container {
  padding: 0px 1rem;

  .back-link {
    color: var(--heading, #3D5A80);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
}

.bordered-container {
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 12px;
  margin-bottom: 10px;
  overflow: hidden;
  .borderless-btn {
    padding: 10px;
    border-radius: 5px;
    &:hover {
      background-color: #e4e6eb;
    }
  }
}
.flex-row {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  &-item {
    width: 50%;
  }
}
.company-public_tabs_item {
  margin-bottom: 0 !important;
}
.profile__section {
  padding: 16px;
}

.public_profile__section {
  padding: 0px;
  margin-top: 1rem;

  .user {
    margin-top: 0px;
    padding: 0px;
  }
}

.Profile_Tab {
  margin-top: 10px;

  .btn-join-team {
    min-width: 120px;
  }
}

.user__info {
  position: relative;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: -1.5rem;
  padding: 1rem 0 1.5rem;

  .left {
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-direction: row;
    padding-left: 150px;

    .about {
      margin-left: 15px;

      .info {
        display: block;
        text-align: center;
        margin-bottom: 3px;

        @media screen and (min-width: 992px) {
          display: flex;
          align-items: center;
          text-align: left;
        }

        .name {
          font-size: 25px;
          font-weight: 500;
        }

        .connections {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #868585;
        }
      }

      .personal_info {
        font-size: 15px;
        text-transform: capitalize;
        width: auto;
        // max-width: 300px;
        word-break: break-word;
        margin-bottom: 1rem;
        min-height: 25px;
      }

      .badge {
        display: none;

        @media screen and (min-width: 992px) {
          display: inline-block;
          cursor: pointer;
          border: 1px solid $lightGrey1;
          padding: 0.3rem 1.5rem;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 12px;
          white-space: nowrap;
          vertical-align: baseline;
          border-radius: 10rem;
          color: $black;
          -webkit-border-radius: 10rem;
          -moz-border-radius: 10rem;
          -ms-border-radius: 10rem;
          -o-border-radius: 10rem;
        }
      }
    }
  }

  .avatar {
    height: 120px;
    width: 120px;
    // width: auto;
    // height: auto;
    position: absolute;
    // padding: 0 0.5rem;
    margin-left: 0.5rem;
    top: -4rem;
    cursor: pointer;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    background: #efefef;
    border: 1px solid #ccc;
    box-sizing: content-box;
    // overflow: hidden;

    .upload__icon {
      position: absolute;
      z-index: 1;
      right: 0;
      right: 0px;
      width: 30px;
      border: 1px solid;
      padding: 5px;
      bottom: 20px;
      border: 1px solid #ccc;
      height: 30px;
      display: none;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: #ddd;
    }

    &:hover {
      .upload__icon {
        display: flex;
      }
    }

    .img {
      //width: 175px;
      //height: 175px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #c8c8c8;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
    }

    &.company-avtar {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background: transparent;
      border: 1px solid #e4e6eb;
    }
  }

  .upload {
    position: absolute;
    bottom: 0;
    right: -0.5rem;
    width: 34.68px;
    height: 34.68px;
    background: #e4e6eb;
    cursor: pointer;
    border-radius: 100%;

    .avatar_upload {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
    }

    input {
      display: none;
    }

    &.company-upload {
      bottom: 1rem;
      right: -0.5rem;
    }
  }

  .right {
    .count {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 1rem;
      color: $black;
      padding-right: 0.8rem;

      @media screen and (min-width: 768px) {
        padding-bottom: 0.5rem;
      }

      .circle,
      .mybridge_count {
        // min-width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 768px) {
          justify-content: flex-end;
        }

        .icon {
          height: auto;
          width: 25px;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        .organizer-label {
          background: #e4e6ea;
          font-size: 95%;
          margin-left: 0.5rem;
          padding: 0 5px;
        }
      }

      // .mybridge_count {}
    }

    .actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 0.5rem;
      position: relative;
      padding: 10px;
      align-items: center;

      .btn-join-team {
        min-width: 120px;
      }

      .profile__action__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 9px 12px;
        gap: 4px;
        background: #e4e6eb;
        border: 1px solid #e4e6eb;
        border-radius: 5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
        color: #5b5b5b;
      }

      .more_actions {
        cursor: pointer;
        padding: 10px;
      }

      .more-menu {
        position: absolute;
        background: $white;
        box-shadow: 0 0 10px $lightGrey1;
        width: fit-content;
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
        border-radius: 0.5rem;
        right: 0;
        top: 3rem;
        right: 1rem;

        .link {
          padding: 0.5rem 1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          user-select: none;

          &:hover {
            background-color: $lightGrey2;
          }

          &:active {
            background-color: $lightGrey1;
          }

          > span {
            padding: 0px 10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .left {
      flex-direction: column;
      padding: 7rem 0 0 0;
      text-align: center;
      // margin-top: -4rem;
    }

    .avatar {
      top: -4rem;

      .img-fluid {
        height: 125px;
        width: 125px;
      }
    }

    .right {
      .count {
        justify-content: center;
      }

      .actions {
        justify-content: center;
        gap: 0.5rem;
      }
    }
  }
}

.user__follow__count {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #5b5b5b;
}

.user__tabs {
  padding-left: 24px;
  // margin-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #5b5b5b;

  .user_tabs_item {
    margin-bottom: 0 !important;
  }
}

.min-w-300 {
  min-width: 300px !important;
}

.border-none {
  border: none !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.border-none {
  border: none !important;
}

.Profile_Banner {
  position: relative;
  background: $white;
  margin-bottom: 1.5rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .banner {
    position: relative;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
    border-bottom: 1px solid $lightGrey1;

    .upload_banner_button {
      position: absolute;
      z-index: 1;
      right: 20px;
      bottom: 20px;
      display: none;
      align-items: center;
      border-radius: 100%;

      button {
        display: flex;
        align-items: center;

        .uicon {
          padding-right: 10px;
        }
      }
    }

    &:hover {
      .upload_banner_button {
        display: flex;
      }
    }

    .img-fluid {
      width: 100%;
      height: 200px;
      // object-fit: cover;
      object-position: center top;

      @media only screen and (min-width: 992px) {
        height: 300px;
      }
    }

    &.company-profile-banner {
      img {
        height: 170px;
      }
    }

    .edit-banner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 1rem;
      bottom: 9rem;
      background: $primaryGrey;
      line-height: 1;
      font-weight: 400;
      font-size: 0.9rem;
      height: 2.5rem;
      padding: 0 1.5rem;

      @media screen and (min-width: 768px) {
        right: 2rem;
        bottom: 2rem;
      }
    }
  }
}

.mobile-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .right-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 900px) {
  .mobile-menu {
    width: 80%;
    position: absolute;
    z-index: 1;
    background: $white;
    padding: 1rem;
    box-shadow: 0 0 5px $grey;
    border-radius: 0.5rem;

    .link-menu {
      display: block;
      padding: 0.5rem;
      width: 100%;
      text-align: left;
      border-radius: 5px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      line-height: 1.7rem;

      &.active,
      &:hover {
        background-color: $skyBlue;
      }
    }
  }
}

.small-font {
  font-size: 80%;
}

.cursor-hand {
  cursor: pointer;
}

.remove-me {
  z-index: 5;
  background: $darkGrey2;
  padding: 0.5rem 1rem;
  color: $white;
  position: absolute;
  right: 3.4rem;
  border-radius: 0.3rem;
  top: 2.5rem;

  &::before {
    content: '';
    position: absolute;
    border-left: 15px solid transparent;
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $darkGrey2;
    top: -1.5rem;
    right: 0.8rem;
  }
}

.btn-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.add-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .section-name {
    flex: 0 1 31%;
    max-width: 31%;
    min-width: 25%;
  }
}

// Comman Classe
textarea.form-control {
  min-height: 10rem;
  resize: none;
}

.btn {
  height: 2.5rem;
  line-height: normal;
  font-size: 1rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.custom-button {
  @extend %vh-center;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;
  font-weight: $fw-500;
  line-height: 1.875rem;
  background-color: transparent;
  border: none;
  color: $primaryColor;

  @media screen and (min-width: 768px) {
    // font-size: 1.125rem;
    border-radius: 0.25rem;
    padding: 0.625rem 1.25rem;
  }

  &:hover {
    background-color: $socialLoginHover;
    color: $primaryColor;
  }

  img {
    margin-right: 0.625rem;
  }
}

.profile-testimonial {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .testimonial {
    display: flex;
    column-gap: 1rem;

    .user-pic {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      min-width: 48px;
    }

    .testimonial-details {
      flex-grow: 1;

      .name {
        font-weight: $fw-600;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .date {
          font-weight: $fw-400;
          font-size: $fs-12;
        }
      }

      .designation {
        font-size: $fs-14;
        color: $grey;
      }

      .post {
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1rem;
        width: fit-content;

        &::after {
          content: '”';
          position: absolute;
          right: -10px;
          font-size: 3rem;
          bottom: -0.8rem;
          line-height: 1rem;
        }

        &::before {
          content: '“';
          position: absolute;
          left: 0;
          font-size: 3rem;
          top: -0.8rem;
        }
      }

      .user-list {
        display: flex;

        .user-pic {
          width: 20px;
          height: 20px;
          min-width: 20px;

          &:nth-child(2) {
            margin-left: -7px;
          }

          &:nth-child(3) {
            margin-left: -7px;
          }
        }
      }
    }

    .btn {
      background: transparent;
      border: none;
      justify-content: center;
      align-items: center;
    }

    .btn-ignore {
      color: $error;
    }

    .btn-accept {
      color: $primaryColor;
    }
  }

  .request-count {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $error;
    color: $white;
    text-align: center;
    line-height: 1.7rem;
    margin-left: 0.5rem;
    font-size: $fs-12;
  }
}

.testimonial-popup {
  .modal-content {
    border-radius: 15px;
  }

  .modal-body {
    padding: 1.5rem;
    padding-top: 0;

    .testimonial-data {
      min-height: 8rem;
      resize: none;
    }

    .testimonial-msg {
      min-height: 7rem;
      resize: none;
      margin-bottom: 0px;
    }
  }
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-skills {
  &--block {
    line-height: 1.25rem;
  }

  &--subtitle {
    color: $secondaryGrey;
    font-size: 0.813rem;
  }

  &--body {
    min-height: 10rem;
  }

  .skills-list {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;

    .skill-item {
      background: $inputBGColor;
      padding: 5px 35px 5px 15px;
      border-radius: 25px;
      border: 1px solid $inputBorderColor;
      position: relative;

      .remove-skills {
        position: absolute;
        right: 10px;
        top: 12px;
      }

      .user-list {
        display: flex;

        .user-pic {
          width: 20px;
          height: 20px;

          &:nth-child(2) {
            margin-left: -7px;
          }

          &:nth-child(3) {
            margin-left: -7px;
          }
        }
      }
    }
  }
}

.profile-chip {
  .chips {
    padding: 5px 15px !important;
    background: $lightGrey;
    border-color: $lightGrey1 !important;
  }
}

.pills {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 30px;
  padding: 0 0.5rem;
  margin: 0.4rem;
  border-radius: 10rem;
  border: 1px solid $inputBorderColor;

  &--name {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &--img {
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
}

.overlay-fix {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.member-card {
  margin-bottom: 1rem;

  .card-header {
    padding: 15px;
    border-bottom: 1px solid $inputBorderColor;
    font-size: 1.2rem;
  }

  .card-body {
    padding: 15px;

    .user-list {
      display: flex;
      gap: 0.5rem;
      justify-content: center;

      > ul > li > div > div > span {
        width: 60px !important;
        height: 60px !important;
        font-size: 16px;
        border: 1px solid $lightGrey1;
      }

      > ul > li:nth-child(4) > div > button {
        width: 60px !important;
        height: 60px !important;
        font-size: 16px;
      }

      > ul > li > div > div > span > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .avatar-placeholder {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
        user-select: none;

        .custom-avatar {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          border-radius: 50%;
          border: 1px solid $lightGrey1;
          background-color: $lightGrey2;

          &:hover {
            background-color: $lightGrey1;
          }
        }
      }

      .more-btn {
        &:hover {
          background-color: $lightGrey2;
        }
      }
    }

    .user-img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      background: $lightGrey1;
      position: relative;

      .number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80%;
      }
    }
  }

  .card-footer {
    border-top: 1px solid $inputBorderColor;
  }
}

.about-card {
  .card-header {
    padding: 15px;
    border-bottom: 1px solid $inputBorderColor;
    font-size: 1.2rem;
  }

  .sub-head {
    font-size: $fs-14;
    font-weight: $fw-500;
    padding: 15px 15px 10px;
  }

  .sub-body {
    font-size: $fs-14;
    font-weight: $fw-400;
    padding: 0 15px 10px;
    line-height: 22px;
  }
}

.show-all {
  background: transparent;
  border: 0;
  color: $black;
  width: 100%;
  height: 64px !important;

  &:hover {
    background: $lightGrey1;
    color: $black;
  }
}

.crop-modal {
  .modal-dialog {
    --bs-modal-width: 800px;
  }

  .modal-content {
    border-radius: 1rem;
    overflow: visible;
  }

  .modal-header {
    padding: 1rem 1.5rem 0.5rem;
  }

  .modal-body {
    padding: 0rem 1.5rem 1rem;

    // File Uploader
    .form-file-upload {
      height: 16rem;
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: relative;
    }

    #input-file-upload {
      display: none;
    }

    #label-file-upload {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 2px;
      border-radius: 1rem;
      border-style: dashed;
      border-color: #cbd5e1;
      background-color: #f8fafc;
    }

    #label-file-upload.drag-active {
      background-color: #ffffff;
    }

    .upload-button {
      cursor: pointer;
      padding: 0.25rem;
      font-size: 1rem;
      border: none;
      font-family: 'Oswald', sans-serif;
      background-color: transparent;
    }

    .upload-button:hover {
      text-decoration-line: underline;
    }

    #drag-file-element {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}

.crop-modal {
  &.profile-pic {
    .ReactCrop--fixed-aspect {
      .ReactCrop__crop-selection {
        border-radius: 50%;
      }
    }
  }

  .rotate-btn {
    background: $lightGrey1;
    padding: 5px;
    height: auto;
    line-height: 0;
    border: 0;
  }
}

.user-list-cir {
  display: flex;

  .user-img {
    width: 20px;
    height: 20px;
    min-width: 20px;

    &:nth-child(2) {
      margin-left: -7px;
    }

    &:nth-child(3) {
      margin-left: -7px;
    }
  }
}

.data-with-logo {
  display: flex;
  margin-bottom: 1.2rem;
  column-gap: 0.5rem;

  .logo {
    padding-top: 10px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .data {
    flex-grow: 1;
  }
}

.aval,
.notaval {
  position: absolute;
  top: 9px;
  right: 10px;
}

.slugtext {
  &.pd-145 {
    .start-1 + .form-control {
      padding-left: 145px !important;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
  }

  &.pd-180 {
    .start-1 + .form-control {
      padding-left: 180px !important;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
  }

  .input-group-icon {
    top: 20px;
  }

  .success {
    border-color: green !important;
  }

  .error {
    border-color: $error !important;
  }
}

.overflow-wrap {
  overflow-wrap: anywhere;
}

.testimonial-modal-body {
  max-height: 500px;
  height: 100%;
  overflow: auto;
}
