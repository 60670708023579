.sidebar {
  top: 80px;

  .primary-card {
    padding: 12px 20px;
    display: flex;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: $black;
    }
  }
}

.events {
  padding: 20px;
  flex-direction: column;
}

.events {
  padding: 20px;
  flex-direction: column;

  &--row {
    width: 100%;

    &:not(:first-child) {
      border-top: 1px solid $lightGrey1;
      padding-top: 1rem;
      margin-top: 1rem;
    }
    h4 {
      font-weight: 400;
      font-size: $fs-18;
      line-height: 1;
      margin: 10px 0 15px;
      color: $black;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      cursor: pointer;
      & + ul {
        margin-top: 12px;

        li {
          padding-top: 12px;
          &:nth-child(2) {
            border-top: 1px solid $lightGrey1;
          }
        }
      }
      li {
        width: 65%;
        strong {
          font-weight: 700;
          font-size: 16px;
          line-height: 1;
          color: #272727;
          display: block;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: $fs-13;
          line-height: 1;
          color: $darkGrey1;
        }

        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 1;
          color: $primaryColor !important;
          margin: 0 0 0.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:first-child{
          width: 35%; 
        }
      }
    }
  }
}
.sidebar-menu{
  margin: 0;
  padding: 0;
  list-style: none;
  &--item{
    padding: 10px 20px;
    > a{text-decoration: none; display: inline-block; margin-right: 5px;}
  }
  
}
.Profile_Section_Card_Title{
  font-size: $fs-16;
  font-weight: $fw-600;
  padding: 10px 20px;
  border-bottom: 1px solid $lightGrey1;
  > span{
    font-size: $fs-14;
    font-weight: $fw-400;
    color: $grey;
    display: block;
  }
}
.profileSection-profile{
  padding: 10px 20px;
  &--body{
    margin-bottom: 10px;
  }
}