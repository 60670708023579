.single-filter {
  border-radius: 5px;
  white-space: nowrap;

  button.btn {
    // height: 34px;
    font-size: 15px;
  }

  .custom-radio,
  .form-group.custom-checkbox {
    margin-bottom: 10px;
  }

  .form-group.custom-checkbox {
    margin-top: 0;
  }
  .custom-checkbox input:checked + span:before {
    background-color: $primaryColor !important;
  }

  .single-filter-keywords-input > .input-group {
    width: 90% !important;
  }

  .columns-2-salary-keywords {
    columns: 2;
  }
  .filter-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #3d5a80;
  }
}
