.networks-container {
  display: block;
  padding: 24px 0;

  &--body {
    background: var(--white);
    padding: 30px;
    border-radius: $rounded-12;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    >span.back-link {
      color: var(--heading, #3D5A80);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    &--tabContainer {
      padding: 15px 0;
    }
  }

  .invite-section {
    margin-bottom: 20px;
  }

  .invite-heading {
    font-size: $fs-15;
    font-weight: $fw-700;
    color: $darkGrey2;
  }

  .invite-container {
    display: block;

    .invite-card {
      padding: 20px;
      border-bottom: 1px solid #d9d9d9;

      &--inner {
        display: flex;
        justify-content: space-between;
      }

      &--leftSection {
        display: flex;
        justify-content: flex-start;
      }

      &--profile {
        padding: 0 15px 0 0;
      }

      &--details {
        display: flex;
        flex-direction: column;

        .card-name {
          font-size: $fs-15;
          font-weight: $fw-500;
          margin: 0px;
        }

        .card-desc {
          font-size: $fs-12;
          color: $darkGrey1;
          font-weight: $fw-500;
        }

        .card-degrees {
          font-size: $fs-12;
          color: $darkGrey1;
          font-weight: $fw-400;

          >span {
            margin-right: 15px;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .card-meta {
          font-size: $fs-10;
          color: $darkGrey1;
          font-weight: $fw-400;
        }
      }

      &--actions {
        display: flex;

        >button:first-child {
          margin-right: 15px;
        }
      }

      &--bottom {
        padding: 15px;
        border-radius: $rounded-12;
        border: 1px solid $lightGrey1;
        margin-top: 10px;

        .card-message {
          font-size: $fs-15;
          line-height: normal;
        }

        a {
          color: $darkGrey2;
          font-size: $fs-15;
          text-decoration: none;
        }
      }

      &.declined {
        .card-desc {
          font-size: $fs-14;
        }
      }
    }

    .user-card {
      padding: 10px;
      border-radius: $rounded-12;
      border: 1px solid $lightGrey1;
      display: flex;
      justify-content: flex-start;
      min-height: 140px;

      &--profile {
        padding: 0 10px 0 0;
      }

      &--details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card-name {
          font-size: $fs-16;
          font-weight: $fw-600;
          margin: 0px;
          color: $primaryColor;
        }

        .card-desc {
          font-size: $fs-14;
          color: $darkGrey1;
          font-weight: $fw-400;
        }

        .card-degrees {
          font-size: $fs-12;
          color: $darkGrey1;
          font-weight: $fw-400;

          >span {
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
          }
        }

        @media (max-width: 1400px) {
          .card-desc {
            font-size: $fs-12;
          }

          .card-degrees {
            font-size: $fs-11;
          }
        }

        .button-section {
          display: flex;
          padding-top: 15px;

          >button:first-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
}