.company-profile {
  .heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;

    .links {
      color: $primaryColor;
      cursor: pointer;
    }
  }
  .borderless-btn {
    padding: 10px;
    border-radius: 5px;
    &.no-br {
      border-radius: 0;
    }
    &:hover {
      background-color: #e4e6eb;
    }
  }
}

.modal-services {
  .services-img {
    background: $primaryGrey;
    width: 150px;
    height: 150px;
    border-radius: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    position: relative;

    .upload {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      &.added-img {
        width: 30px;
        left: auto;
        top: auto;
        right: -1.8rem;
        bottom: 0;
        background: $primaryGrey;
        border-radius: 50%;
        height: 30px;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .serviceDetail {
    min-height: 150px;
  }

  // .action-btn {
  //     .btn-secondary {
  //         height: 40px;
  //         line-height: 1rem;
  //         background: $darkGrey1;
  //         border-color: $darkGrey1;
  //         color: $black;
  //         margin-right: 1rem;
  //     }

  //     .btn-primary {
  //         height: 40px;
  //         line-height: 1rem;
  //     }
  // }
}

// .service-item {
//     background: $primaryGrey;
//     margin-bottom: 1rem;
//     padding: 1rem;
//     position: relative;
//     p{font-size: $fs-14;}
//     .service-img {
//         width: 100%;
//     }

//     .cta-menu {
//         width: 20px;
//         position: absolute;
//         top: 1rem;
//         right: 1rem;
//     }
// .service-item {
//     background: $primaryGrey;
//     margin-bottom: 1rem;
//     padding: 1rem;
//     position: relative;
//     p{font-size: $fs-14;}
//     .service-img {
//         width: 100%;
//     }

//     .cta-menu {
//         width: 20px;
//         position: absolute;
//         top: 1rem;
//         right: 1rem;
//     }

//     .more-menu {
//         position: absolute;
//         top: 2.5rem;
//         right: 1.5rem;
//         padding: 1rem;
//         background: $white;
//         width: 12rem;
//         box-shadow: 0 0 10px $darkGrey1;
//         border-radius: 0.5rem;
//     .more-menu {
//         position: absolute;
//         top: 2.5rem;
//         right: 1.5rem;
//         padding: 1rem;
//         background: $white;
//         width: 12rem;
//         box-shadow: 0 0 10px $darkGrey1;
//         border-radius: 0.5rem;

//         .link {
//             cursor: pointer;
//             font-weight: $fw-600;
//         .link {
//             cursor: pointer;
//             font-weight: $fw-600;

//             &+.link {
//                 margin-top: 0.5rem;
//             }
//         }
//     }
// }
//             &+.link {
//                 margin-top: 0.5rem;
//             }
//         }
//     }
// }

.user-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  .user-card {
    display: flex;
    margin-bottom: 1rem;
    column-gap: 0.5rem;
    @media (min-width: 576px) {
      flex: 0 1 100%;
    }
    @media (min-width: 1200px) {
      flex: 0 1 48%;
    }
    .profile-pic {
      position: relative;
      width: 60px;
      height: 60px;
      img {
        border-radius: 50%;
      }
      .status {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 40px;
        right: 6px;
        &.available {
          background: $success;
        }
        &.busy {
          background: $error;
        }
        &.notActive {
          background: $darkGrey1;
        }
      }
    }
    .profile-detail {
      flex-grow: 1;
      flex-basis: 0;
      .user-name {
        font-size: 1.1rem;
        font-weight: $fw-600;
      }
      .desination {
        font-size: 90%;
      }
    }
  }
}

.candidate-item {
  border: 1px solid $primaryGrey;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  margin-top: 0.5rem;
  .user-pic {
    .candidate-img {
      width: 50px;
      height: 50px;
      margin-left: 0.5rem;
      border-radius: 50%;
    }
  }
  .user-details {
    flex-grow: 1;
    flex-basis: 0;
    .name {
      font-weight: $fw-600;
      .tags {
        color: $primaryColor;
        font-weight: $fw-400;
        font-size: $fs-14;
        margin-left: 0.5rem;
      }
    }
    .links {
      display: flex;
      justify-content: space-around;
      font-size: $fs-13;
      .intrested {
        color: $primaryColor;
        cursor: pointer;
      }
      .not-intrested {
        color: $error;
        cursor: pointer;
      }
      .unsure {
        cursor: pointer;
      }
    }
    .date {
      font-size: $fs-13;
    }
    .company-name {
      font-size: $fs-12;
    }
    .job-title {
      font-size: $fs-14;
    }
  }
}
.job-tabs-container {
  border-bottom: 2px solid #e4e6eb;
  .nav-item {
    margin-bottom: -2px;
  }
}
