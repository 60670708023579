.form-select {
  border: 0.0625rem solid $inputBorderColor;
  color: $black;
  border-radius: 0.5rem;
  height: 44px;
  background-color: $inputBGColor;
  padding: 0.375rem 2.25rem 0.375rem 1.25rem;
  &::placeholder {
    color: $black;
  }

  &:focus {
    color: $black;
    background-color: $checkboxBg;
    border-color: $primaryColor;
    box-shadow: 0 0 0 0.0625rem $primaryColor;
  }
}

.select-dropdown {
  border: 0.0625rem solid $inputBorderColor;
  color: $darkGrey2;
  border-radius: 0.5rem;
  height: 44px;
  background-color: $inputBGColor;
  .css-13cymwt-control,
  .css-t3ipsp-control {
    background-color: transparent;
    border-color: transparent;
    line-height: 2.5rem;
  }
  // padding: 0.375rem 2.25rem 0.375rem 1.25rem;
}
.custom-select {
  &__control {
    background-color: $inputBGColor !important;
    border: 0.0625rem solid $inputBorderColor;
    height: 44px;
    border-radius: 0.5rem !important;
    &--is {
      &-focused {
        border: 1px solid #cccccc;
      }
    }
  }
  &__indicator-separator {
    display: none !important;
  }
  &__indicators {
    padding: 0 10px;
    cursor: pointer;
  }
  &__placeholder {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
  .circle-drop-container {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $lightGrey1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
