@import '../utils/variables';
.onboarding {
  min-height: 100vh;
  display: flex;
  padding: 1.5rem 0;
  align-items: center;
  justify-content: center;

  &_content {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    max-width: 500px;
    margin: 0 auto;
    padding:15px 20px;

    &.full-width {
      max-width: 1320px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 125%;
      color: #3D5A80;
      margin-bottom: 16px;
      text-align: center;
    }

    .submit {
      font-weight: 600;
      font-size: 15px;
      line-height: 87%;
      border: none;
      margin-top: 20px;
      color: #ffffff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      background: #3d5a80 !important;
      border-radius: 5px;
      transition: all ease-in-out 0.3s;
      margin-left: 0px;
      &.secondary {
        color: #5b5b5b;
        background: #e4e6eb !important;

        img {
          margin-right: 10px;
        }

        &:hover {
          background: #ccddfe;
        }
      }

      &:disabled {
        pointer-events: none;
        background: #4d88fe;
      }

      &:hover {
        background: #014ce4;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 40px;
      text-align: center;
      color: #5b5b5b;
    }
  }

  &_stepper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    gap: 2px;
    list-style: none;
    li {
      width: calc((100% - 10px) / 6);
      background: #dddddd;
      height: 16px;
      pointer-events: none;
      // cursor: pointer;

      &.active {
        background: $primaryColor;
        pointer-events: all;
      }
    }
  }
}

.custom-react-select {
  &:not(:first-child) {
    margin-top: 20px;
  }
  label {
    font-weight: 400;
    margin-bottom: 4px;
    display: block;
    font-size: 16px;
    line-height: 16px;
    position: relative;
    color: #5b5b5b;

    sup {
      font-size: 16px;
      top: 0;
      left: 2px;
      line-height: 1;
    }
  }

  & > div {
    & > div {
      min-height: 45px;
      background: #f8f8f8;
      border: 1px solid #dddddd;
      border-radius: 10px;

      & > div:first-child {
        padding-left: 20px;
      }

      & > div:nth-child(2) {
        & > div {
          color: #697386;
        }
        & > span {
          display: none;
        }
      }
    }
  }

  & > input {
    padding: 14px 20px;
    height: 48px;
    display: block;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}

body {
  .custom-react-select {
    & > div {
      & > div + div {
        & > div {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.student-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 87%;
    color: #5b5b5b;
    margin-top: 20px;
    cursor: pointer;
  }
}

.additional-actions {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  list-style: none;

  li {
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
    color: #5b5b5b;

    &:hover {
      background: #eef0f5;
    }
  }
}

.additional-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #e41c19;
}

.eligibility {
  height: 53px;
  padding: 0 20px;
  background: #f8f8f8;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #747474;
  }
}

.custom-switch {
  width: 41px;
  height: 25px;
  border: 1px solid #d9d9d9;
  border-radius: 100px;
  padding: 3px;
  position: relative;

  span {
    width: 100%;
    height: 100%;
    background: #dddddd;
    display: block;
    border-radius: 100px;
    position: relative;
    transition: all ease-in-out 0.3s;
    &::before {
      content: '';
      transition: all ease-in-out 0.3s;
      width: 17px;
      height: 17px;
      left: 0;
      top: 0;
      position: absolute;
      background: #5b5b5b;
      border-radius: 100px;
    }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;

    &:checked {
      & + span {
        background: #E4E6EB;
        &::before {
          background: $primaryColor;
          left: 16px;
        }
      }
    }
  }
}

.onboarding-profile {
  text-align: center;

  .icon {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d9d9d9;
    font-weight: 600;
    font-size: 55px;
    line-height: 120%;
    color: $primaryColor;

    span {
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: hidden;
      text-transform: uppercase;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    button {
      width: 45px;
      height: 45px;
      display: flex;
      border: none;
      background-color: #ececec;
      position: absolute;
      right: 0;
      bottom: 18px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      input {
        display: none;
      }

      label {
        cursor: pointer;
        display: flex;
        > svg {
          width: 28px;
          height: 23px;
        }
      }
    }
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 87%;
    margin-bottom: 8px;
    color: #5b5b5b;
  }

  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 87%;
    margin-bottom: 8px;
    color: #5b5b5b;
  }

  h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 87%;
    /* or 10px */
    margin-bottom: 0;
    color: #868585;
  }
}

.editable-input-field {
  position: relative;

  input {
    padding-left: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.edit {
      padding-right: 60px;
    }
    &:read-only {
      pointer-events: none;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }
  .right-icon {
    right: 20px;
    left: auto;
    cursor: pointer;
  }
}

.connection-card {
  margin-bottom: 28px;
  padding: 28px 16px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  text-align: center;

  .btn {
    height: 36px;

    & + .btn {
      margin-left: 5px;
    }
  }

  .button-group {
    margin-top: 20px;
  }

  .connection-chaining {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #747474;

      & + li {
        margin-left: 1.25rem;
      }

      svg {
        margin: 0 2px;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 4px;
    color: #747474;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 4px;
    color: #000000;
  }

  .icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 12px;
    border-radius: 50%;

    & > div {
      span {
        font-size: 20px;
      }
    }
  }
}

.btn {
  &-transparent {
    color: #0255fe;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: #0255fe;
      background-color: rgb(204, 221, 254);
    }
  }
}

.email-verification {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrap {
    padding: 16px;
    width: 500px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    position: relative;
    .closeBtn{border: none; background: transparent; position: absolute; right: 16px; top: 10px; padding: 0px;}
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 87%;
      /* or 17px */
      text-align: center;
      margin-bottom: 16px;

      color: #5b5b5b;
    }

    input {
      &:read-only {
        pointer-events: none;
      }
    }

    .btn {
      margin-top: 20px;
      width: 100%;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      /* or 21px */

      text-align: center;

      margin-bottom: 20px;
      color: #5b5b5b;

      span {
        color: #0255fe;
      }
    }
  }
}
