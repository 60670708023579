@import "../../../scss//utils/variables";

.circular-progress {
    background: none;
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 6px solid #eee;
        position: absolute;
        top: 0;
        left: 0;
    }

    >span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    div.stopper {
        display: none;
        background-color: $secondaryGrey;
        cursor: pointer;
        border-radius: 100%;
        opacity: 0.7;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 10px;
        position: relative;
        z-index: 10;
    }

    &:hover {
        div.stopper {
            display: flex
        }
    }

    .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 2px;
        border-style: solid;
        position: absolute;
        top: 0;
    }

    .progress-left {
        left: 0;

        .progress-bar {
            left: 100%;
            border-top-right-radius: 80px;
            border-bottom-right-radius: 80px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
            border-color: $primaryColor;
        }
    }

    .progress-right {
        right: 0;

        .progress-bar {
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            border-color: $primaryColor;
        }
    }



    .progress-value {
        position: absolute;
        top: 0;
        left: 0;
    }

}