$radio-border-color: $primaryColor;
$radio-size: 20px;
$radio-checked-size: 10px;
$radio-ripple-size: 15px;

@keyframes ripple {

  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% {
    box-shadow: 0px 0px 0px $radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.radio-button {

  input[type="radio"] {
    display: none;

    &:checked+label:before {
      border-color: $primaryColor;
      // background-color: $primaryColor;
      animation: ripple 0.2s linear forwards;
    }

    &:checked+label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $radio-size;
    position: relative;
    padding: 0 1rem;
    margin-bottom: 0;
    color: $black;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $radio-size;
      height: $radio-size;
      background-color: $lightGrey1;
    }

    &:after {
      top: $radio-size / 2 - $radio-checked-size / 2;
      left: $radio-size / 2 - $radio-checked-size / 2;
      width: $radio-checked-size;
      height: $radio-checked-size;
      transform: scale(0);
      background: $primaryColor;
    }
  }
}

.radio-wrapper {
  .custom-radio {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}


.custom-radio {
  display: flex;
  
  label {
    cursor: pointer;
    user-select: none;

    input {
      display: none;

      &:checked {
        &+span {
          &::before {
            background-color: $white;
            border-color: $primaryColor;
          }

          &::after {
            opacity: 1;
          }
        }
      }
    }

    span {
      padding-left: 25px;
      position: relative;

      &::before {
        width: 18px;
        position: absolute;
        left: 0;
        height: 18px;
        top: 0.12rem;
        background: $white;
        border: 1px solid $radioBorder;
        border-radius: 100px;
        content: '';
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        left: 4px;
        top: 6px;
        opacity: 0;
        background: $primaryColor;
        border-radius: 100px;
      }
      &.wd-20{
        width: 20px;
        height: 20px;
      }
      &.with-label{
        &::after {
          top: 6px;
        }
      }
    }
  }
}