.company-role {
  color: rgba(134, 133, 133, 1);
  font-size: 14px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.company-name {
  color: rgba(91, 91, 91, 1);
  font-size: 18px;
  line-height: 120%;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.button-color {
  background-color: #e4e6eb !important;
  color: black;
}
.button-color:hover {
  background-color: #3d5a80 !important;
  color: white;
}
.company-tabs-item {
  margin-bottom: 0 !important;
}
