.create-post {
  border: none;
  &--header {
    padding: 20px;

    .wrap {
      & + div {
        padding-left: calc(8px + 41px);
      }
      display: flex;
      align-items: flex-start;
      column-gap: 8px;

      .content {
        flex-grow: 1;
        position: relative;

        textarea {
          display: block;
          resize: none;
          width: 100%;
          background: #f3f3f3;
          border-radius: 50px;
          border: none;
          padding: 10.5px 14px;
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;

          &.focused {
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
            border-radius: 0;
          }

          &:focus {
            border: none;
            outline: none;
          }
        }
      }

      .icon {
        width: 40px;
        flex-shrink: 0;
        background: $lightGrey1;
        height: 40px;
        border-radius: 50%;

        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
      }
    }

    .navbar-dropdown {
      .dropdown-wrap {
        padding: 0 16px;
        width: auto;
        height: 30px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 100px;
        background-color: transparent;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 1;
          color: #000000;
        }
      }

      .navbar-dropdown--menu {
        top: 36px;
        &.profile-dropdown .menu-body {
          ul {
            margin-top: 15px;
          }
          h4 {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            margin: 0;
            color: #000000;
          }

          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            margin: 0;
            color: #747474;
          }
        }
      }
    }
  }

  &--footer {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .post-button {
      font-weight: 500;
      font-size: 15px;
      line-height: 30px;
      color: #ffffff;
      width: 77.23px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primaryColor;
      transition: all ease-in-out 0.3s;
      border: none;
      border-radius: 5px;

      &:disabled {
        opacity: 0.5;
      }

      &:not(:disabled):hover {
        background: #df7d23;
      }
    }

    .navbar-dropdown {
      position: relative;
      &--menu {
        top: 1.5rem;
      }
      @media screen and (max-width: 1023px) {
        width: 100%;

        .navbar-dropdown--menu.profile-dropdown {
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .dropdown-wrap {
        padding: 0;
        width: auto;
        height: auto;
        background-color: transparent;
      }
      .navbar-dropdown--menu.profile-dropdown .menu-body {
        ul {
          margin-top: 15px;
        }
        h4 {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          margin: 0;
          color: #000000;
        }

        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          margin: 0;
          color: #747474;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 15px;
      display: flex;
      align-items: center;
      line-height: 1;
      color: #000000;
    }

    &::after {
      content: '';
      width: calc(100% - 40px);
      left: 20px;
      top: 0;
      position: absolute;
      height: 1px;
      background-color: #d0cfcf;
    }
    & > ul {
      display: flex;
      align-items: center;
      list-style: none;
      column-gap: 20px;
      margin-bottom: 16px;

      @media screen and (min-width: 1024px) {
        column-gap: 4px;
        margin-bottom: 0;
      }
      li {
        position: relative;
        & > div {
          position: absolute;
          z-index: 9;
          right: 0;
          top: 40px;
        }
        button {
          width: 40px;
          height: 40px;
          background: transparent;
          display: flex;
          align-items: center;
          border-radius: 50%;
          justify-content: center;
          transition: all ease-in-out 0.3s;
          border: none;

          label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          input {
            display: none;
          }

          &:hover {
            background-color: darken($color: #f8f8f8, $amount: 5%);
          }
        }
      }
    }
  }
}
