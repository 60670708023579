.separator {
  position: relative;
  margin: 0.625rem 0;
  @media screen and (min-width: 768px) {
    margin: 1.875rem 0 2.1875rem;
  }
  &::before {
    content: '';
    height: 0.125rem;
    background-color: $black;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  span {
    margin: 0 auto;
    text-align: center;
    z-index: 1;
    position: relative;
    display: block;
    width: 3.75rem;
    background-color: $white;
    font-weight: $fw-700;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: $black;
  }
}