@import '../../../../../scss/utils/variables';

.add_profile_sections {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  gap: 10px;

  &__buttons {
    display: flex;
    gap: 16px 8px;
    flex-wrap: wrap;
  }
  &__divider {
    width: 100%;
    border: 1px solid $secondaryBtnBg;
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 9px;
    gap: 10px;
    width: 250px;
    height: 48px;
    background: #e4e6eb;
    border-radius: 8px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 87%;
    color: #5b5b5b;
    flex-grow: 1;
    &:hover,
    &:focus {
      background-color: $primaryColor;
      color: $white;
    }
  }
}

.section_title {
  font-size: $fs-20 !important;
  color: $darkGrey2;
}
