.primary-layout {
  @media screen and (min-width: 768px) {
    // height: calc(100vh - 3.75rem);
    // display: flex;
    // overflow: auto;
    // align-items: flex-start;
    flex-wrap: wrap;
  }

  &--left {
    width: 30%;
    flex-shrink: 0;
    // height: 100%;
    padding-right: 2rem;
  }

  &--middle {
    @media screen and (min-width: 768px) {
      width: calc(100% - 30%);
    }

    @media screen and (min-width: 1280px) {
      width: 40%;
    }
  }

  &--right {
    @media screen and (min-width: 768px) {
      width: calc(100% - 30%);
      padding-bottom: 2rem;
      margin-left: auto;
    }

    @media screen and (min-width: 1280px) {
      height: 100%;
      width: 30%;
      padding-bottom: 0;
    }
  }
}

.footer-page{
  padding: 20px;
  text-align: center;
  
}