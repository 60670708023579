@import './variables';

:root {
  --primaryColor: #3d5a80;
  --primaryHoverColor: #21354f;
  --primaryDisabledColor: #e4e6eb;
  --primaryDisabledText: #a8a8a8;
  --white: #ffffff;
  --btn-height: 44px;
  --linkHoverBg: #e8efff;
  --secondaryColor: #e4e6eb;
  --secondaryHoverColor: #ccddfe;
  --secondaryDisabledColor: #eef0f5;
  --secondaryTxt: #5b5b5b;
  --blue: #3d5a80;
  --darkBlue: #003049;
  --borderGrey: #d9d9d9;
  --dangerBG:#ff0000;
  --dangerBGHover:#bb2d3b;
  --whiteTxt:#3D5A80;
}

.btn {
  --bs-btn-font-size: 15px;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 20px;
  --bs-btn-border-radius: 0.3125rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: none;
  height: var(--btn-height);
  white-space: nowrap;

  &-sm {
    --bs-btn-font-size: 14px;
    --bs-btn-font-weight: 400;
    --btn-height: 36px;
    --bs-btn-padding-y: 0;
    --bs-btn-padding-x: 10px;
    --bs-btn-line-height: 1;
    height: var(--btn-height);
  }

  &-primary {
    --bs-btn-color: var(--white);
    --bs-btn-bg: var(--primaryColor);
    --bs-btn-border-color: var(--primaryColor);
    --bs-btn-hover-color: var(--white);
    --bs-btn-hover-bg: var(--primaryHoverColor);
    --bs-btn-hover-border-color: var(--primaryHoverColor);
    --bs-btn-active-color: var(--white);
    --bs-btn-active-bg: var(--primaryHoverColor);
    --bs-btn-active-border-color: var(--primaryHoverColor);
    --bs-btn-disabled-color: var(--primaryDisabledText);
    --bs-btn-disabled-bg: var(--primaryDisabledColor);
    --bs-btn-disabled-border-color: var(--primaryDisabledColor);
  }

  &-secondary {
    --bs-btn-color: var(--secondaryTxt);
    --bs-btn-bg: var(--secondaryColor);
    --bs-btn-border-color: var(--secondaryColor);
    --bs-btn-hover-color: var(--secondaryTxt);
    --bs-btn-hover-bg: var(--secondaryHoverColor);
    --bs-btn-hover-border-color: var(--secondaryHoverColor);
    --bs-btn-active-color: var(--secondaryTxt);
    --bs-btn-active-bg: var(--secondaryHoverColor);
    --bs-btn-active-border-color: var(--secondaryHoverColor);
    --bs-btn-disabled-color: var(--secondaryTxt);
    --bs-btn-disabled-bg: var(--secondaryDisabledColor);
    --bs-btn-disabled-border-color: var(--secondaryDisabledColor);
  }
  &-blue {
    --bs-btn-color: var(--white);
    --bs-btn-bg: var(--blue);
    --bs-btn-border-color: var(--blue);
    --bs-btn-hover-color: var(--white);
    --bs-btn-hover-bg: var(--blue);
    --bs-btn-hover-border-color: var(--blue);
    --bs-btn-active-color: var(--white);
    --bs-btn-active-bg: var(--blue);
    --bs-btn-active-border-color: var(--blue);
    --bs-btn-disabled-color: var(--white);
    --bs-btn-disabled-bg: var(--primaryDisabledColor);
    --bs-btn-disabled-border-color: var(--primaryDisabledColor);
  }
  &-dark-blue {
    --bs-btn-color: var(--white);
    --bs-btn-bg: var(--darkBlue);
  }

  &-link {
    --bs-link-color: #5b5b5b;
    --bs-link-hover-color: var(--primaryColor);
    --bs-btn-hover-bg: var(--linkHoverBg);
    text-decoration: none;
    --bs-btn-padding-y: 0px;
    min-height: auto !important;
    &:hover {
      background: none !important;
    }
  }
  &-white {
    --bs-btn-color: var(--whiteTxt);
    --bs-btn-bg: var(--white);
    --bs-btn-border-color: var(--borderGrey);
    --bs-btn-hover-color: var(--secondaryTxt);
    --bs-btn-hover-bg: var(--secondaryColor);
    --bs-btn-hover-border-color: var(--borderGrey);
    --bs-btn-active-color: var(--secondaryTxt);
    --bs-btn-active-bg: var(--secondaryColor);
    --bs-btn-active-border-color: var(--borderGrey);
    --bs-btn-disabled-color: var(--secondaryTxt);
    --bs-btn-disabled-bg: var(--borderGrey);
    --bs-btn-disabled-border-color: var(--borderGrey);
  }
  &-danger {
    --bs-btn-color: var(--white);
    --bs-btn-bg: var(--dangerBG);
    --bs-btn-border-color: var(--dangerBG);
    --bs-btn-hover-color: var(--white);
    --bs-btn-hover-bg: var(--dangerBGHover);
    --bs-btn-hover-border-color: var(--dangerBGHover);
    --bs-btn-active-color: var(--white);
    --bs-btn-active-bg: var(--dangerBGHover);
    --bs-btn-active-border-color: var(--dangerBGHover);
    --bs-btn-disabled-color: var(--secondaryTxt);
    --bs-btn-disabled-bg: var(--borderGrey);
    --bs-btn-disabled-border-color: var(--borderGrey);
  }
  &-lg{
    padding: 0.6rem 1.5rem !important;
    height: auto !important;
  }
}

.onlyIcon {
  --bs-btn-font-size: 14px;
  --bs-btn-font-weight: 400;
  --btn-height: 23px;
  --bs-btn-padding-y: 0;
  --bs-btn-padding-x: 10px;
  --bs-btn-line-height: 1;
  height: var(--btn-height);
}
