.tabs-container {
  &--horizontal {
    .nav-link {
      color: $darkGrey3;
      padding: unset;
      padding: 0.5rem 1rem;
      font-weight: $fw-600;
      border-bottom: 1px solid transparent;
      border-radius: unset;
      -webkit-border-radius: unset;
      -moz-border-radius: unset;
      -ms-border-radius: unset;
      -o-border-radius: unset;
      font-size: $fs-15;

      &:focus-visible {
        outline: none;
      }

      &.active {
        color: $primaryColor;
        border-bottom: 2px solid $primaryColor;
        background-color: transparent;
      }

      &:hover {
        color: $primaryColor;
        border-bottom: 2px solid $primaryColor;
        border-radius: unset;
        -webkit-border-radius: unset;
        -moz-border-radius: unset;
        -ms-border-radius: unset;
        -o-border-radius: unset;
      }
    }
  }

  &--vertical {
    .nav-link {
      width: 100%;
      text-align: left;
      color: $black;
      margin-bottom: 0.25rem;
      border-radius: $rounded-5;
      -webkit-border-radius: $rounded-5;
      -moz-border-radius: $rounded-5;
      -ms-border-radius: $rounded-5;
      -o-border-radius: $rounded-5;
      display: flex;
      gap: 10px;
      align-items: center;
      &:focus-visible {
        outline: none;
      }

      &.active, &:hover {
        background-color: $secondaryBtnBg;
        color: $black;
        .circleIcon {
          background-color: $primaryColor;
          svg{filter:invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(125%) contrast(102%);}
        }
      }

      // &:hover {
      //   background-color: $secondaryBtnBg;
      //   color: $black;
      //   .circleIcon {
      //     background-color: $primaryColor;
      //     svg{filter:invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(125%) contrast(102%);}
      //   }
      // }
    }
  }
}
.chatTabsHeader.nav-tabs .nav-link {
  border: none;
}
.chatTabsHeader.nav-tabs .nav-link.active {
  border-bottom: 1px solid $primaryColor;
}
