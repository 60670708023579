.react-confirm-alert-overlay{
  background: rgba(217, 217, 217, 0.5);
  z-index: 9999;
}
.react-confirm-alert {
  &-body {
    width: 500px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    h1{
      font-size: 24px;
      font-weight: 600;
    }
  }
  &-button-group {
    justify-content: flex-end;
    button {
      outline: none;
      background: #333;
      border: none;
      display: inline-block;
      padding: 6px 18px;
      color: #eee;
      margin-right: 10px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;

      &:first-child {
       background: var(--primaryColor);
      }
      &:last-child {
       background: var(--secondaryColor);
        color: var(--secondaryTxt);
      }
    }
  }
}
