@import '../utils/variables';
@import '../utils/placeholders';

.user-flow {
  min-height: 100vh;
  background-color: $white;
  @extend %vh-center;

  &--form,
  &--signUp,
  &--forgot-password {
    .form-group {
      margin-bottom: 16px !important;
    }
    .form-control {
      height: 48px;
    }
    .separator {
      margin: 16px 0 !important;
    }
  }

  &--bannerSection {
    background-image: url('/assets/images/womanoncomputer2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    justify-content: space-between;
    align-items: center;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    padding-top: 40px;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(105, 141, 171, 0.54);
    }
  }

  &--bottom {
    display: flex;
    gap: 15px;
    padding: 30px 0;
    justify-content: space-between;
    width: 100%;
  }

  &--iconBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .iconbox-circle {
      width: 72px;
      height: 72px;
      display: flex;
      padding: 10px;
      border: 1px solid $white;
      border-radius: 50%;
      &-inner {
        width: 52px;
        height: 52px;
        background: $primaryOrangeColor;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .iconbox-text {
        font-size: 20px;
        text-align: center;
      }
    }
  }
  .newLoginInfoBoxBottom {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    width: 100%;
    gap: 35px;
    position: relative;
    z-index: 5;
    > a {
      font-size: 16px;
      color: $white;
      text-decoration: none;
    }
  }
  &--info {
    &.newLoginInfoBox {
      z-index: 1;
      position: relative;
      max-width: 500px;
      color: $white;
      h1 {
        font-family: $primaryFontPoppings;
        font-weight: $fw-700;
        font-size: $fs-48;
        line-height: 120%;
        margin-bottom: 30px;
      }
      h3 {
        font-family: $primaryFontPoppings;
        font-weight: $fw-700;
        font-size: 30px;
        margin-bottom: 20px;
      }
      p {
        color: $white;
        font-size: $fs-20;
        font-weight: 400;
      }
    }

    @extend %center-with-direction;
    margin-bottom: 2.8125rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 4.4375rem;
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
      align-items: flex-start !important;
    }
    img {
      display: block;
      width: 8.5625rem;
      margin-bottom: 0.625rem;

      @media screen and (min-width: 768px) {
        width: 10.875rem;
      }

      @media screen and (min-width: 1024px) {
        width: 13.5625rem;
        align-self: flex-start;
      }
    }

    p {
      font-size: 1rem;
      line-height: 188%;
      color: $black;
      font-weight: $fw-400;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 1.3125rem;
        line-height: 143%;
        span {
          display: block;
        }
      }

      @media screen and (min-width: 1024px) {
        text-align: left;
        span {
          display: initial;
        }
      }

      @media screen and (min-width: 1280px) {
        span {
          display: block;
        }
      }
    }
  }
  &--formSection {
    display: flex;
    min-height: 100vh;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &--formHeader {
    font-weight: 700;
    font-size: 30px;
    font-family: $primaryFontPoppings;
    color:$darkGrey2;
    margin-bottom: 30px;
  }
  &--formSubHeader {
    font-weight: 500;
    font-size: 16px;
    font-family: $primaryFontPoppings;
    text-align: left;
    display: block !important;
    margin: 15px 0;
  }
  &--form {
    background: $white;
    padding: 1.25rem;
    width: 90%;
    .forgot-password {
      @extend %flex-between;
      .form-group {
        margin: 0px !important;
      }
      p {
        font-weight: $fw-400;
        font-size: 0.9375rem;
        line-height: 1;
        cursor: pointer;
        color: $primaryColor;
        &:hover {
          text-decoration: underline;
        }
      }
      a {
        color: $primaryColor;
        text-decoration: none;
      }
    }

    p {
      // @extend %vh-center;
      font-weight: $fw-500;
      font-size: 1rem;
      line-height: 1.875rem;
      color: $black;

      @media screen and (min-width: 768px) {
        font-size: 1.125rem;
      }

      a {
        font-weight: $fw-500;
        margin-left: 0.3125rem;
        line-height: 1.875rem;
        color: $primaryColor;
        text-decoration: none;

        @media screen and (min-width: 1280px) {
          font-weight: $fw-600;
          // font-size: 1.5rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h1 {
      font-weight: $fw-600;
      font-size: 1.625rem;
    }

    // .btn {
    //   margin-top: 1.25rem;
    //   @media screen and (min-width: 768px) {
    //     margin-top: 2.1875rem;
    //   }
    // }
    .back-to-page {
      a {
        font-weight: $fw-400;
        font-size: 0.9375rem;
        line-height: 1;
        cursor: pointer;
        color: $darkGrey2;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.social-login {
  @extend %vh-center;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;
  font-weight: $fw-500;
  font-size: 1.125rem;
  line-height: 1.875rem;
  background-color: transparent;
  border: none;
  color: $black;

  @media screen and (min-width: 768px) {
    border-radius: 0.25rem;
    padding: 0.625rem 1.25rem;
  }

  &:hover {
    background-color: $socialLoginHover;
  }

  img {
    margin-right: 0.625rem;
  }
}
