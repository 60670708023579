.mutual-container-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  @media (min-width:992px) and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}
.mutual-user-card {
  padding: 15px;
  border-radius: $rounded-12;
  border: 1px solid $lightGrey1;
  display: flex;
  flex-direction: column;
  &--inner {
    display: flex;
    justify-content: flex-start;
  }
  &--profile {
    padding: 0 15px 0 0;
  }
  &--details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .card-name {
      font-size: $fs-16;
      font-weight: $fw-600;
      margin: 0px;
      color: $primaryColor;
    }
    .card-desc {
      font-size: $fs-14;
      color: $darkGrey1;
      font-weight: $fw-400;
    }
    .card-degrees {
      font-size: $fs-12;
      color: $darkGrey1;
      font-weight: $fw-400;

      > span {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    @media (max-width: 1400px) {
      .card-desc {
        font-size: $fs-12;
      }
      .card-degrees {
        font-size: $fs-11;
      }
    }
  }
  &--bottom {
    padding-top: 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (min-width:1200px) and (max-width: 1400px) {
      padding-left: 85px;
    }
    > button {
      margin-right: 10px;
      &:last-child {
        // margin-top: 10px;
        margin-right: 0px;
      }
    }
  }
}
