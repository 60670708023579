.page-not-found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  padding: 8px;

    &__heading {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: $black;
    }

    &__description {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
    }
  
}
