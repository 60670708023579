@import './variables';

* {
  font-family: $primaryFont;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-size: 1rem;
  background-color: $primaryBg;
  padding-bottom: 60px;

  @media screen and (min-width: 768px) {
    padding-bottom: 0;
  }
}

p,
ul,
ol {
  margin: 0;
  padding: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.cursor-default {
  cursor: default !important;
}
.primaryLink {
  font-size: $fs-16;
  font-weight: $fw-500;
  text-decoration: none;
  vertical-align: middle;
}
.clearfix::after {
  clear: both;
  height: 0px;
  opacity: 0;
  display: block;
  visibility: hidden;
  content: '';
}
.draggable {
  position: fixed;
  top: 100px;
  right: 100px;
  width: 420px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 5px 0;
}
.draggable.medium {
  width: 600px;
  .draggable-content {
    padding: 15px;
    height: auto;
  }
}
.draggable.wide {
  width: 800px;
}
.draggable-panel {
  background-color: #fff;
  cursor: move;
}

.draggable-content {
  padding: 15px;
  height: 360px;
}
.draggable.wide .draggable-content {
  height: 400px;
}
.draggable-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
  button {
    background: none;
    border: none;
  }
}
.progress {
  height: 8px;
}
.progress-bar {
  background-color: $primaryColor;
}
a {
  color: $primaryColor;
  text-decoration: none;
}
a:hover {
  color: $primaryHoverColor;
}
[role='tooltip'].popup-content {
  width: auto !important;
}
.chatProviderContainer {
  opacity: 1;
  width: auto;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: visible;
}
.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}


 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
