.post-card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border: none;
  padding-top: 20px;

  &--header {
    padding: 0 20px 20px;

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #000000;
    }

    .wrap {
      display: flex;
      column-gap: 8px;

      .content {
        align-items: flex-start;
        flex-grow: 1;
        display: flex;
        position: relative;

        .navbar-dropdown {
          margin-left: auto;

          @media screen and (min-width: 768px) {
            position: relative;
          }

          &--menu {
            top: 2rem;
            width: 250px;
          }

          .dropdown-wrap {
            border: none;
            background: transparent;
            line-height: 1;
            display: flex;
            width: 34px;
            height: 34px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all ease-in-out 0.3s;

            &>svg {
              width: auto;
              height: auto;
            }

            &:hover {
              background-color: $lightGrey;
            }
          }
        }

        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          color: #0255fe;
          margin-bottom: 2px;
          text-transform: capitalize;
        }

        strong {
          margin: 0 0 2px;
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #272727;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #747474;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background: $lightGrey1;
        border-radius: 50%;

        &.transparent {
          background-color: transparent;
        }

        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      margin: 0;
      color: $black;
    }
  }

  &--body {
    img {
      display: block;
      width: 100%;
    }

    ul {
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 24px 20px;
      display: flex;
      gap: 10px;
      position: relative;
      justify-content: center;
      border-bottom: 1px solid $lightGrey1;
      margin-bottom: 15px;

      @media screen and (min-width: 768px) {
        gap: 20px;
      }

      // &:after {
      //   content: '';
      //   width: calc(100% - 40px);
      //   left: 20px;
      //   bottom: 0;
      //   position: absolute;
      //   height: 1px;
      //   background-color: $lightGrey1;
      // }
      li {
        @extend %vh-center;
        // width: calc((100% - 60px) / 4);
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 1024px) {
          width: 90px;
        }

        button {
          font-weight: 400;
          font-size: 15px;
          line-height: 30px;
          padding: 0;
          border: none;
          // width: 100%;
          background-color: transparent;
          border-radius: 6px;
          color: $black;
          transition: all ease-in-out 0.3s;

          // &:hover {
          //   background-color: $lightGrey;
          // }
        }

        .navbar-dropdown {
          position: relative;

          .dropdown-wrap {
            width: auto;
            border-radius: 0;
            background-color: transparent;
            height: auto;

            &>svg {
              width: auto;
              height: auto;
            }
          }

          &--menu {
            width: 198px;
            height: auto;
            background: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            border: none;
            top: 35px;

            .menu-body {
              max-height: none;

              ul {
                display: block;
                border: none;
                padding: 0;
                justify-content: flex-start;

                li {
                  &+li {
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &--footer {
    padding: 0 20px 20px;

    .wrap {
      display: flex;
      column-gap: 16px;

      .content {
        h4 {
          font-weight: 500;
          font-size: 16px;
          margin: 0 0 0px;
          line-height: 1;
          color: $primaryColor;
        }

        strong {
          margin: 0 0 0px;
          display: block;
          font-weight: 500;
          font-size: 14px;
          line-height: normal;
          color: $darkGrey1;
        }

        span {
          display: block;
          font-weight: 400;
          margin: 0 0 0px;
          font-size: 12px;
          line-height: 1;
          color: $darkGrey1;
        }

        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 160%;
          margin: 0;
          color: $black;
        }

        img {
          display: block;
          margin-top: 4px;
          width: 100%;
          border-radius: 4px;
        }
      }

      .icon {
        width: 41px;
        flex-shrink: 0;
        background: $lightGrey1;
        height: 41px;
        border-radius: 50%;

        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
      }
    }
  }
}

// .event-card {
//   display: flex;
//   row-gap: 16px;
//   padding: 20px;
//   position: relative;
//   flex-direction: column;

//   @media screen and (min-width: 768px) {
//     flex-direction: row;
//     column-gap: 16px;
//     row-gap: 0;
//   }
//   .content {
//     // display: flex;
//     // align-items: flex-start;
//     flex-grow: 1;

//     .button-group {
//       display: flex;
//       align-items: center;
//       position: absolute;
//       top: 20px;
//       right: 20px;

//       @media screen and (min-width: 768px) {
//         position: static;
//       }

//       .btn {
//         font-weight: 500;
//         font-size: 13px;
//         padding: 0;
//         line-height: 1;
//         width: 60px;
//         height: 29px;

//         & + .btn {
//           margin-left: 1rem;
//         }
//       }
//     }

//     ul {
//       list-style: none;
//       flex-wrap: wrap;
//       margin: 1.5rem 0 0;
//       padding: 0;
//       display: flex;
//       gap: 0;
//       position: relative;
//       li {
//         @extend %vh-center;
//         width: calc(100% / 4);
//         button {
//           font-weight: 400;
//           font-size: 15px;
//           line-height: 30px;
//           border: none;
//           width: 100%;
//           background-color: transparent;
//           border-radius: 6px;
//           color: $black;
//           transition: all ease-in-out 0.3s;
//         }
//       }
//     }
//     h4 {
//       font-weight: 500;
//       font-size: 18px;
//       margin: 0 0 8px;
//       line-height: 1;
//       color: $primaryColor;
//     }

//     strong {
//       margin: 0 0 12px;
//       display: block;
//       font-weight: 500;
//       font-size: 14px;
//       line-height: normal;
//       color: $darkGrey1;
//     }

//     span {
//       display: block;
//       font-weight: 500;
//       margin: 0 0 8px;
//       font-size: 13px;
//       line-height: 1;
//       color: $darkGrey1;
//     }

//     p {
//       font-style: italic;
//       font-weight: 300;
//       font-size: 15px;
//       line-height: normal;
//       color: $darkGrey1;
//     }
//   }
//   .icon {
//     width: 41px;
//     flex-shrink: 0;
//     background: $lightGrey1;
//     height: 41px;
//     border-radius: 50%;

//     img {
//       width: 100%;
//       border-radius: 50%;
//       height: 100%;
//     }
//   }
// }

.quote-post {
  border: 1px solid #dddddd;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;

  &_content {
    padding-left: 14px;
    width: calc(100% - 40px);

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #0255fe;
      margin-bottom: 2px;
      text-transform: capitalize;
    }

    strong {
      margin: 0 0 2px;
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #272727;
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: #747474;
    }
  }

  &_icon {
    width: 40px;
    height: 40px;
  }
}

.post-main-image img {
  max-width: 100%;
}

.tag-people-link {
  padding: 0.5rem !important;
  cursor: pointer;
}

.tag-people-modal {
  .tpm-container {
    // min-height: 200px;

    h5 {
      padding-top: 1rem;
    }

    .search {
      background-color: white;
      z-index: 10;
      gap: 1rem;
      position: sticky;
      top: -10px;

      input {
        padding-left: 2.5rem;
      }

      button {}
    }
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &.tag-list {
      flex-direction: row;
      flex-wrap: wrap;

      .btn-close {
        width: 0.4rem;
        height: 0.4rem;
        margin-left: 0.2rem;
      }

      li {
        font-size: 16px;
        gap: 0.2rem;
        background-color: #E4E6EB;
        border-radius: 0.2rem;
        padding: 0.5rem;
        ;

        p {
          padding-left: 0.5rem;
        }
      }
    }

    li {
      list-style: none;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      border-radius: 0.5rem;
      cursor: pointer;

      p {
        padding-left: 1rem;
      }

      &:active {
        background-color: $lightGrey1;
      }

      &:hover {
        background-color: $lightGrey;
      }
    }
  }

}