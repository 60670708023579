.candidate-jobs-container {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}

.candidate-filter {
  display: flex;
  flex-direction: column;
}

.candidate-filter-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 1.5rem;
}

.candidate-filter-button {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 6px 12px;
  height: 29px;

  background: #f6f7f8;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  &.active {
    border-color: #3d5a80;
    background-color: #e4e6eb;
    p {
      color: #3d5a80;
    }
  }
}

.candidate-filter-pagination-header {
  background: #e4e6eb;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  .action-menu {
    background: $white;
    box-shadow: 0 0 10px $lightGrey1;
    position: absolute;
    top: 2rem;
    right: 0rem;
    z-index: 1;
    border-radius: 0.5rem;

    &.limit {
      right: 4rem;
    }

    :hover {
      background-color: $lightGrey1;
    }

    .action {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      white-space: nowrap;
      padding: 10px;
      cursor: pointer;

      .icon {
        min-width: 25px;
      }
    }
  }

  .check-box-section {
    position: relative;
    .check-box-all {
      width: fit-content;
    }
    display: flex;
    align-items: center;
  }

  .select-jobs-section {
    position: relative;
  }

  .page-limit-section {
    position: relative;
    display: flex;
    align-items: center;
  }

  // .check-all {
  //   width: fit-content;
  // }

  // .small-dd {
  //   width: fit-content;
  //   margin-right: 1rem;
  //   margin-bottom: 0;
  //   align-items: center;

  //   .small-sel {
  //     height: auto;
  //     width: fit-content;
  //   }
  // }
}

.candidates-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .candidate {
    padding: 10px;
    display: flex;
    align-items: center;
    // gap: 10px;

    .candidate-information {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      flex: 1;

      .candidate-name-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
        }
      }
    }
  }
}

.candidate-filter-pagination-footer {
}
