.follower-card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  border: 1px solid #e4e6eb;
  border-radius: 10px;
  min-width: 200px;
  padding: 20px;
  margin-bottom: 20px;
}

.follower-info-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  .follower-info-details {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }
}
.follower-filters {
  margin-right: -8px;
}
.borderless-btn {
  padding: 10px;
  border-radius: 5px;

  &:hover {
    background-color: #e4e6eb;
  }
}
.no-followers-image-container {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  .no-followers-image {
    height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
  }
  p {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
  button {
    p {
      color: #3d5a80;
    }
  }
}
.connection-chaining {
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #747474;

    & + li {
      margin-left: 1.25rem;
    }

    svg {
      margin: 0 2px;
    }
  }
}
.icon-status-container {
  position: relative;

  .status {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    bottom: 8px;
    right: 0px;
  }
}

.name-position-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 5px;
}

.connection-circle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
}

.follower-actions-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  color: #000000;

  .gray-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;

    border: none;
    background: #e4e6eb;
    border-radius: 5px;
    max-height: 36px;
    min-height: 36px;
    justify-content: center;
  }
  .blue-button {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    border: none;
    background: #3d5a80;
    border-radius: 5px;
    max-height: 36px;
    min-height: 36px;
    justify-content: center;
  }
  .orange-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;

    border: none;
    color: #fff;
    background: #e18c3e;
    border-radius: 5px;
    min-height: 36px;
  }
}
