.primary-avatar-list {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;

  .user-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    position: relative;

    &.md {
      width: 60px;
      height: 60px;
    }

    &.xl {
      width: 80px;
      height: 80px;
    }

    .statusIcon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &.xl {
    .my-bridge-avatar {
      span {
        font-size: 1.5rem;
      }
    }
  }

  .content {
    padding-left: 13px;
    align-self: center;

    &.xl {
      padding-left: 10px;

      h4 {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: $primaryColor;
      }

      .followers {
        font-weight: 400;
        font-size: 15px;
        line-height: 1;
        color: #5b5b5b;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        margin: 0 0 2px;
        color: #272727;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: #747474;
      }
    }

    h4 {
      text-transform: capitalize;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 125%;
      color: #272727;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 143%;
      color: #747474;
      margin-top: 2px;
    }

    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-top: 1rem;
      list-style: none;

      &.connection-chaining {
        display: flex;
        align-items: center;

        li {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #747474;

          svg {
            margin: 0 2px;
          }
        }
      }

      li {
        font-weight: 400;
        font-size: 0.9375rem;
        display: flex;
        align-items: center;
        line-height: 1;
        color: $black;

        &+li {
          margin-left: 1.25rem;
        }
      }
    }

    em {
      display: block;
      font-style: normal;
      font-weight: 400;
      margin-top: 8px;
      font-size: 10px;
      line-height: 14px;
      color: #747474;
    }
  }
}

.network-item-list.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  .network-list-card {
    margin: 0px;
  }
}

.network-list-card {
  display: flex;
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin: 10px;
  flex-direction: column;
  position: relative;

  .kebab-action.network-list-kabab {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  &.postcardList {
    flex-direction: row;
  }

  &.small {
    padding: 8px 0;
    border: none;
  }

  &--inner {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &--profile {
    margin-right: 15px;
    align-items: center;
    display: flex;
    flex: 0;
    width: 100%;
    height: 65px;
    font-size: 10px;

    &.dashboard {
      width: 40px;
      height: 40px;
    }

    h5 {
      text-transform: capitalize;
      margin: 0;
      font-weight: $fw-600;
      font-size: $fs-14;
      line-height: 125%;
      color: $black;
    }

    p {
      font-size: $fs-12;
      font-weight: $fw-500;
      margin: 0;
      line-height: 1.1rem;
      color: $grey;
    }

    .my-bridge-avatar {
      font-size: $fs-18;
      font-weight: $fw-400;
      margin: 0;
      color: $white;
      text-transform: capitalize;
    }

    .subDetails {
      font-size: $fs-12;
      font-weight: $fw-500;
      margin: 0;
      color: $grey;
      text-transform: capitalize;
    }
  }

  &--details {
    flex: 1;
    padding-top: 10px;

    h5 {
      text-transform: capitalize;
      margin: 0;
      font-weight: $fw-600;
      font-size: $fs-14;
      line-height: 125%;
      color: $headingColor;
    }

    p {
      font-size: $fs-11;
      font-weight: $fw-400;
      line-height: 1.1rem;
      margin: 0;
    }

    span {
      font-size: $fs-11;
      font-weight: $fw-400;
      margin: 0;
      color: $grey;
      text-transform: capitalize;
    }

    .icon-module {
      span {
        font-weight: 400;
        font-size: 15px;
        padding: 0 5px;
      }
    }
  }

  &--actions {
    display: flex;
    flex: 0;
    justify-content: flex-around;
    align-items: center;
    gap: 10px;

    button {
      font-size: $fs-13;
      padding: 0 10px;
      height: 34px;
      line-height: normal;

      // &:first-child{
      //   margin-right: 8px;
      // }
      &.btn-link {
        background: transparent;
        border: none;
      }
    }

    &.network-list-card-via-search {
      flex: 2 1;
      flex-direction: column;
    }
  }

  &--imageBox {
    border-radius: 5px;
    background: #e4e6eb;
    padding: 8px 12px;
  }

  &--messageBox {
    border-radius: 5px;
    background: #e4e6eb;
    padding: 8px 12px;
    font-size: $fs-14;
  }

  &--messageBoxTimer {
    color: #747474;
    font-size: $fs-10;
  }

  &--bottomActions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}