.search-chip {
  position: relative;
  .chips {
    padding: 10px 15px;
    border-radius: 25px;
    border: 1px solid $primaryColor;
    color: $white;
    font-size: $fs-15;
    background-color: $primaryColor;
  }
  .chips + .chips {
    margin-left: 0.5rem;
  }
}
.tagList {
  position: absolute;
  list-style: none;
  font-size: $fs-15;
  padding: 0px;
  margin-top: -1rem;
  cursor: pointer;
  width: 97.5%;
  left: 1rem;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  z-index: 105;
  li {
    padding: 10px 15px;
    &:hover {
      background: $primaryGrey;
    }
  }
}
