.status-icon {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 3px;
  right: -7px;

  &.away {
    background-color: $darkGrey2;
  }
  &.available {
    background-color: $success;
  }
  &.busy {
    background-color: $error;
  }
}