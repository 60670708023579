.modal-content {
  padding: 10px;
  border-radius: 20px;
}

.media-modal {
  .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0;
  }

  .modal-content {
    height: 100% !important;
    border: 0 !important;
    border-radius: 0.5rem;
  }
}

.text-modal {
  .modal-dialog {
    width: 50% !important;
    max-width: none !important;
    max-height: 100% !important;
  }

  .modal-content {
    height: 100% !important;
    border: 0 !important;
    border-radius: 0.5rem;
    max-height: 90vh;
    height: auto !important;
  }
}

.post-modal-bd {
  z-index: 9998 !important;
}

.post-modal-dialog {
  z-index: 9998 !important;

  .modal-content {
    padding: 0px;
  }
}

.post-modal-body {
  display: flex;
  justify-content: center;
  padding: 0px !important;
  overflow-x: hidden;
  // height: 100vh;


  .post-footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 9;
  }
}

.post-modal-content-media {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eff3f5;
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: black;

  .post-media-item {
    min-width: 50vw;
    max-width: 65vw;
    overflow: auto;
    // padding: 40px 40px 40px 30px;
    // height: 500px;
  }
}

.post-modal-content-media {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eff3f5;
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: black;

  .post-media-item-image {
    min-width: 50vw;
    max-width: 65vw;
    overflow: auto;
  }

  .post-media-item-video {
    min-width: 50vw;
    max-width: 65vw;
    position: relative;

    >span {
      width: 100%;
      height: 100%;
      display: flex;
      background-color: #222222;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      >div {
        background-color: white;
      }
    }

    >div {
      width: 100% !important;
      // height: auto !important;
    }

  }

  .post-media-item-gif {
    min-width: 50vw;
    max-width: 65vw;
    overflow: auto;
  }
}

.post-modal-content-text {
  width: 100%;
  // overflow-y: auto;
  height: max-content;
}

.post-modal-text-header {
  display: flex;
  justify-content: space-between;
  padding: 1.3rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  border-bottom: 1px solid #f1f1f1;
}

.post-modal-text-body {
  // padding: 10px;
  color: #999;
}