@import '../utils/variables';

.custom-checkbox {
  list-style: 1;
  margin-top: 1rem;
  input {
    display: none;
    &:checked {
      & + span {
        &:before {
          border-color: $primaryColor;
          background-color: $primaryColor;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  label {
    cursor: pointer;
  }
  &.error {
    span {
      &:before {
        border: 2px solid $error;
      }
    }
  }
  span {
    font-weight: $fw-400;
    font-size: 0.9375rem;
    @media screen and (max-width: 1400px) {
      font-size: 14px;
    }
    line-height: 1;
    position: relative;
    user-select: none;
    color: $black;
    padding-left: 1.9375rem;
    display: inline-block;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease-in-out 0.3s;
    }

    &:after {
      width: 0.375rem;
      height: 0.75rem;
      transform: rotate(45deg);
      border-right: 0.125rem solid $white;
      border-bottom: 0.125rem solid $white;
      left: 0.4375rem;
      top: 0;
      opacity: 0;
    }

    &:before {
      background: $checkboxBg;
      width: 1.25rem;
      height: 1.25rem;
      border: 0.0625rem solid $formBorderColor;
      border-radius: 0.188rem;
      -webkit-border-radius: 0.188rem;
      -moz-border-radius: 0.188rem;
      -ms-border-radius: 0.188rem;
      -o-border-radius: 0.188rem;
    }
  }
}
