@import "../../../../scss/utils/variables";

.drag__area {
    padding: 10px;
    margin-bottom: 20px;
    color: $secondaryGrey;
    border: 1px dashed $secondaryGrey;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        border-color: $secondaryGrey;
        background-color: $primaryGrey;
    }

    .drag__area__focus {
        color: $primaryColor;
        font-weight: bolder;
    }
}

.drag__area.hover {
    border-color: $secondaryGrey;
    background-color: $primaryGrey;
}

.listing__section {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;

    ul {
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            margin: 0;
            padding: 10px 0px;
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid $primaryGrey;

            .li__icon {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .video__preview {
                    width: 32px;
                    height: 32px;
                    background-color: black;
                    border:1px solid $secondaryGrey;
                    border-radius: $rounded-6;
                }
                .image__preview {
                    width: 32px;
                    height: 32px;
                    border:1px solid $secondaryGrey;
                    border-radius: $rounded-6;
                }
            }

            .error {
                color: $error;
                font-size: $fs-12
            }

            >div:nth-child(2) {
                flex: 7;
                padding-left: 10px;
            }

            >div:nth-child(3) {
                flex: 4;
            }

            >div:nth-child(4) {
                display: flex;
                justify-content: flex-end;
                flex: 1;
                cursor: pointer;

                button {
                    border: none;
                    background-color: transparent;
                }
            }

            .progress {
                width: 100%;

                .progress-bar {
                    width: 10%;
                }
            }

        }
    }
}

.footer__section {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    div:nth-child(2) {
        margin-left: 20px
    }
}