@import '../utils/variables';

.teams {
  // padding: 0 1rem;

  .custom-button {
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent !important;

    &:hover {
      background-color: $lightGrey2 !important;
    }

    &:active {
      color: $primaryColor;
      background-color: $lightGrey1 !important;
    }

    >span {
      padding-right: 10px;
    }
  }

  .list-page {

    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
    }

    .card {
      border: unset;
      border-radius: 0px;
      cursor: pointer;

      &:hover {
        background-color: $lightGrey2;
      }
    }

    .card:last-child .page-list-wrapper {
      border: none;
    }

    .no-data-container {
      justify-content: center;

      img {
        width: 300px;
      }

      .il-desc {
        text-align: center;
      }
    }

    .page-list-wrapper {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      border-bottom: 1px solid $listBorderColor;

      .page-list-image {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: cover;
        border-radius: 100%;
        border: 1px solid $lightGrey;
        background-color: $lightGrey
      }

      .page-details {
        position: relative;
        flex-grow: 1;

        .card-text {
          line-height: 1.3rem;
          opacity: 0.7;
        }
      }

      .page-list-action {
        display: flex;
        align-self: center;
      }

      .more_actions {
        cursor: pointer;

        // margin-left: 3rem;
        .threeDots:after {
          content: '\2807';
          font-size: 30px;
          cursor: pointer;
          color: $darkGrey2;
        }
      }

      .more-menu {
        position: absolute;
        background: $white;
        box-shadow: 0 0 10px $lightGrey1;
        width: fit-content;
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
        border-radius: 0.5rem;
        right: 0;

        .link {
          padding: 0.5rem 1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          >span {
            padding: 0px 10px;
          }

          &:hover {
            background: $lightGrey2
          }
        }
      }

      .avatar {
        height: auto;
        width: 100%;
      }

      .company-banner {
        position: relative;
        height: 222px;
        background: $darkGrey1;
        margin-bottom: 5rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .upload-banner {
          position: absolute;
          bottom: 0.5rem;
          right: 1.8rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        .upload-logo {
          width: 160px;
          height: 160px;
          background: $grey1;
          position: absolute;
          border: 5px solid $white;
          left: 2rem;
          bottom: -4rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .upload-icon {
            position: absolute;
            bottom: 0.5rem;
            right: -0.8rem;
            cursor: pointer;
          }
        }
      }

      .company-detail {
        min-height: 100px;
      }

      .website {
        padding-left: 4rem;
      }
    }
  }
}

.leave-team {
  .modal-header {
    .modal-title {
      font-size: $fs-24;
      font-weight: $fw-700;
    }
  }

  .modal-body {
    padding: 0 25px 25px;
  }

  .modal-content {
    border-radius: 15px;
  }
}

.create-team {
  .modal-header {
    .modal-title {
      font-size: $fs-24;
      font-weight: $fw-700;
    }
  }

  .modal-dialog {
    --bs-modal-width: 750px;
  }

  .modal-content {
    border-radius: 15px;
  }

  .modal-body {
    padding: 0 25px 25px;
    overflow-x: hidden;

    .team-banner {
      height: 195px;
      background-color: $darkGrey1;
      margin: 0 -1.6rem 5rem;
      position: relative;
      border-bottom: 1px solid $lightGrey1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .upload-banner {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        background: $lightGrey2;
        border: 1px solid $lightGrey2;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
      }

      .upload-logo {
        width: 140px;
        height: 140px;
        background: $grey1;
        position: absolute;
        border: 1px solid $lightGrey1;
        left: 1.5rem;
        bottom: -4rem;
        border-radius: 100%;
        overflow: hidden;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .upload-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid $lightGrey;
          border-radius: 50%;
          padding: 0.5rem;
          width: 64px;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            cursor: pointer;
          }
        }

        .upload-icon.show-on-hover {
          z-index: -1;
        }

        &:hover {
          .upload-icon.show-on-hover {
            z-index: 0;
          }
        }
      }
    }

    .team-body {

      .search-chip {
        .chips {
          background-color: transparent;
          color: black;

          svg {
            cursor: pointer
          }
        }
      }

      .field-error {
        color: tomato;
      }

      .team_detail {
        height: 75px;
      }
    }
  }
}

.chat-compose {
  margin-bottom: 1.5rem;

  .chat-wrapper {
    display: flex;
    gap: 1rem;

    .chat-user {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    .chat-input-wrapper {
      flex-grow: 1;

      .chat-input {
        // border-radius: 25px;
        // background: #f3f3f3;
        // border: 0;
        display: block;
        resize: none;
        width: 100%;
        background: #f3f3f3;
        border-radius: 50px;
        border: none;
        padding: 10.5px 14px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
      }
    }
  }

  .msg-action {
    display: flex;
    padding: 1rem 0 0rem;
    column-gap: 1rem;

    .msg-btn {
      padding: 0;
      background: transparent;
      border: 0;
      height: auto;
    }
  }
}

.team__main__chat {
  .chat__composer {
    border: 1px solid $lightGrey1;
    order: 1 !important;
    border-radius: 15px;
    margin-bottom: 1rem;

    .input__inner {
      gap: 1rem;

      .input__message-input {
        width: 100%;
      }

      .input__sticky {
        position: relative;
      }
    }
  }

  .chat__list {
    border: 1px solid $lightGrey1;
    border-radius: 15px;
  }
}

.welcomeNoteInner {
  font-size: $fs-15;
  color: $darkGrey2;

  h6 {
    font-size: $fs-16;
    color: $black;
  }
}

.nonOrganizerMembersContainer {

  &--CardList {

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .members-network-card {
      width: 49%;
      margin: 20px 0px 0px 0px;

      .non-org-actions-container {
        padding: 20px 0px 10px 0px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        span {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: 1250px) {
      flex-direction: column;

      .members-network-card {
        width: 100%;
      }
    }
  }


}

.membersContainer {
  display: flex;
  flex-direction: column;

  .network-list-card {
    margin: 10px 0px 0px 0px;

    &--inner {
      margin: 10px 0px 0px 0px;
    }

    &--messageBox {
      margin-top: 20px;
    }

    &--messageBoxTimer {
      padding-left: 5px;
    }
  }


  .pending-posts {
    display: flex;
    flex-direction: column-reverse;
  }

  &--CardList {

    .actions-container {
      position: relative;

      &--menu {
        position: absolute;
        border-radius: 12px;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        right: 0;
        width: 200px;
        z-index: 10;

        ul {
          padding: 0;
          margin: 0;

          li {
            padding: 0.5rem 1rem;
            cursor: pointer;
            user-select: none;
            font-size: 14px;

            &:hover {
              background-color: $lightGrey2;
            }

            &:active {
              background-color: $lightGrey1;
            }
          }
        }
      }
    }
  }

  &--Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 13px;
    border-bottom: 1px solid $inputBorderColor;

    h6 {
      font-size: $fs-16;
      font-weight: $fw-600;
      color: $black;
      margin: 0px;

      span {
        color: $darkGrey2;
        font-weight: $fw-400;
      }
    }
  }

  &--Filters {
    padding: 13px 0;
    border-bottom: 1px solid $inputBorderColor;
  }
}

.sidebarList.sec-options {
  li {
    padding-bottom: 30px;
  }
}

.sidebarList {
  margin: 0;
  padding: 0;
  list-style: none;


  .opt-text {
    color: rgba(0, 0, 0, 0.5);
  }


  li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 10px;

    p {
      font-size: $fs-15;
      color: #5b5b5b;

      span {
        font-size: $fs-13;
        color: #747474;
      }
    }
  }
}

.cardBottomLink {
  padding: 15px 0 0;
  text-align: center;

  a {
    color: $black;
    text-decoration: none;
  }
}

.inviteFriendsPopup {
  display: block;

  .searchFriends {
    padding: 5px 0;
  }

  .inviteSelector {
    padding: 0 0 10px;
    display: flex;
    gap: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
  }

  .inviteContainer {
    display: block;
    max-height: 300px;
    overflow-y: auto;

    .inviteUserContainer {
      display: flex;

      &--inner {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
      }

      &--action {
        display: flex;
        align-items: center;
      }

      &--Details {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;

        h5 {
          font-size: $fs-15;
          font-weight: $fw-500;
          color: $darkGrey2;
          margin: 0;
        }

        p {
          font-size: $fs-12;
          font-weight: $fw-400;
          color: $darkGrey1;
          margin: 0;
        }
      }

      &--Profile {
        width: 40px;
      }
    }
  }
}

.team-profile-banner {
  .upload {
    right: -15px;
    bottom: 10px;
  }

  // .user .left{
  //   padding-left: 160px;
  // }
  .team-circle {
    font-size: $fs-15;
  }

  .team-circle span:after {
    content: ',';
    margin-right: 5px;
  }

  .team-circle:nth-last-child(2) span:after {
    content: '';
    margin-right: 0px;
  }
}

.team-chat-container {
  height: 100%;
  display: block;
  min-height: 895px;

  .team__main__chat {
    overflow: visible;
  }
}

.empty-list-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20%;
  line-height: normal;
  color: $darkGrey1;

  b {
    color: $black;
  }
}

.members-section {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 8px;

  &--title {
    font-weight: 600;
    font-size: 20px;
    color: $darkGrey2;
  }

  &--header {
    font-weight: 600;
    font-size: 20px;
    color: $darkGrey2;
    text-align: left;
    padding: 20px 30px;
    border-bottom: 1px solid #e4e6eb;
    flex: unset;
    width: 100%;
  }

  &.fullpage {
    align-items: stretch;
  }

  &--desc {
    padding: 20px 30px;
    border-bottom: 1px solid #e4e6eb;
    font-weight: 400;
    font-size: 15px;
    color: $darkGrey2;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h3 {
      font-weight: 600;
      font-size: 15px;
      color: $darkGrey2;
    }

    &:last-child {
      border: none;
    }
  }
}