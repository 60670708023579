.card-action-info {
  &-arrow {
    color: #5B5B5B;
  }

  &-content {
    border-radius: 8px;
    background-color: #5B5B5B;
    padding: 16px;
    min-width: 164px;

    &>div {
      transform: none !important;
      left: 22px !important;
      top: -8px !important;
    }

    ul {
      list-style: none;

      li {

        &+li {
          margin-top: 12px;
        }

        a {
          font-weight: 500;
          font-size: 13px;
          line-height: 120%;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          text-decoration: none;
          text-transform: capitalize;

          img {
            height: 24px;
            width: 24px;
            display: flex;
            border-radius: 50%;
            object-fit: cover;
            object-position: top center;
            margin-right: 5px;

          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }
}

.nested-images {
  align-items: center !important;

  p {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 120% !important;
    margin-left: 2px !important;
    color: #5B5B5B !important;
  }

  img {
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
    width: 24px;

    &:not(:first-child) {
      margin-left: -7px;
    }
  }
}

.edit-post {
  .create-post {
    border-radius: 0;
    margin-bottom: 0 !important;
    box-shadow: none;

    &--footer {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    &--header {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
  }
}

.inactiveUserOverlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.default-post-content {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  text-align: justify;
  color: #3D5A80;
  padding-left: 10px;
}

.video-player {
  position: relative;

  >span {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #222222;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    >div {
      background-color: white;
    }
  }

  >div {
    width: 100% !important;
    // height: auto !important;
  }
}

.meeting-popup {
  .modal-body {
    padding: 10px !important;
  }
}