.textarea {
  min-height: 150px;
}
.rounded-pill-start {
  border-radius: 44px 0 0 44px;
}
.rounded-pill-end {
  border-radius: 0 44px 44px 0 !important;
}

.hding_sJobs {
  font-style: normal;
  font-weight: $fw-700;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 3px;

  &.below_hding_sJobs {
    font-weight: $fw-400;
  }
}

.sJobs_spec,
.sJobs_desc {
  font-size: 0.875rem;
}
.sJobs_btton {
  background-color: $primaryGrey;
  font-size: 0.8125rem;
  font-weight: $fw-500;
  line-height: 18px;
  height: 36px !important;
}
.suggested_jobs_list {
  color: $black;
}
.rounded-10 {
  border-radius: 10px !important;
}
.sJobs_tabs a,
.sJobs_tabs a:hover,
.sJobs_tabs a:visited {
  color: $primaryColor;
  text-decoration: none;
  font-style: normal;
  font-weight: $fw-700;
  font-size: 0.89rem;
  height: 30px;
  display: inline-block;
}
.sJobs_tabs a:hover {
  border-bottom: 3px solid $primaryColor;
}
._search-jobs button {
  background-color: $primaryColor;
}
._no-color {
  background-color: transparent;
}
._font-15 {
  font-size: 0.89rem;
}
.border-color {
  border-color: $primaryGrey !important;
}
@media (min-width: 768px) {
  ._containerWidth {
    max-width: 1230px !important;
  }
}
@media (max-width: 767px) {
  ._search-jobs-form .input-group {
    margin-bottom: 10px;
  }
  ._search-jobs-form {
    margin-top: 20px;
  }
  .post_job {
    margin-top: 10px;
  }
}

@media (max-width: 575px) {
  .post_job {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 0.89rem;
    color: $primaryColor;
  }
  ._search-jobs {
    flex-direction: column;
    align-items: start !important;
  }
  ._search-jobs > * {
    width: 100%;
  }

  ._search-jobs-form input {
    border-radius: 0 20px 20px 0px !important;
  }
  ._search-jobs-form span {
    border-radius: 20px 0px 0px 20px !important;
    border-left: 1px solid $primaryGrey !important;
  }
}
.post_job_icon {
  text-decoration: none;
  font-size: 0.9rem;
  color: $primaryColor;
  cursor: pointer;
  display: flex;
  align-items: center;
  &.back {
    color: $secondaryGrey;
  }
}
._viewAll button {
  background-color: $primaryGrey;
  font-weight: $fw-500;
  height: 38px;
  line-height: 19px;
  font-size: 0.87rem;
}
._search-jobs-form {
  .input-group {
    height: 44px;
    input {
      font-size: 0.89rem;
      color: $secondaryGrey;
      height: 44px;
    }
  }
}
._searchJobBtn {
  width: 146px;
  height: 42px !important;
  font-size: 0.99rem;
  font-weight: $fw-500;
}
._job_tabs {
  .tabs__bar {
    button {
      font-size: 0.89rem;
      background-color: transparent;
      border: 0px solid transparent;
      margin-right: 45px;
      height: 35px;
      &.tabs__tab-active {
        color: $primaryColor;
        border-bottom: 2px solid $primaryColor;
      }
    }
  }

  .tabs__tab {
    font-weight: $fw-700;
  }
}
.post_job_hding {
  font-size: 0.9rem;
  color: $headingColor;
  font-weight: $fw-700;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid $secondaryBtnBg;
}
.border_btm {
  border-bottom: 1px solid $primaryGrey;
}
.height_34 {
  height: 34px !important;
}
.post_job {
  font-size: 1rem;
  input,
  select {
    font-size: 1rem;
  }
}
.post_job_form {
  font-size: 0.9rem;
  input,
  select {
    font-size: 0.9rem;
  }
}
._myJobDetailPage {
  p {
    font-size: 0.9rem;
  }
}
._post_data {
  textarea {
    height: auto;
  }
}
.job-details {
  .image_container {
    width: 90px;
    height: 90px;
  }
  .jobs-benifits {
    flex-direction: column;
    display: flex;
    row-gap: 0.1rem;
    margin: 1rem 0;
    .benifits {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 20px;
    }
  }
  .skills-wrappper {
    .skill {
      border: none;
      color: $darkGrey2;
      background: #E4E6EB;
      padding: 10px 20px;
      border-radius: 25px;
      font-size: $fs-15;
    }
  }
}

.job-detail-note {
  ul,
  ol {
    margin-left: 1.8rem;
  }
  *{color:#5b5b5b !important; font-family: "Inter", sans-serif !important;}
}

.page-textarea {
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    margin: 0px 1.5rem;
    padding: 0;
  }
}

.filter-btn{
  border-radius: 20px !important;
    background: #F6F7F8;
    border: 1px solid #D9D9D9;
    color: $black;
    height: 36px !important;
    padding: 5px 20px;
  &.active{
    color: #0255FE;
    background: #E3ECFF;
    border: 1px solid #0255FE;
  }
  +.filter-btn{
    margin-left: .8rem;
  }
}
.candidate-list-head{
  padding: 5px 10px;
  background: #D9D9D9;
  .check-all{
    width: fit-content;
  }
  .small-dd{
    width: fit-content;
    margin-right: 1rem;
    margin-bottom: 0;
    align-items: center;
    
    .small-sel{
      height: auto;
      width: fit-content;
    }   
  }
}