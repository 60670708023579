@import '../utils/variables';

.readmoreTxt {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: $primaryColor !important;
  cursor: pointer;
  font-weight: $fw-600;
}
