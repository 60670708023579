.jobs-header {
  display: flex;
  background: $headingColor;
  padding: 24px;
  &--top-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
    a {
      fill: $white;
      color: $white;
      font-size: 20px;
      vertical-align: middle;
      svg {
        vertical-align: -1px;
      }
      &:hover {
        color: $white;
      }
    }
  }
  &--bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: $white;
      font-size: 16px;
      &:hover {
        color: #e4e6eb;
      }
    }
  }
}

.job-preference {
  border: 1px solid $lightGrey1;
  border-radius: 10px;
  padding: 20px;
  &--inner-section {
    display: flex;
    justify-content: space-between;
    font-size: $fs-15;
    color: $darkGrey2;
    h5 {
      font-weight: 700;
      font-size: 15px;
      color: $black;
    }
    .job-checkbox {
      max-width: 150px;
    }
  }
}

.job-card {
  min-height: 24rem;
  &.individual {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 16px;
    position: relative;
    .btn-wrapper {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 1;
    }
    .job-btn-wrapper {
      bottom: 1rem;
      position: absolute;
      width: 100%;
    }
    .title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &--title {
    color: $headingColor;
    font-size: $fs-16;
    font-weight: $fw-700;
    cursor: pointer;
  }
  &--benifits {
    color: $darkGrey1;
    line-height: 22px;
    height: 66px;
  }
  &--subtitle {
    font-size: $fs-14;
    color: #272727;
    margin: -5px 0 5px;
  }
  .job-excerpt {
    font-weight: 400;
    font-size: 14px;
    color: $black;
    line-height: 20px;
    height: 60px;
    overflow: hidden;
  }
  // .job-description {
  //   height: 100px !important;
  // }
  .company-logo.individual {
    width: 65px;
    height: 65px;
  }
}
.empty-message {
  padding: 40px 0;
  text-align: center;
}
.search-container-jobs {
  padding: 16px 0;
  .search-details {
    padding: 10px 0;
    border-bottom: 1px solid #e4e6eb;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 180%;
    color: #5b5b5b;
  }
}
.jobDetails-companydetails {
  line-height: 18px;
  margin: 5px 0 0;
}
